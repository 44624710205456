import type { ComputedRef, Ref, UnwrapNestedRefs } from "vue";
import { merge } from "lodash-es";
import { AxiosError } from "axios";
import { ApiError } from "@/libs/api";

interface Props<T> {
  value?: T;
  keepLoading?: boolean;
  initialLoading?: boolean;
  promise: (...props: any[]) => Promise<T>;
  onFetch?: () => any;
  onSuccess?: (data: T) => any;
  onFailure?: (response: any) => any;
}

export type UseRequest<T = undefined, F = any> = UnwrapNestedRefs<{
  isLoading: Ref<boolean>;
  hasErrors: ComputedRef<boolean>;
  data: Ref<T>;
  fetch: (...value: any) => Promise<void>;
  count: Ref<number>;
  reset: () => void;
  errors: Ref<RequestErrors<F>>;
}>;

export type RequestErrors<F = any> = Partial<Record<keyof F | "unknown", any[]>>;

export default function useRequest<T = undefined, F = any>(request: Props<T>): UseRequest<T, F> {
  const data = ref<T | undefined>(request?.value) as Ref<T>;
  const count = shallowRef<number>(0);
  const errors = shallowRef<RequestErrors<F>>({});
  const isLoading = shallowRef<boolean>(!!request.initialLoading);
  const hasErrors = computed<boolean>(() => Object.keys(errors.value).length > 0);
  const setErrors = (e: Record<keyof T, string> | string) => merge(errors.value, typeof e === "object" ? e : { unknown: e });
  const clearErrors = () => (errors.value = {});

  const fetch = async (...value: any): Promise<void> => {
    isLoading.value = true;
    if (request.onFetch) {
      request.onFetch();
    }
    try {
      data.value = await request.promise(...value);
      count.value++;
      clearErrors();
      if (!request.keepLoading) {
        isLoading.value = false;
      }
      if (request.onSuccess) {
        return request.onSuccess(data.value);
      }
      return Promise.resolve();
    } catch (e) {
      clearErrors();
      isLoading.value = false;
      setErrors("Unknown");
      if (e instanceof AxiosError) {
        setErrors(e.response?.data?.errors ?? e.response?.data?.message ?? e.message ?? e);
      }
      if (e instanceof ApiError) {
        setErrors(e.response?.errors ?? e.message ?? e);
      }
      if (request.onFailure) {
        return request.onFailure(e instanceof AxiosError ? e?.response ?? e.message : e);
      }
      return Promise.reject(e);
    }
  };
  const reset = () => {
    isLoading.value = request.initialLoading ?? false;
    clearErrors();
  };

  return reactive({
    data,
    fetch,
    count,
    reset,
    errors,
    isLoading,
    hasErrors,
  }) as UseRequest<T, F>;
}
