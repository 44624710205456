import api from "@/libs/api";
import type { OwnerBankAccount, UnsavedOwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import SearchOwnerBankAccountsFactory from "@/contexts/owner-bank-accounts/composables/factories/SearchOwnerBankAccountsFactory";
import ShowOwnerBankAccountFactory from "@/contexts/owner-bank-accounts/composables/factories/ShowOwnerBankAccountFactory";
import CreateOwnerBankAccountFactory from "@/contexts/owner-bank-accounts/composables/factories/CreateOwnerBankAccountFactory";
import UpdateOwnerBankAccountFactory from "@/contexts/owner-bank-accounts/composables/factories/UpdateOwnerBankAccountFactory";

export default function useOwnerBankAccounts() {
  const searchOwnerBankAccounts = async (ownerId: string): Promise<OwnerBankAccount[]> => {
    const { data } = await api.GET("/owners/{owner}/bank-accounts", { params: { path: { owner: ownerId } } });
    return Promise.resolve(SearchOwnerBankAccountsFactory.fromSearchOwnerBankAccountsResponse(data));
  };
  const createOwnerBankAccount = async (ownerId: string, ownerBankAccount: UnsavedOwnerBankAccount): Promise<OwnerBankAccount> => {
    const { data } = await api.POST("/owners/{owner}/bank-accounts", { params: { path: { owner: ownerId } }, body: CreateOwnerBankAccountFactory.toCreateOwnerBankAccountRequest(ownerBankAccount) });
    return Promise.resolve(CreateOwnerBankAccountFactory.fromCreateOwnerBankAccountResponse(data));
  };
  const showOwnerBankAccount = async (ownerId: string, ownerBankAccountId: string): Promise<OwnerBankAccount> => {
    const { data } = await api.GET("/owners/{owner}/bank-accounts/{account}", { params: { path: { owner: ownerId, account: ownerBankAccountId } } });
    return Promise.resolve(ShowOwnerBankAccountFactory.fromShowOwnerBankAccountResponse(data));
  };
  const updateOwnerBankAccount = async (ownerId: string, ownerBankAccountId: string, ownerBankAccount: UnsavedOwnerBankAccount): Promise<OwnerBankAccount> => {
    const { data } = await api.PUT("/owners/{owner}/bank-accounts/{account}", { params: { path: { owner: ownerId, account: ownerBankAccountId } }, body: UpdateOwnerBankAccountFactory.toUpdateOwnerBankAccountRequest(ownerBankAccount) });
    return Promise.resolve(UpdateOwnerBankAccountFactory.fromUpdateOwnerBankAccountResponse(data));
  };
  const defaultOwnerBankAccount = async (ownerId: string, ownerBankAccountId: string): Promise<void> => {
    await api.POST("/owners/{owner}/bank-accounts/{account}/default", { params: { path: { owner: ownerId, account: ownerBankAccountId } } });
  };
  const deleteOwnerBankAccount = async (ownerId: string, ownerBankAccountId: string): Promise<void> => {
    await api.DELETE("/owners/{owner}/bank-accounts/{account}", { params: { path: { owner: ownerId, account: ownerBankAccountId } } });
  };

  return {
    searchOwnerBankAccounts,
    createOwnerBankAccount,
    showOwnerBankAccount,
    updateOwnerBankAccount,
    defaultOwnerBankAccount,
    deleteOwnerBankAccount,
  };
}
