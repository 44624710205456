<template>
  <SlideOver
    ref="slideOverElement"
    :parent-route="{ name: 'accommodations.manage', params: { id: accommodationId } }"
    prevent-close
    route
    size="full"
  >
    <SlideOverHeader>
      <SlideOverTitle>{{ $t("accommodation_contracts.manage.title") }}</SlideOverTitle>
      <SlideOverHeaderActions>
        <button
          class="btn-white"
          type="button"
          @click.prevent="slideOverElement?.close()"
        >
          {{ $t("shared.form.cancel") }}
        </button>
        <button
          :disabled="updateAccommodationContractRequest.isLoading"
          class="btn-primary"
          type="submit"
          @click.prevent="submit"
        >
          {{ $t("shared.form.save") }}
        </button>
      </SlideOverHeaderActions>
    </SlideOverHeader>
    <SlideOverBody>
      <LoadingAbsolute :is-loading="searchAccountSeasonsRequest.isLoading">
        <div class="space-y-4 pb-6">
          <div class="space-y-2.5 md:flex md:items-end md:space-x-2.5 md:space-y-0">
            <div class="w-full md:w-1/5">
              <FormGroup>
                <FormLabel>{{ $t("accommodation_contracts.props.reference_price") }}</FormLabel>
                <FormInput>
                  <FormFloat
                    v-model.number="form.reference_price"
                    name="reference_price"
                  />
                </FormInput>
              </FormGroup>
            </div>
            <ButtonWithIcon
              class="btn-primary"
              icon="wand-magic-sparkles-solid"
              @click="calculatePrices"
            >
              {{ $t("accommodation_contracts.manage.calculate") }}
            </ButtonWithIcon>
          </div>
          <div>
            <div class="gap-0 divide-x divide-gray-200 border-x border-t border-gray-200 xl:columns-2">
              <div class="flex items-stretch divide-x divide-gray-200">
                <div class="flex grow" />
                <div class="flex w-28 flex-none py-1">
                  <div class="m-auto text-center text-xs text-gray-600">%</div>
                </div>
                <div class="flex w-24 flex-none py-1">
                  <div class="m-auto text-center text-xs text-gray-600">{{ $t("accommodation_contracts.props.weekly_price") }}</div>
                </div>
              </div>
              <div class="hidden items-stretch divide-x divide-gray-200 xl:flex">
                <div class="flex grow" />
                <div class="flex w-28 flex-none py-1">
                  <div class="m-auto text-center text-xs text-gray-600">%</div>
                </div>
                <div class="flex w-24 flex-none py-1">
                  <div class="m-auto text-center text-xs text-gray-600">{{ $t("accommodation_contracts.props.weekly_price") }}</div>
                </div>
              </div>
            </div>
            <div class="gap-0 border-x border-t border-gray-200 xl:columns-2">
              <template
                v-for="week in Object.keys(searchAccountSeasonsRequest.data).map((item) => Number(item))"
                :key="week"
              >
                <AccommodationContractsWeekForm
                  v-model="form.weeks[week]"
                  :reference-price="form.reference_price"
                  :season="searchAccountSeasonsRequest.data[week]"
                  :week="week"
                  :year="getAccommodationContractRequest.data.year"
                />
              </template>
            </div>
          </div>
          <FormGroup :errors="updateAccommodationContractRequest.errors.notes">
            <FormLabel>{{ $t("accommodation_contracts.props.notes") }}</FormLabel>
            <FormInput>
              <FormTextarea
                v-model="form.notes"
                name="notes"
              />
            </FormInput>
          </FormGroup>
        </div>
      </LoadingAbsolute>
    </SlideOverBody>
  </SlideOver>
</template>

<script lang="ts" setup>
import SlideOver from "@/contexts/shared/ui/components/slide-over/SlideOver.vue";
import SlideOverTitle from "@/contexts/shared/ui/components/slide-over/SlideOverTitle.vue";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormFloat from "@/contexts/shared/ui/components/form/FormFloat.vue";
import SlideOverHeader from "@/contexts/shared/ui/components/slide-over/SlideOverHeader.vue";
import LoadingAbsolute from "@/contexts/shared/ui/components/loading/LoadingAbsolute.vue";
import AccommodationContractsWeekForm from "@/contexts/accommodation-contracts/ui/components/AccommodationContractsWeekForm.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import SlideOverBody from "@/contexts/shared/ui/components/slide-over/SlideOverBody.vue";
import useAccountSeasons from "@/contexts/accounts/composables/useAccountSeasons";
import useRequest from "@/contexts/shared/composables/useRequest";
import type { AccountSeason } from "@/contexts/accounts/models/AccountSeason";
import useAccommodationContracts from "@/contexts/accommodation-contracts/composables/useAccommodationContracts";
import type { AccommodationContractWeek, UnsavedAccommodationContractUpdated } from "@/contexts/accommodations/models/AccommodationContract";
import SlideOverHeaderActions from "@/contexts/shared/ui/components/slide-over/SlideOverHeaderActions.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import FormTextarea from "@/contexts/shared/ui/components/form/FormTextarea.vue";

const emit = defineEmits<{
  fetchContracts: [];
}>();

const route = useRoute();
const { searchAccountSeasons } = useAccountSeasons();
const { successNotification, errorNotification } = useNotification();
const { getAccommodationContract, updateAccommodationContract } = useAccommodationContracts();

const form = ref<UnsavedAccommodationContractUpdated>({ weeks: {}, reference_price: 0, notes: null });
const slideOverElement = ref<InstanceType<typeof SlideOver>>();
const accommodationId = computed<string>(() => String(route.params.id));
const contractId = computed<string>(() => String(route.params.contractId));

onMounted(() => {
  getAccommodationContractRequest.fetch();
});

const getAccommodationContractRequest = useRequest({
  initialLoading: true,
  promise: () => getAccommodationContract(accommodationId.value, contractId.value),
  onSuccess: (data) => {
    form.value = data;
    searchAccountSeasonsRequest.fetch();
  },
});
const searchAccountSeasonsRequest = useRequest<Record<number, AccountSeason | null>>({
  initialLoading: true,
  value: {},
  promise: () => searchAccountSeasons(getAccommodationContractRequest.data.year),
});
const updateAccommodationContractRequest = useRequest({
  promise: () => updateAccommodationContract(accommodationId.value, contractId.value, form.value),
  onSuccess: () => {
    successNotification("accommodation_contracts.update.success");
    emit("fetchContracts");
    slideOverElement.value?.close();
  },
  onFailure: () => {
    errorNotification("accommodation_contracts.update.error");
  },
});

const submit = async () => {
  await updateAccommodationContractRequest.fetch();
};
const calculatePrices = () => {
  Object.keys(form.value.weeks)
    .map((item) => Number(item))
    .forEach((item) => {
      if (form.value.weeks[item]) {
        (form.value.weeks[item] as AccommodationContractWeek).amount = searchAccountSeasonsRequest.data[item]?.amount ?? 100;
      }
    });
};
</script>
