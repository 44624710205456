<template>
  <SlideOver
    ref="slideOverElement"
    :parent-route="{ name: 'bookings' }"
    route
    size="full"
  >
    <SlideOverHeader>
      <SlideOverTitle>{{ $t("bookings.create.title") }}</SlideOverTitle>
      <SlideOverHeaderActions>
        <button
          class="btn-white"
          type="button"
          @click.prevent="slideOverElement?.close()"
        >
          {{ $t("shared.form.cancel") }}
        </button>
        <button
          :disabled="createBookingsRequest.isLoading"
          class="btn-primary"
          type="submit"
          @click.prevent="submit"
        >
          {{ $t("shared.form.save") }}
        </button>
      </SlideOverHeaderActions>
    </SlideOverHeader>
    <SlideOverBody>
      <form
        class="space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0"
        @submit.prevent="submit"
      >
        <div class="md:grid md:gap-4 lg:col-span-2 lg:grid-cols-2">
          <SectionGroup>
            <section>
              <SectionHeader>
                <SectionTitle>{{ $t("bookings.manage.accommodation.title") }}</SectionTitle>
              </SectionHeader>
              <FormGroup :errors="createBookingsRequest.errors.accommodation_id">
                <FormLabel>{{ $t("bookings.props.accommodation_id") }}</FormLabel>
                <FormInput>
                  <AccommodationsSelect v-model="booking.accommodation_id" />
                </FormInput>
              </FormGroup>
            </section>
          </SectionGroup>
        </div>
        <div class="md:grid md:gap-4 lg:col-span-2 lg:grid-cols-2">
          <SectionGroup>
            <section>
              <SectionHeader>
                <SectionTitle>{{ $t("bookings.manage.guest.title") }}</SectionTitle>
              </SectionHeader>
              <FormGroup :errors="createBookingsRequest.errors.guest_id">
                <FormLabel>{{ $t("bookings.props.guest_id") }}</FormLabel>
                <FormInput>
                  <BookingGuestsSelect v-model="booking.guest_id" />
                </FormInput>
              </FormGroup>
            </section>
          </SectionGroup>
        </div>
        <SectionGroup class="lg:col-span-2">
          <section>
            <SectionHeader>
              <SectionTitle>{{ $t("bookings.manage.details.title") }}</SectionTitle>
            </SectionHeader>
            <BookingsDetailsForm
              v-model="booking"
              :errors="createBookingsRequest.errors"
            />
          </section>
        </SectionGroup>
        <SectionGroup class="lg:col-span-2">
          <section>
            <SectionHeader>
              <SectionTitle>{{ $t("bookings.manage.additional_details.title") }}</SectionTitle>
            </SectionHeader>
            <BookingsAdditionalDetailsForm
              v-model="booking"
              :errors="createBookingsRequest.errors"
            />
          </section>
        </SectionGroup>
        <input
          class="hidden"
          type="submit"
        />
      </form>
    </SlideOverBody>
  </SlideOver>
</template>

<script lang="ts" setup>
import SlideOverTitle from "@/contexts/shared/ui/components/slide-over/SlideOverTitle.vue";
import SlideOverBody from "@/contexts/shared/ui/components/slide-over/SlideOverBody.vue";
import SlideOverHeaderActions from "@/contexts/shared/ui/components/slide-over/SlideOverHeaderActions.vue";
import SlideOverHeader from "@/contexts/shared/ui/components/slide-over/SlideOverHeader.vue";
import SlideOver from "@/contexts/shared/ui/components/slide-over/SlideOver.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import useNotification from "@/contexts/shared/composables/useNotification";
import useBookings from "@/contexts/bookings/composables/useBookings";
import type { Booking, UnsavedBooking } from "@/contexts/bookings/models/Booking";
import SectionHeader from "@/contexts/shared/ui/components/section/SectionHeader.vue";
import SectionGroup from "@/contexts/shared/ui/components/section/SectionGroup.vue";
import SectionTitle from "@/contexts/shared/ui/components/section/SectionTitle.vue";
import BookingsDetailsForm from "@/contexts/bookings/ui/components/BookingsDetailsForm.vue";
import BookingsAdditionalDetailsForm from "@/contexts/bookings/ui/components/BookingsAdditionalDetailsForm.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import AccommodationsSelect from "@/contexts/accommodations/ui/components/AccommodationsSelect.vue";
import BookingGuestsSelect from "@/contexts/booking-guests/ui/components/BookingGuestsSelect.vue";

const emit = defineEmits<{
  fetchList: [];
}>();
const { t } = useI18n();
const { empty, createBooking } = useBookings();
const { successNotification, errorNotification } = useNotification();

useHead({ title: () => `${t("bookings.create.title")} - Hussbook` });

const slideOverElement = ref<InstanceType<typeof SlideOver>>();
const booking = ref<UnsavedBooking>(empty);

const createBookingsRequest = useRequest<Booking, UnsavedBooking>({
  promise: () => createBooking(booking.value),
  onSuccess: () => {
    successNotification("bookings.create.success");
    emit("fetchList");
    slideOverElement.value?.close();
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("bookings.create.error");
    }
  },
});

const submit = async () => {
  await createBookingsRequest.fetch();
};
</script>
