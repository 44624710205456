import type { SearchCleaningGroupsCriteria } from "@/contexts/cleaning-groups/models/SearchCleaningGroupsCriteria";
import CleaningGroupsClient from "@/clients/cleaning-groups";
import SearchCleaningGroupFactory from "@/contexts/cleaning-groups/composables/factories/SearchCleaningGroupFactory";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import CreateCleaningGroupFactory from "@/contexts/cleaning-groups/composables/factories/CreateCleaningGroupFactory";
import type { CleaningGroup, UnsavedCleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import ShowCleaningGroupFactory from "@/contexts/cleaning-groups/composables/factories/ShowCleaningGroupFactory";

export default function useCleaningGroups() {
  const searchCleaningGroups = async (criteria: SearchCleaningGroupsCriteria, cursor?: string): Promise<Pagination<CleaningGroup>> => {
    return SearchCleaningGroupFactory.fromSearchCleaningGroupResponse(await CleaningGroupsClient.searchCleaningGroups(SearchCleaningGroupFactory.toSearchCleaningGroupRequest(criteria, cursor)));
  };
  const createCleaningGroup = async (cleaningGroup: UnsavedCleaningGroup): Promise<CleaningGroup> => {
    return CreateCleaningGroupFactory.fromCreateCleaningGroupResponse(await CleaningGroupsClient.createCleaningGroup(CreateCleaningGroupFactory.toCreateCleaningGroupRequest(cleaningGroup)));
  };
  const showCleaningGroup = async (id: string): Promise<CleaningGroup> => {
    return ShowCleaningGroupFactory.fromShowCleaningGroupResponse(await CleaningGroupsClient.showCleaningGroup(id));
  };
  const updateCleaningGroup = async (id: string, cleaningGroup: UnsavedCleaningGroup): Promise<void> => {
    return await CleaningGroupsClient.updateCleaningGroup(id, CreateCleaningGroupFactory.toCreateCleaningGroupRequest(cleaningGroup));
  };
  const archiveCleaningGroup = async (id: string): Promise<void> => {
    return await CleaningGroupsClient.archiveCleaningGroup(id);
  };
  const unarchiveCleaningGroup = async (id: string): Promise<void> => {
    return await CleaningGroupsClient.unarchiveCleaningGroup(id);
  };
  const deleteCleaningGroup = async (id: string): Promise<void> => {
    return await CleaningGroupsClient.deleteCleaningGroup(id);
  };

  return {
    searchCleaningGroups,
    showCleaningGroup,
    createCleaningGroup,
    updateCleaningGroup,
    archiveCleaningGroup,
    deleteCleaningGroup,
    unarchiveCleaningGroup,
  };
}
