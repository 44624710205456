import type { components } from "@/libs/api/schema";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import { ownerFromResponse } from "@/contexts/owners/utils/ownerFromResponse";
import { cleaningGroupFromResponse } from "@/contexts/cleaning-groups/utils/cleaningGroupFromResponse";
import { accommodationKitchenFromResponse } from "@/contexts/accommodations/utils/accommodationKitchenFromResponse";
import { accommodationBedroomFromResponse } from "@/contexts/accommodations/utils/accommodationBedroomFromResponse";
import { accommodationBathroomFromResponse } from "@/contexts/accommodations/utils/accommodationBathroomFromResponse";
import { accommodationChannelFromResponse } from "@/contexts/accommodations/utils/accommodationChannelFromResponse";
import { accommodationPhotoFromResponse } from "@/contexts/accommodation-photos/utils/accommodationPhotoFromResponse";
import { accommodationAttachmentFromResponse } from "@/contexts/accommodation-attachments/utils/accommodationAttachmentFromResponse";
import { accommodationContractFromResponse } from "@/contexts/accommodation-contracts/utils/accommodationContractFromResponse";
import { bookingFromResponse } from "@/contexts/bookings/utils/bookingFromResponse";
import { accommodationDescriptionFromResponse } from "@/contexts/accommodations/utils/accommodationDescriptionFromResponse";
import { accommodationExtraFromResponse } from "@/contexts/accommodations/utils/accommodationExtraFromResponse";

export const accommodationFromResponse = (response: components["schemas"]["Accommodation"]): Accommodation => {
  return {
    id: response.id,
    name: response.name,
    acronym: response.acronym,
    type: response.type,
    capacity: response.capacity,
    featured: response.featured,
    registration_code: response.registration_code,
    land_registration_code: response.land_registration_code,
    indoor_area: response.indoor_area,
    total_area: response.total_area,
    floors: response.floors,
    facilities: response.facilities,
    check_in_time_from: response.check_in_time_from,
    check_in_time_to: response.check_in_time_to,
    check_out_time: response.check_out_time,
    notes: response.notes,
    street: response.street,
    city: response.city,
    postal_code: response.postal_code,
    country_code: response.country_code,
    latitude: response.latitude,
    longitude: response.longitude,

    attachments_count: response.attachments_count,
    bookings_count: response.bookings_count,
    contracts_count: response.contracts_count,
    photos_count: response.photos_count,
    bathrooms_count: response.bathrooms_count,
    bedrooms_count: response.bedrooms_count,
    kitchens_count: response.kitchens_count,
    channels_count: response.channels_count,
    descriptions_count: response.descriptions_count,
    extras_count: response.extras_count,

    owner_id: response.owner_id,
    cleaning_group_id: response.cleaning_group_id,
    default_attachment_id: response.default_attachment_id,

    bedrooms: response.bedrooms?.map((item) => accommodationBedroomFromResponse(item)),
    bathrooms: response.bathrooms?.map((item) => accommodationBathroomFromResponse(item)),
    kitchens: response.kitchens?.map((item) => accommodationKitchenFromResponse(item)),
    attachments: response.attachments?.map((item) => accommodationAttachmentFromResponse(item)),
    bookings: response.bookings?.map((item) => bookingFromResponse(item)),
    cleaning_group: response.cleaning_group ? cleaningGroupFromResponse(response.cleaning_group) : undefined,
    contracts: response.contracts?.map((item) => accommodationContractFromResponse(item)),
    owner: response.owner ? ownerFromResponse(response.owner) : undefined,
    photos: response.photos?.map((item) => accommodationPhotoFromResponse(item)),
    channels: response.channels?.map((item) => accommodationChannelFromResponse(item)),
    descriptions: response.descriptions?.map((item) => accommodationDescriptionFromResponse(item)),
    extras: response.extras?.map((item) => accommodationExtraFromResponse(item)),

    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
    archived_at: response.archived_at ? new Date(response.archived_at) : null,
  };
};
