import type { Router, RouteRecordRaw } from "vue-router";
import Middleware from "@/contexts/shared/models/Middleware";
import DashboardPage from "@/contexts/dashboard/ui/pages/DashboardPage.vue";

const routes: RouteRecordRaw[] = [
  {
    name: "dashboard",
    path: "/",
    component: DashboardPage,
    meta: {
      group: "dashboard",
      layout: "dashboard",
      middleware: [Middleware.auth],
    },
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
