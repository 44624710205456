import type { ComputedRef, ModelRef, Ref } from "vue";
import useDayjs from "@/contexts/shared/composables/useDayjs";

export default function useCalendar<T extends Record<string, any>>(range: { value: Ref<T> | ModelRef<T> | ComputedRef<T>; key: keyof T }) {
  const dayjs = useDayjs();
  const { t } = useI18n();

  const days = computed<Date[]>(() => {
    const dates: Date[] = [];
    for (let d = dayjs(range.value.value[range.key].from).clone().toDate(); d <= dayjs(range.value.value[range.key].to).clone().toDate(); d.setDate(d.getDate() + 1)) {
      dates.push(dayjs(d).clone().toDate());
    }
    return dates;
  });

  const months = t("shared.dayjs.months").split("_");
  const monthsShort = t("shared.dayjs.months_short").split("_");
  const monthTitle = computed<string>(() => {
    const from = dayjs(range.value.value[range.key].from).clone();
    const to = dayjs(range.value.value[range.key].to).clone();
    if (dayjs(from).get("month") !== dayjs(to).get("month")) {
      if (dayjs(from).get("year") !== dayjs(to).get("year")) {
        return `${monthsShort[dayjs(from).get("month")]} ${dayjs(from).get("year")} - ${monthsShort[dayjs(to).get("month")]} ${dayjs(to).get("year")}`;
      }
      return `${monthsShort[dayjs(from).get("month")]} - ${monthsShort[dayjs(to).get("month")]} ${dayjs(from).get("year")}`;
    }
    return `${months[dayjs(from).get("month")]} ${dayjs(from).get("year")}`;
  });

  const getPrev7Days = () => {
    const week = dayjs(range.value.value[range.key].from).subtract(7, "days");
    return { from: week.clone().toDate(), to: week.add(6, "days").toDate() };
  };
  const getNext7Days = () => {
    const week = dayjs(range.value.value[range.key].from).add(7, "days");
    return { from: week.toDate(), to: week.add(6, "days").toDate() };
  };

  return {
    days,
    monthTitle,
    getPrev7Days,
    getNext7Days,
  };
}
