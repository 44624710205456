import type { Accommodation, UnsavedAccommodation } from "@/contexts/accommodations/models/Accommodation";
import type { paths } from "@/libs/api/schema";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";

const toUpdateAccommodationAddressRequest = (accommodation: UnsavedAccommodation): paths["/accommodations/{accommodation}/address"]["put"]["requestBody"]["content"]["application/json"] => {
  return {
    street: accommodation.street,
    city: accommodation.city,
    postal_code: accommodation.postal_code,
    country_code: accommodation.country_code,
    latitude: accommodation.latitude,
    longitude: accommodation.longitude,
  };
};

const fromUpdateAccommodationAddressResponse = (response: paths["/accommodations/{accommodation}/address"]["put"]["responses"][200]["content"]["application/json"] | undefined): Accommodation => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationFromResponse(response.data);
};

export default {
  toUpdateAccommodationAddressRequest,
  fromUpdateAccommodationAddressResponse,
};
