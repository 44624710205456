import type { AccountSeason, AccountSeasonType } from "@/contexts/accounts/models/AccountSeason";
import type SearchAccountSeasonsResponse from "@/clients/account-seasons/SearchAccountSeasons/response/SearchAccountSeasonsResponse";

const fromSearchAccountSeasonsResponse = (response: SearchAccountSeasonsResponse): Record<number, AccountSeason | null> => {
  const data: Record<number, AccountSeason | null> = {};
  Object.keys(response.data).forEach((item) => {
    const week = response.data[Number(item)];
    data[Number(item)] = week
      ? {
          id: week.id,
          year: week.year,
          week: week.week,
          type: week.type as AccountSeasonType,
          amount: week.amount,
          created_at: new Date(week.created_at),
          updated_at: new Date(week.updated_at),
        }
      : null;
  });
  return data;
};

export default {
  fromSearchAccountSeasonsResponse,
};
