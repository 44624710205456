<template>
  <SlideOver
    ref="slideOverElement"
    :parent-route="{ name: 'accommodations.prices' }"
    route
    size="3xl"
  >
    <SlideOverHeader>
      <SlideOverTitle>{{ $t("accommodations.prices.bulk.title") }}</SlideOverTitle>
      <SlideOverHeaderActions>
        <button
          class="btn-white"
          type="button"
          @click.prevent="slideOverElement?.close()"
        >
          {{ $t("shared.form.cancel") }}
        </button>
        <button
          :disabled="updateAccommodationsPricesRequest.isLoading"
          class="btn-primary"
          type="submit"
          form="update_accommodation_prices_form"
        >
          {{ $t("shared.form.save") }}
        </button>
      </SlideOverHeaderActions>
    </SlideOverHeader>
    <SlideOverBody>
      <form
        id="update_accommodation_prices_form"
        class="grid gap-3 md:grid-cols-2"
        @submit.prevent="updateAccommodationsPricesRequest.fetch()"
      >
        <div class="md:col-span-2">
          <FormGroup
            id="accommodations.prices.bulk.form.accommodations"
            :errors="updateAccommodationsPricesRequest.errors.accommodations"
          >
            <FormLabel>{{ $t("accommodations.prices.bulk.form.accommodations") }}</FormLabel>
            <AccommodationsSelect v-model="form.accommodations[0]" />
          </FormGroup>
        </div>
        <FormGroup
          id="accommodations.prices.bulk.form.date.from"
          :errors="updateAccommodationsPricesRequest.errors.from"
        >
          <FormLabel>{{ $t("accommodations.prices.bulk.form.date.from") }}</FormLabel>
          <FormDatePicker
            v-model="form.date.from"
            :max-date="form.date.to"
          />
        </FormGroup>
        <FormGroup
          id="accommodations.prices.bulk.form.date.to"
          :errors="updateAccommodationsPricesRequest.errors.to"
        >
          <FormLabel>{{ $t("accommodations.prices.bulk.form.date.to") }}</FormLabel>
          <FormDatePicker
            v-model="form.date.to"
            :min-date="form.date.from"
          />
        </FormGroup>
        <div class="md:col-span-2">
          <AccommodationsPriceWeekdayLimitInput
            v-model="form.weekday"
            :errors="updateAccommodationsPricesRequest.errors.weekday"
          />
        </div>
        <div class="md:col-span-2">
          <AccommodationsPricesInput
            v-model="form.prices"
            :errors="updateAccommodationsPricesRequest.errors.prices"
          />
        </div>
        <FormGroup
          id="`minimum_stay"
          :errors="updateAccommodationsPricesRequest.errors.minimum_stay"
        >
          <FormLabel>{{ $t("accommodations.prices.minimum_stay") }}</FormLabel>
          <FormInteger
            v-model="form.minimum_stay"
            :min="1"
            name="`minimum_stay"
          />
        </FormGroup>
        <input
          class="hidden"
          type="submit"
        />
      </form>
    </SlideOverBody>
  </SlideOver>
</template>

<script setup lang="ts">
import { useEventBus } from "@vueuse/core";
import SlideOver from "@/contexts/shared/ui/components/slide-over/SlideOver.vue";
import SlideOverTitle from "@/contexts/shared/ui/components/slide-over/SlideOverTitle.vue";
import SlideOverHeader from "@/contexts/shared/ui/components/slide-over/SlideOverHeader.vue";
import SlideOverBody from "@/contexts/shared/ui/components/slide-over/SlideOverBody.vue";
import AccommodationsPriceWeekdayLimitInput from "@/contexts/accommodations/ui/components/AccommodationsPriceWeekdayLimitInput.vue";
import FormDatePicker from "@/contexts/shared/ui/components/form/FormDatePicker.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import AccommodationsSelect from "@/contexts/accommodations/ui/components/AccommodationsSelect.vue";
import AccommodationsPricesInput from "@/contexts/accommodations/ui/components/AccommodationsPricesInput.vue";
import type { UpdateAccommodationsPrices } from "@/contexts/accommodations/models/UpdateAccommodationsPrices";
import useRequest from "@/contexts/shared/composables/useRequest";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import SlideOverHeaderActions from "@/contexts/shared/ui/components/slide-over/SlideOverHeaderActions.vue";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";

const emit = defineEmits<{
  fetchList: [];
}>();

const { t } = useI18n();
const router = useRouter();
const accountBus = useEventBus<string>("account");
const { updateAccommodationsPrices } = useAccommodations();

accountBus.on(() => router.push({ name: "accommodations" }));
useHead({ title: () => `${t("accommodations.prices.bulk.title")} - Hussbook` });

const slideOverElement = ref<InstanceType<typeof SlideOver>>();
const form = ref<UpdateAccommodationsPrices>({
  accommodations: [null],
  date: { from: null, to: null },
  weekday: [0, 1, 2, 3, 4, 5, 6],
  prices: {},
  minimum_stay: null,
});

const updateAccommodationsPricesRequest = useRequest({
  promise: () => updateAccommodationsPrices(form.value),
  onSuccess: () => {
    // todo success notification
    slideOverElement.value?.close();
    emit("fetchList");
  },
  onFailure: () => {
    // todo failure notification
  },
});
</script>
