<template>
  <div ref="target">
    <template v-if="bookingsAccommodationRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="bookingsAccommodationRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="bookingsAccommodationRequest.data.data.length === 0">
      <SectionEmptyState>
        {{ $t("accommodations.manage.bookings.empty") }}
      </SectionEmptyState>
    </template>
    <template v-else>
      <div class="space-y-3">
        <ListTable>
          <ListTableHeader>
            <ListTableHeaderCell primary>{{ $t("bookings.props.code") }}</ListTableHeaderCell>
            <ListTableHeaderCell>{{ $t("bookings.props.check_in") }}</ListTableHeaderCell>
            <ListTableHeaderCell>{{ $t("bookings.props.check_out") }}</ListTableHeaderCell>
            <ListTableHeaderCell optional>{{ $t("bookings.props.adults") }}</ListTableHeaderCell>
            <ListTableHeaderCell optional>{{ $t("bookings.props.children") }}</ListTableHeaderCell>
            <ListTableHeaderCell optional>{{ $t("bookings.props.language") }}</ListTableHeaderCell>
          </ListTableHeader>
          <ListTableBody>
            <template
              v-for="booking in bookingsAccommodationRequest.data.data"
              :key="booking.id"
            >
              <ListTableBodyRowLink :to="{ name: 'bookings.manage', params: { id: booking.id } }">
                <ListTableBodyCell>{{ booking.code }}</ListTableBodyCell>
                <ListTableBodyCell>{{ booking.check_in.toLocaleDateString() }}</ListTableBodyCell>
                <ListTableBodyCell>{{ booking.check_out.toLocaleDateString() }}</ListTableBodyCell>
                <ListTableBodyCell optional>{{ booking.adults }}</ListTableBodyCell>
                <ListTableBodyCell optional>{{ booking.children }}</ListTableBodyCell>
                <ListTableBodyCell optional>{{ booking.language ? $t(`shared.language.${booking.language.toLowerCase()}`) : "-" }}</ListTableBodyCell>
              </ListTableBodyRowLink>
            </template>
          </ListTableBody>
        </ListTable>
        <CursorPagination
          :meta="bookingsAccommodationRequest.data.meta"
          :total="bookingsAccommodationRequest.data.total"
          @change-page="changePage"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import useVisibility from "@/contexts/shared/composables/useVisibility";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import { emptyPagination, type Pagination } from "@/contexts/shared/models/Pagination";
import type { Booking } from "@/contexts/bookings/models/Booking";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRowLink from "@/contexts/shared/ui/components/list/ListTableBodyRowLink.vue";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { BookingsAccommodationsCriteria } from "@/contexts/accommodations/models/BookingsAccommodationsCriteria";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const { bookingsAccommodation } = useAccommodations();

const target = ref<HTMLElement>();
const { criteria, cursor, changePage } = useCriteria<BookingsAccommodationsCriteria>({ per_page: 10 }, () => bookingsAccommodationRequest.fetch());
useVisibility(target, () => bookingsAccommodationRequest.fetch());

const bookingsAccommodationRequest: UseRequest<Pagination<Booking>> = useRequest<Pagination<Booking>>({
  initialLoading: true,
  value: emptyPagination<Booking>(),
  promise: () => bookingsAccommodation(props.accommodation.id, criteria.value, cursor.value),
  onSuccess: (response) => {
    if (cursor.value && response.data.length === 0) {
      changePage();
      return;
    }
  },
});
</script>
