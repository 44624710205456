<template>
  <ListTable>
    <ListTableHeader>
      <ListTableHeaderCell small>
        <FormCheckbox
          id="select_all"
          v-model="selectAll"
          name="select_all"
        />
      </ListTableHeaderCell>
      <ListTableHeaderCell primary>
        {{ $t("bookings.props.code") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell>{{ $t("bookings.props.status") }}</ListTableHeaderCell>
      <ListTableHeaderCell optional>{{ $t("bookings.props.provider") }}</ListTableHeaderCell>
      <ListTableHeaderCell>{{ $t("bookings.props.accommodation_id") }}</ListTableHeaderCell>
      <ListTableHeaderCell>{{ $t("bookings.props.check_in") }}</ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("bookings.props.check_out") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("bookings.props.created_at") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("bookings.props.updated_at") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell small />
    </ListTableHeader>
    <ListTableBody>
      <template
        v-for="booking in bookings"
        :key="booking.id"
      >
        <ListTableBodyRowLink :to="{ name: 'bookings.manage', params: { id: booking.id } }">
          <ListTableBodyCell
            :padding="false"
            :truncate="false"
            class="pr-2.5"
          >
            <FormCheckbox
              :id="booking.id"
              v-model="input"
              :value="booking.id"
              name="selected"
            />
          </ListTableBodyCell>
          <ListTableBodyCell>{{ booking.code }}</ListTableBodyCell>
          <ListTableBodyCell>
            <BookingsStatusBadge :status="booking.status" />
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            <BookingsProviderBadge :provider="booking.provider" />
          </ListTableBodyCell>
          <ListTableBodyCell>
            {{ booking.accommodation?.acronym }}
            <Badge
              v-if="booking.accommodation?.archived_at"
              class="badge-gray ml-2"
            >
              {{ $t("shared.archived") }}
            </Badge>
          </ListTableBodyCell>
          <ListTableBodyCell>{{ booking.check_in.toLocaleDateString() }}</ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ booking.check_out.toLocaleDateString() }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ booking.created_at.toLocaleString() }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ booking.updated_at.toLocaleString() }}
          </ListTableBodyCell>
          <ListTableBodyCell
            :padding="false"
            :truncate="false"
            class="pl-2.5"
          >
            <BookingsDropdown
              :booking="booking"
              @confirmed="$emit('fetchList')"
              @cancelled="$emit('fetchList')"
              @deleted="$emit('fetchList')"
            />
          </ListTableBodyCell>
        </ListTableBodyRowLink>
      </template>
    </ListTableBody>
  </ListTable>
</template>

<script lang="ts" setup>
import type { Booking } from "@/contexts/bookings/models/Booking";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableBodyRowLink from "@/contexts/shared/ui/components/list/ListTableBodyRowLink.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import FormCheckbox from "@/contexts/shared/ui/components/form/FormCheckbox.vue";
import BookingsDropdown from "@/contexts/bookings/ui/components/BookingsDropdown.vue";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";
import BookingsProviderBadge from "@/contexts/bookings/ui/components/BookingsProviderBadge.vue";
import BookingsStatusBadge from "@/contexts/bookings/ui/components/BookingsStatusBadge.vue";

const props = defineProps<{
  bookings: Booking[];
  selected?: string[];
}>();

const emit = defineEmits<{
  fetchList: [];
  "update:selected": [value: string[]];
}>();

const input = computed<string[]>({
  get: () => props.selected ?? [],
  set: (value) => emit("update:selected", value),
});
const selectAll = computed<boolean>({
  get: () => props.selected?.length === props.bookings.length,
  set: (value) => (input.value = value ? props.bookings.map((item) => item.id) : []),
});
</script>
