<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodations.prices.sync.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form
      id="sync_accommodation_prices_form"
      class="space-y-3.5"
      @submit.prevent="syncAccommodationPricesRequest.fetch()"
    >
      <FormGroup
        id="accommodations.prices.sync.from"
        :errors="syncAccommodationPricesRequest.errors.from"
      >
        <FormLabel>{{ $t("accommodations.prices.sync.form.from") }}</FormLabel>
        <FormDatePicker v-model="form.from" />
      </FormGroup>
      <FormGroup
        id="accommodations.prices.sync.to"
        :errors="syncAccommodationPricesRequest.errors.to"
      >
        <FormLabel>{{ $t("accommodations.prices.sync.form.to") }}</FormLabel>
        <FormDatePicker
          v-model="form.to"
          :min-date="form.from"
        />
      </FormGroup>
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-primary"
      type="submit"
      form="sync_accommodation_prices_form"
    >
      {{ $t("accommodations.prices.sync.form.submit") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script setup lang="ts">
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import useNotification from "@/contexts/shared/composables/useNotification";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import useRequest from "@/contexts/shared/composables/useRequest";
import FormDatePicker from "@/contexts/shared/ui/components/form/FormDatePicker.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const emit = defineEmits<{
  close: [];
  updated: [];
}>();

const { syncAccommodationPrices } = useAccommodations();
const { successNotification, errorNotification } = useNotification();

const form = ref<{ from: Date | null; to: Date | null }>({ from: new Date(), to: null });

const syncAccommodationPricesRequest = useRequest({
  promise: () => syncAccommodationPrices(props.accommodation.id, form.value.from, form.value.to),
  onSuccess: () => {
    emit("close");
    emit("updated");
    successNotification("accommodations.prices.sync.success");
  },
  onFailure: () => {
    errorNotification("accommodations.prices.sync.error");
  },
});
</script>
