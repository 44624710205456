<template>
  <DropdownMenu>
    <template #button>
      <slot />
    </template>
    <DropdownMenuItemRouterLink
      :disabled="contract.status !== 'draft'"
      :to="{ name: 'accommodations.manage.contract.manage', params: { id: contract.accommodation_id, contractId: contract.id } }"
    >
      {{ $t("accommodation_contracts.manage.title") }}
    </DropdownMenuItemRouterLink>
    <DropdownMenuItemButton
      :disabled="contract.status !== 'draft'"
      @click="approveContractModal?.open()"
    >
      {{ $t("accommodation_contracts.approve.title") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="contract.status !== 'approved'"
      @click="confirmContractModal?.open()"
    >
      {{ $t("accommodation_contracts.confirm.title") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="contract.status !== 'confirmed'"
      @click="contractDownloadModal?.open()"
    >
      {{ $t("accommodation_contracts.pdf.title") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="contract.status === 'confirmed'"
      class="text-red-600 hover:text-red-700"
      @click="deleteContractModal?.open()"
    >
      {{ $t("accommodation_contracts.delete.title") }}
    </DropdownMenuItemButton>
  </DropdownMenu>
  <ModalDialog
    ref="approveContractModal"
    v-slot="{ close }"
  >
    <AccommodationContractsApproveModal
      :contract="contract"
      @approved="$emit('approved')"
      @close="close"
    />
  </ModalDialog>
  <ModalDialog
    ref="confirmContractModal"
    v-slot="{ close }"
    prevent-close
    size="xl"
  >
    <AccommodationContractsConfirmModal
      :contract="contract"
      @confirmed="$emit('confirmed')"
      @close="close"
    />
  </ModalDialog>
  <ModalDialog
    ref="deleteContractModal"
    v-slot="{ close }"
  >
    <AccommodationContractsDeleteModal
      :contract="contract"
      @deleted="$emit('deleted')"
      @close="close"
    />
  </ModalDialog>
  <ModalDialog
    ref="contractDownloadModal"
    size="3xl"
    @opened="pdfContractRequest.fetch()"
    @close="pdfContractRequest.reset()"
  >
    <PreviewPdfModal
      :file="pdfContractRequest.data"
      :has-errors="pdfContractRequest.hasErrors"
      :is-loading="pdfContractRequest.isLoading"
      :name="`${snakeCase(accommodation.acronym)}_contract_${contract.year}.pdf`"
      title="accommodation_contracts.pdf.title"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import DropdownMenu from "@/contexts/shared/ui/components/dropdown/DropdownMenu.vue";
import type { AccommodationContract } from "@/contexts/accommodations/models/AccommodationContract";
import DropdownMenuItemRouterLink from "@/contexts/shared/ui/components/dropdown/DropdownMenuItemRouterLink.vue";
import DropdownMenuItemButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuItemButton.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import AccommodationContractsApproveModal from "@/contexts/accommodation-contracts/ui/modals/AccommodationContractsApproveModal.vue";
import AccommodationContractsConfirmModal from "@/contexts/accommodation-contracts/ui/modals/AccommodationContractsConfirmModal.vue";
import AccommodationContractsDeleteModal from "@/contexts/accommodation-contracts/ui/modals/AccommodationContractsDeleteModal.vue";
import PreviewPdfModal from "@/contexts/shared/ui/modals/PreviewPdfModal.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import useNotification from "@/contexts/shared/composables/useNotification";
import useAccommodationContracts from "@/contexts/accommodation-contracts/composables/useAccommodationContracts";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import { snakeCase } from "lodash-es";

const props = defineProps<{
  accommodation: Accommodation;
  contract: AccommodationContract;
}>();

defineEmits<{
  approved: [];
  confirmed: [];
  deleted: [];
}>();

const { pdfAccommodationContract } = useAccommodationContracts();
const { errorNotification } = useNotification();

const approveContractModal = ref<InstanceType<typeof ModalDialog>>();
const confirmContractModal = ref<InstanceType<typeof ModalDialog>>();
const deleteContractModal = ref<InstanceType<typeof ModalDialog>>();
const contractDownloadModal = ref<InstanceType<typeof ModalDialog>>();

const pdfContractRequest = useRequest({
  initialLoading: true,
  promise: () => pdfAccommodationContract(props.contract.accommodation_id, props.contract.id),
  onFailure: () => errorNotification("accommodation_contracts.pdf.error"),
});
</script>
