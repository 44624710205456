export type Pagination<T> = {
  readonly data: T[];
  readonly meta: PaginationMeta;
  readonly total: number;
};

export type PaginationMeta = {
  readonly next_cursor: string | null;
  readonly path: string | null;
  readonly per_page: number;
  readonly prev_cursor: string | null;
};

const emptyPagination = <T>(): Pagination<T> => ({
  data: [],
  meta: {
    next_cursor: null,
    path: null,
    per_page: 25,
    prev_cursor: null,
  },
  total: 0,
});

export { emptyPagination };
