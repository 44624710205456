<template>
  <TransitionRoot
    :show="isOpen"
    as="template"
    @after-leave="leave"
  >
    <DialogRoot
      as="div"
      class="relative z-20"
      @close="preventClose ? void 0 : close()"
    >
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                :class="classes"
                class="pointer-events-auto relative ml-2.5 w-screen"
              >
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-500"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-500"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="absolute left-0 top-0 -ml-10 flex pr-4 pt-4">
                    <button
                      class="rounded-md p-1 text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      type="button"
                      @click="close"
                    >
                      <FontAwesomeIcon
                        class="h-5 w-5 fill-current"
                        icon="xmark-solid"
                      />
                    </button>
                  </div>
                </TransitionChild>
                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <slot v-bind="{ isOpen, isClosed }" />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </DialogRoot>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { Dialog as DialogRoot, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import type { RouteLocationRaw } from "vue-router";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

const props = withDefaults(
  defineProps<{
    parentRoute?: RouteLocationRaw;
    route?: boolean;
    size?: "medium" | "large" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "full";
    preventClose?: boolean;
  }>(),
  {
    parentRoute: undefined,
    size: "7xl",
  },
);
const emit = defineEmits<{
  opened: [];
  closed: [];
}>();

const router = useRouter();
const isOpen = ref(false);
const isClosed = ref(false);

onMounted(() => {
  if (props.route) {
    open();
  }
});

const open = () => {
  isOpen.value = true;
  setTimeout(() => emit("opened"), 550);
};
const close = () => {
  isOpen.value = false;
};
const leave = () => {
  if (!isClosed.value) {
    isClosed.value = true;
    emit("closed");
    if (props.parentRoute) {
      router.push(props.parentRoute);
    }
  }
};
const classes = computed(() => ({
  "max-w-md": props.size === "medium",
  "max-w-lg": props.size === "large",
  "max-w-xl": props.size === "xl",
  "max-w-2xl": props.size === "2xl",
  "max-w-3xl": props.size === "3xl",
  "max-w-4xl": props.size === "4xl",
  "max-w-5xl": props.size === "5xl",
  "max-w-6xl": props.size === "6xl",
  "max-w-7xl": props.size === "7xl",
  "max-w-full": props.size === "full",
}));

defineExpose({
  open,
  close,
});
</script>
