import { createRouter, createWebHistory } from "vue-router";
import useAuth from "@/contexts/auth/composables/useAuth";
import Middleware from "@/contexts/shared/models/Middleware";
import { configureRouter as configureAuthRouter } from "@/contexts/auth/router";
import { configureRouter as configureDashboardRouter } from "@/contexts/dashboard/router";
import { configureRouter as configureBookingsRouter } from "@/contexts/bookings/router";
import { configureRouter as configureAccommodationsRouter } from "@/contexts/accommodations/router";
import { configureRouter as configureCleaningGroupsRouter } from "@/contexts/cleaning-groups/router";
import { configureRouter as configureSettingsRouter } from "@/contexts/settings/router";
import { configureRouter as configureProfileRouter } from "@/contexts/profile/router";
import { configureRouter as configureSharedRouter } from "@/contexts/shared/router";
import { configureRouter as configureOwnersRouter } from "@/contexts/owners/router";
import { configureRouter as configureBookingCleaningsRouter } from "@/contexts/booking-cleanings/router";
import type { LayoutTypes } from "@/contexts/shared/models/Layout";

declare module "vue-router" {
  interface RouteMeta {
    layout: LayoutTypes;
    group?: string;
    middleware?: Middleware[];
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: [],
});

configureAuthRouter(router);
configureDashboardRouter(router);
configureBookingsRouter(router);
configureAccommodationsRouter(router);
configureOwnersRouter(router);
configureCleaningGroupsRouter(router);
configureSettingsRouter(router);
configureProfileRouter(router);
configureSharedRouter(router);
configureBookingCleaningsRouter(router);

router.beforeResolve(async (to, from, next) => {
  const { isAuthenticated, authenticatedUser, fetchUser, logout, clearAuth } = useAuth();
  if (to.meta.middleware?.includes(Middleware.auth)) {
    if (isAuthenticated.value || to.query.oauth === "true") {
      if (!authenticatedUser.value) {
        try {
          await fetchUser();
        } catch (e) {
          clearAuth();
          return next({ name: "login" });
        }
      }
      if (to.query.oauth === "true") {
        return next({ ...to, query: { ...to.query, oauth: undefined, provider: undefined } });
      }
      return next();
    }
    logout();
    return next({ name: "login", query: { redirect: !["/", ""].includes(to.fullPath) ? to.fullPath : undefined } });
  } else if (to.meta.middleware?.includes(Middleware.guest)) {
    if (isAuthenticated.value) {
      return next({ name: "dashboard" });
    }
  }
  return next();
});

export default router;
