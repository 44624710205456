<template>
  <template v-if="photos && photos?.length > 0">
    <div class="flex items-center justify-between gap-3.5">
      <div>
        <ButtonWithIcon
          :disabled="selected.length === 0"
          class="btn-red"
          icon="trash-can-solid"
          @click="photosDeleteModal?.open()"
        >
          {{ $t("accommodation_photos.delete.title", selected.length) }}
        </ButtonWithIcon>
      </div>
      <div class="flex items-center space-x-2">
        <FormCheckbox
          id="select_all"
          v-model="selectAll"
          name="select_all"
        />
        <FormLabel for="select_all">{{ $t("accommodation_photos.select_all", selectAll ? 1 : 0) }}</FormLabel>
      </div>
    </div>
    <Draggable
      v-model="input"
      class="mt-2.5 grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6"
      handle=".handle"
      item-key="id"
      @end="sortAccommodationPhotosRequest.fetch()"
      @start="() => (selected = [])"
    >
      <template #item="{ element }">
        <div
          :style="{ backgroundImage: `url(${element.url})` }"
          class="relative h-40 rounded border border-gray-100 bg-cover bg-center bg-no-repeat p-2.5"
        >
          <div class="flex justify-between">
            <FormCheckbox
              :id="element.id"
              v-model="selected"
              :name="element.name"
              :value="element.id"
              @click.shift="toggle(element.id)"
            />
            <button
              class="handle cursor-move rounded bg-white p-1 leading-none"
              type="button"
            >
              <FontAwesomeIcon
                class="size-3.5"
                icon="arrows-up-down-left-right-solid"
              />
            </button>
          </div>
        </div>
      </template>
    </Draggable>
  </template>
  <SectionEmptyState v-else>
    {{ $t("accommodations.manage.photos.empty") }}
  </SectionEmptyState>
  <ModalDialog
    ref="photosDeleteModal"
    v-slot="{ close }"
  >
    <AccommodationPhotosDeleteModal
      :accommodation="accommodation"
      :photos="selectedPhotos"
      @close="close"
      @deleted="deletedPhotos"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import Draggable from "vuedraggable";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { AccommodationPhoto } from "@/contexts/accommodation-photos/models/AccommodationPhoto";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";
import FormCheckbox from "@/contexts/shared/ui/components/form/FormCheckbox.vue";
import { cloneDeep } from "lodash-es";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import AccommodationPhotosDeleteModal from "@/contexts/accommodation-photos/ui/modals/AccommodationPhotosDeleteModal.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import useAccommodationPhotos from "@/contexts/accommodation-photos/composables/useAccommodationPhotos";
import useRequest from "@/contexts/shared/composables/useRequest";
import useNotification from "@/contexts/shared/composables/useNotification";

const props = defineProps<{
  accommodation: Accommodation;
  photos: AccommodationPhoto[] | undefined;
}>();

const emit = defineEmits<{
  deleted: [value: AccommodationPhoto[]];
}>();

const { sortAccommodationPhotos } = useAccommodationPhotos();
const { successNotification, errorNotification } = useNotification();

const photosDeleteModal = ref<InstanceType<typeof ModalDialog>>();
const input = ref<AccommodationPhoto[]>([]);
const selected = ref<string[]>([]);
const selectedPhotos = computed<AccommodationPhoto[]>(() => input.value.filter((item) => selected.value.includes(item.id)) ?? []);

onMounted(() => {
  input.value = cloneDeep(props.photos) ?? [];
});
watch(
  () => props.photos,
  (value) => {
    selected.value = [];
    input.value = value ?? [];
  },
  { deep: true },
);

const selectAll = computed<boolean>({
  get: () => selected.value.length === input.value.length,
  set: (value) => (selected.value = value ? input.value.map((item) => item.id) : []),
});

const sortAccommodationPhotosRequest = useRequest({
  promise: () => sortAccommodationPhotos(props.accommodation.id, input.value),
  onSuccess: () => {
    successNotification("accommodations.update.success");
  },
  onFailure: () => {
    errorNotification("accommodations.update.error");
  },
});

const toggle = (photo: string) => {
  const first = input.value.find((item) => item.id === selected.value[selected.value.length - 1]);
  const item = input.value.find((item) => item.id === photo);
  if (selected.value.length === 0 || !first || !item) {
    selected.value.push(photo);
    selected.value = [...new Set(selected.value)];
    return;
  }
  if (selected.value.find((item) => item === photo)) {
    input.value.slice(input.value.indexOf(item), input.value.indexOf(first) + 1)?.forEach((item) => selected.value.splice(selected.value.indexOf(item.id), 1));
    selected.value = [...new Set(selected.value)];
    return;
  }
  input.value.slice(input.value.indexOf(first), input.value.indexOf(item) + 1)?.forEach((item) => selected.value.push(item.id));
  selected.value = [...new Set(selected.value)];
};
const deletedPhotos = (value: AccommodationPhoto[]) => {
  selected.value = [];
  emit("deleted", value);
};
</script>
