class CountryProvider {
  get(): string[] {
    return [
      "af",
      "ax",
      "al",
      "dz",
      "as",
      "ad",
      "ao",
      "ai",
      "aq",
      "ag",
      "ar",
      "am",
      "aw",
      "au",
      "at",
      "az",
      "bs",
      "bh",
      "bd",
      "bb",
      "by",
      "be",
      "bz",
      "bj",
      "bm",
      "bt",
      "bo",
      "bq",
      "ba",
      "bw",
      "bv",
      "br",
      "io",
      "vg",
      "bn",
      "bg",
      "bf",
      "bi",
      "kh",
      "cm",
      "ca",
      "cv",
      "ky",
      "cf",
      "td",
      "cl",
      "cn",
      "cx",
      "cc",
      "co",
      "km",
      "ck",
      "cr",
      "hr",
      "cu",
      "cw",
      "cy",
      "cz",
      "cd",
      "dk",
      "dj",
      "dm",
      "do",
      "tl",
      "ec",
      "eg",
      "sv",
      "gq",
      "er",
      "ee",
      "et",
      "fk",
      "fo",
      "fj",
      "fi",
      "fr",
      "gf",
      "pf",
      "tf",
      "ga",
      "gm",
      "ge",
      "de",
      "gh",
      "gi",
      "gr",
      "gl",
      "gd",
      "gp",
      "gu",
      "gt",
      "gg",
      "gn",
      "gw",
      "gy",
      "ht",
      "hm",
      "hn",
      "hk",
      "hu",
      "is",
      "in",
      "id",
      "ir",
      "iq",
      "ie",
      "im",
      "il",
      "it",
      "ci",
      "jm",
      "jp",
      "je",
      "jo",
      "kz",
      "ke",
      "ki",
      "xk",
      "kw",
      "kg",
      "la",
      "lv",
      "lb",
      "ls",
      "lr",
      "ly",
      "li",
      "lt",
      "lu",
      "mo",
      "mk",
      "mg",
      "mw",
      "my",
      "mv",
      "ml",
      "mt",
      "mh",
      "mq",
      "mr",
      "mu",
      "yt",
      "mx",
      "fm",
      "md",
      "mc",
      "mn",
      "me",
      "ms",
      "ma",
      "mz",
      "mm",
      "na",
      "nr",
      "np",
      "nl",
      "nc",
      "nz",
      "ni",
      "ne",
      "ng",
      "nu",
      "nf",
      "kp",
      "mp",
      "no",
      "om",
      "pk",
      "pw",
      "ps",
      "pa",
      "pg",
      "py",
      "pe",
      "ph",
      "pn",
      "pl",
      "pt",
      "pr",
      "qa",
      "cg",
      "re",
      "ro",
      "ru",
      "rw",
      "bl",
      "sh",
      "kn",
      "lc",
      "mf",
      "pm",
      "vc",
      "ws",
      "sm",
      "st",
      "sa",
      "sn",
      "rs",
      "sc",
      "sl",
      "sg",
      "sx",
      "sk",
      "si",
      "sb",
      "so",
      "za",
      "gs",
      "kr",
      "ss",
      "es",
      "lk",
      "sd",
      "sr",
      "sj",
      "sz",
      "se",
      "ch",
      "sy",
      "tw",
      "tj",
      "tz",
      "th",
      "tg",
      "tk",
      "to",
      "tt",
      "tn",
      "tr",
      "tm",
      "tc",
      "tv",
      "vi",
      "ug",
      "ua",
      "ae",
      "gb",
      "us",
      "um",
      "uy",
      "uz",
      "vu",
      "va",
      "ve",
      "vn",
      "wf",
      "eh",
      "ye",
      "zm",
      "zw",
    ];
  }
}

export default new CountryProvider();
