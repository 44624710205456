import type { UnsavedAccountService } from "@/contexts/accounts/models/AccountService";
import type CreateAccountServiceRequest from "@/clients/account-services/CreateAccountService/request/CreateAccountServiceRequest";
import { pickBy } from "lodash-es";

const toCreateAccountServiceRequest = (accountService: UnsavedAccountService): CreateAccountServiceRequest => {
  return {
    name: pickBy(accountService.name),
    price: accountService.price,
    unit: accountService.unit,
    type: accountService.type,
  };
};

export default {
  toCreateAccountServiceRequest,
};
