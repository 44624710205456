<template>
  <DashboardHeader>
    <DashboardHeaderTitle>{{ $t("dashboard.title") }}</DashboardHeaderTitle>
  </DashboardHeader>
  <div class="space-y-4 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0 xl:grid-cols-3">
    <AnalyticsBookingsByWeekChart />
    <AnalyticsBookingsArrivalsCalendar />
    <AnalyticsBookingsDeparturesCalendar />
    <AnalyticsBookingCleaningsByTypeChart />
  </div>
</template>

<script lang="ts" setup>
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import AnalyticsBookingsByWeekChart from "@/contexts/analytics/ui/components/AnalyticsBookingsByWeekChart.vue";
import AnalyticsBookingsArrivalsCalendar from "@/contexts/analytics/ui/components/AnalyticsBookingsArrivalsCalendar.vue";
import AnalyticsBookingsDeparturesCalendar from "@/contexts/analytics/ui/components/AnalyticsBookingsDeparturesCalendar.vue";
import AnalyticsBookingCleaningsByTypeChart from "@/contexts/analytics/ui/components/AnalyticsBookingCleaningsByTypeChart.vue";

const { t } = useI18n();

useHead({ title: () => `${t("dashboard.title")} - Hussbook` });
</script>
