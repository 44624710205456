import { isArray } from "lodash-es";
import type { paths } from "@/libs/api/schema";

const toPdfBookingCheckInRequest = (id: string | string[]): paths["/bookings/check-in/pdf"]["get"]["parameters"]["query"] => {
  return {
    "id[]": isArray(id) ? id : [id],
  };
};

const fromPdfBookingCheckInResponse = (response: paths["/bookings/check-in/pdf"]["get"]["responses"][200]["content"]["application/json"] | undefined): string => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return response.data;
};

export default {
  toPdfBookingCheckInRequest,
  fromPdfBookingCheckInResponse,
};
