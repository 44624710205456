import axios from "@/libs/axios";
import type UserResponse from "@/clients/auth/User/response/UserResponse";

export default async function user(): Promise<UserResponse> {
  try {
    const { data } = await axios.get<UserResponse>(`/user`);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
