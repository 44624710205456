<template>
  <LineChart
    :data="chartData"
    :options="chartOptions"
  />
  <div
    v-show="tooltipData"
    ref="tooltip"
    :style="floatingStyles"
    class="pointer-events-none absolute z-10 hidden whitespace-nowrap rounded border border-gray-200 bg-white text-sm font-normal drop-shadow-xl sm:inline-block"
  >
    <slot
      name="tooltip"
      v-bind="{ data: tooltipData }"
    >
      <div class="px-4 py-3">
        <div class="mb-2 text-sm font-medium text-gray-900">
          <slot
            name="tooltipTitle"
            v-bind="{ value: tooltipData?.title }"
          >
            {{ tooltipData?.title }}
          </slot>
        </div>
        <template
          v-for="(item, index) in tooltipData?.values"
          :key="index"
        >
          <div class="flex items-center text-gray-800">
            <div class="grow-0">
              <div
                :style="{ borderColor: item.color }"
                class="size-2.5 rounded-full border-2"
              />
            </div>
            <div class="ml-1.5 flex grow items-center">
              <div class="mr-2 grow-0 font-medium">
                {{ item.value }}
              </div>
              <div class="grow">
                {{ $t(item.label) }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { Line as LineChart } from "vue-chartjs";
import { autoPlacement, offset, shift, useFloating } from "@floating-ui/vue";
import type { ChartData, ChartOptions } from "chart.js";
import { CategoryScale, Chart as ChartJS, LinearScale, LineElement, PointElement, Tooltip } from "chart.js";
import useChart from "@/contexts/shared/composables/useChart";
import merge from "lodash-es/merge";

const props = defineProps<{
  values: DataSet[];
  labels: any[];
  options?: Partial<ChartOptions<"line">>;
}>();

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

export type DataSet = {
  values: number[];
  color: string;
  label: string;
  dashed?: boolean;
};

const tooltip = ref<HTMLElement>();
const { tooltipData, createTooltip, tooltipPosition } = useChart();
const { floatingStyles } = useFloating(tooltipPosition, tooltip, { placement: "right", middleware: [shift(), offset(8), autoPlacement()] });

const chartData = computed<ChartData<"line">>(() => {
  return {
    labels: props.labels ?? [],
    datasets: props.values.map((item) => ({
      label: item.label,
      data: item.values ?? [],
      borderColor: item.color,
      backgroundColor: item.color,
      pointBorderColor: "transparent",
      pointHoverBorderColor: item.color,
      pointBorderWidth: 3,
      tension: 0.1,
      borderDash: item.dashed ? [10, 5] : undefined,
    })),
  };
});
const chartOptions = computed<ChartOptions<"line">>(() =>
  merge(
    {
      maintainAspectRatio: false,
      animation: false,
      plugins: {
        tooltip: {
          enabled: false,
          external: createTooltip,
        },
      },
      scales: {
        x: {
          border: {
            dash: [4, 4],
            color: "rgba(17,24,39,0.1)",
          },
          grid: {
            drawBorder: false,
            drawTicks: false,
            color: "rgba(209,213,219,1)",
          },
          ticks: {
            autoSkipPadding: 3,
            color: "rgba(17,24,39,1)",
            padding: 16,
          },
        },
        y: {
          beginAtZero: true,
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            color: "rgba(17,24,39,1)",
            stepSize: 1,
            padding: 6,
          },
        },
      },
      elements: {
        line: {
          borderWidth: 2,
        },
        point: {
          radius: 1,
          backgroundColor: "transparent",
          borderWidth: 0,
          hoverBackgroundColor: "white",
          hoverRadius: 4,
          hoverBorderWidth: 2,
        },
      },
      interaction: { intersect: false, mode: "index" },
    },

    props.options,
  ),
);
</script>
