import type { Accommodation, UnsavedAccommodation } from "@/contexts/accommodations/models/Accommodation";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { paths } from "@/libs/api/schema";

const toUpdateAccommodationDetailsRequest = (accommodation: UnsavedAccommodation): paths["/accommodations/{accommodation}/details"]["put"]["requestBody"]["content"]["application/json"] => {
  return {
    owner_id: accommodation.owner_id,
    cleaning_group_id: accommodation.cleaning_group_id,
    name: accommodation.name,
    acronym: accommodation.acronym,
    featured: accommodation.featured,
    type: accommodation.type,
    check_in_time_from: accommodation.check_in_time_from,
    check_in_time_to: accommodation.check_in_time_to,
    check_out_time: accommodation.check_out_time,
    registration_code: accommodation.registration_code,
    land_registration_code: accommodation.land_registration_code,
    indoor_area: accommodation.indoor_area,
    total_area: accommodation.total_area,
    floors: accommodation.floors,
    notes: accommodation.notes,
  };
};

const fromUpdateAccommodationDetailsResponse = (response: paths["/accommodations/{accommodation}/details"]["put"]["responses"][200]["content"]["application/json"] | undefined): Accommodation => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationFromResponse(response.data);
};

export default {
  toUpdateAccommodationDetailsRequest,
  fromUpdateAccommodationDetailsResponse,
};
