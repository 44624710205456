<template>
  <DropdownMenu>
    <template #button>
      <slot />
    </template>
    <DropdownMenuItemButton @click="bookingCleaningDetailsPreviewModal?.open()">
      {{ $t("booking_cleanings.actions.details") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton @click="bookingCleaningStickerPreviewModal?.open()">
      {{ $t("booking_cleanings.actions.sticker") }}
    </DropdownMenuItemButton>
  </DropdownMenu>
  <ModalDialog
    ref="bookingCleaningDetailsPreviewModal"
    size="3xl"
    @opened="pdfBookingCleaningDetailsRequest.fetch()"
    @close="pdfBookingCleaningDetailsRequest.reset()"
  >
    <PreviewPdfModal
      :file="pdfBookingCleaningDetailsRequest.data"
      :has-errors="pdfBookingCleaningDetailsRequest.hasErrors"
      :is-loading="pdfBookingCleaningDetailsRequest.isLoading"
      :name="`cleaning_details_${cleaning.id}.pdf`"
      title="booking_cleanings.details.title"
    />
  </ModalDialog>
  <ModalDialog
    ref="bookingCleaningStickerPreviewModal"
    size="3xl"
    @opened="pdfBookingCleaningStickerRequest.fetch()"
    @close="pdfBookingCleaningStickerRequest.reset()"
  >
    <PreviewPdfModal
      :file="pdfBookingCleaningStickerRequest.data"
      :has-errors="pdfBookingCleaningStickerRequest.hasErrors"
      :is-loading="pdfBookingCleaningStickerRequest.isLoading"
      :name="`cleaning_sticker_${cleaning.id}.pdf`"
      title="booking_cleanings.sticker.title"
    />
  </ModalDialog>
</template>

<script setup lang="ts">
import DropdownMenu from "@/contexts/shared/ui/components/dropdown/DropdownMenu.vue";
import PreviewPdfModal from "@/contexts/shared/ui/modals/PreviewPdfModal.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import DropdownMenuItemButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuItemButton.vue";
import type { BookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import useRequest from "@/contexts/shared/composables/useRequest";
import useBookingCleanings from "@/contexts/booking-cleanings/composables/useBookingCleanings";
import useNotification from "@/contexts/shared/composables/useNotification";

const props = defineProps<{
  cleaning: BookingCleaning;
}>();

const { errorNotification } = useNotification();
const { pdfBookingCleaningDetails, pdfBookingCleaningSticker } = useBookingCleanings();

const bookingCleaningDetailsPreviewModal = ref<InstanceType<typeof ModalDialog>>();
const bookingCleaningStickerPreviewModal = ref<InstanceType<typeof ModalDialog>>();

const pdfBookingCleaningDetailsRequest = useRequest({
  initialLoading: true,
  promise: () => pdfBookingCleaningDetails(props.cleaning.id),
  onFailure: () => errorNotification("booking_cleanings.details.error"),
});
const pdfBookingCleaningStickerRequest = useRequest({
  initialLoading: true,
  promise: () => pdfBookingCleaningSticker(props.cleaning.id),
  onFailure: () => errorNotification("booking_cleanings.sticker.error"),
});
</script>
