import type { paths } from "@/libs/api/schema";

const fromOAuthRedirectResponse = (response: paths["/oauth/{provider}/redirect"]["get"]["responses"][200]["content"]["application/json"] | undefined): string => {
  if (!response) {
    throw new Error("Invalid response");
  }

  return response.redirect;
};

export default {
  fromOAuthRedirectResponse,
};
