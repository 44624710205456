import type { Account, UnsavedCreateAccount } from "@/contexts/accounts/models/Account";
import type CreateAccountRequest from "@/clients/accounts/CreateAccount/request/CreateAccountRequest";
import type CreateAccountResponse from "@/clients/accounts/CreateAccount/response/CreateAccountResponse";
import { accountFromResponse } from "@/contexts/accounts/utils/accountFromResponse";

const toCreateAccountRequest = (account: UnsavedCreateAccount): CreateAccountRequest => {
  return {
    name: account.name,
    email: account.email,
  };
};
const fromCreateAccountResponse = (response: CreateAccountResponse): Account => {
  return accountFromResponse(response.data);
};

export default {
  toCreateAccountRequest,
  fromCreateAccountResponse,
};
