import type { Option } from "@/contexts/shared/models/Select";
import CountryProvider from "@/contexts/shared/providers/countryProvider";

export default function useCountry() {
  const { t } = useI18n();

  const countryOptions: Option[] = CountryProvider.get().map((item) => ({ value: item, label: t(`shared.country.${item}`) }));

  return {
    countryOptions,
  };
}
