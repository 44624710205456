import type UpdateAccountIntegrationRequest from "@/clients/account-integrations/UpdateAccountIntegration/request/UpdateAccountIntegrationRequest";
import type UpdateAccountIntegrationResponse from "@/clients/account-integrations/UpdateAccountIntegration/response/UpdateAccountIntegrationResponse";
import axios from "@/libs/axios";
import { pickBy } from "lodash-es";

export default async function updateAccountIntegration(id: string, request: UpdateAccountIntegrationRequest): Promise<UpdateAccountIntegrationResponse> {
  try {
    const { data } = await axios.put<UpdateAccountIntegrationResponse>(`/settings/integrations/${id}`, pickBy(request));
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
