<template>
  <div>
    <slot />
    <FormError />
  </div>
</template>

<script lang="ts" setup>
import { v4 as uuid } from "uuid";
import FormError from "@/contexts/shared/ui/components/form/FormError.vue";
import type { Ref } from "vue";

const props = withDefaults(
  defineProps<{
    id?: string;
    errors?: string[];
  }>(),
  {
    id: undefined,
    errors: undefined,
  },
);

const { errors } = toRefs(props);

provide<string>("id", props.id ?? uuid());
provide<Ref<string[] | undefined>>("errors", errors);
</script>
