import type { components, paths } from "@/libs/api/schema";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";

const toUpdateAccommodationFacilitiesRequest = (facilities: string[]): paths["/accommodations/{accommodation}/facilities"]["put"]["requestBody"]["content"]["application/json"] => {
  return {
    facilities: facilities as components["schemas"]["AccommodationFacility"][],
  };
};

const fromUpdateAccommodationFacilitiesResponse = (response: paths["/accommodations/{accommodation}/facilities"]["put"]["responses"][200]["content"]["application/json"] | undefined): Accommodation => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationFromResponse(response.data);
};

export default {
  toUpdateAccommodationFacilitiesRequest,
  fromUpdateAccommodationFacilitiesResponse,
};
