<template>
  <div class="flex items-center gap-3.5">
    <FormRadio
      :id="value"
      v-model="modelValue"
      :value="value"
      name="select"
    />
    <label
      :for="value"
      class="cursor-pointer text-sm text-gray-700"
    >
      {{ label }}
    </label>
  </div>
</template>

<script setup lang="ts">
import FormRadio from "@/contexts/shared/ui/components/form/FormRadio.vue";
import { v4 as uuid } from "uuid";

const props = defineProps<{
  id: string;
  value: string;
  label: string;
}>();

const modelValue = defineModel<string | number | boolean | undefined>({ default: false });

provide<string>("id", props.id ?? uuid());
</script>
