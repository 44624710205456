import type { Booking, BookingChannel, BookingProvider, BookingStatus, UnsavedBooking } from "@/contexts/bookings/models/Booking";
import type CreateBookingRequest from "@/clients/bookings/CreateBooking/request/CreateBookingRequest";
import type CreateBookingResponse from "@/clients/bookings/CreateBooking/response/CreateBookingResponse";
import dayjs from "dayjs";

const toCreateBookingRequest = (booking: UnsavedBooking): CreateBookingRequest => {
  return {
    language: booking.language,
    check_in: booking.check_in ? dayjs(booking.check_in?.toISOString()).format("YYYY-MM-DD") : null,
    check_out: booking.check_out ? dayjs(booking.check_out?.toISOString()).format("YYYY-MM-DD") : null,
    adults: booking.adults,
    children: booking.children,
    is_youngsters: booking.is_youngsters,
    has_pets: booking.has_pets,
    security_deposit: booking.security_deposit,
    notes: booking.notes,
    accommodation_id: booking.accommodation_id,
    guest_id: booking.guest_id,
  };
};

const fromCreateBookingResponse = (response: CreateBookingResponse): Booking => {
  return {
    id: response.id,
    code: response.code,
    status: response.status as BookingStatus,
    price: response.price,
    language: response.language,
    check_in: new Date(response.check_in),
    check_out: new Date(response.check_out),
    adults: response.adults,
    children: response.children,
    is_youngsters: response.is_youngsters,
    has_pets: response.has_pets,
    security_deposit: response.security_deposit,
    notes: response.notes,
    channel: response.channel as BookingChannel,
    provider: response.provider as BookingProvider | null,
    accommodation_id: response.accommodation_id,
    guest_id: response.guest_id,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};

export default {
  toCreateBookingRequest,
  fromCreateBookingResponse,
};
