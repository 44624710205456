import type { SearchAccommodationsPricesCriteria } from "@/contexts/accommodations/models/SearchAccommodationsPricesCriteria";
import type { paths } from "@/libs/api/schema";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { AccommodationPrices } from "@/contexts/accommodations/models/AccommodationPrices";

const toSearchAccommodationsPricesRequest = (criteria: SearchAccommodationsPricesCriteria): paths["/accommodations/prices"]["get"]["parameters"]["query"] => {
  const dayjs = useDayjs();

  return {
    "id[]": criteria.id ?? [],
    from: dayjs(criteria.date.from).startOf("day").format("YYYY-MM-DD"),
    to: dayjs(criteria.date.to).endOf("day").format("YYYY-MM-DD"),
  };
};

const fromSearchAccommodationsPricesResponse = (response: paths["/accommodations/prices"]["get"]["responses"][200]["content"]["application/json"] | undefined): AccommodationPrices[] => {
  return (response?.data ?? []).map((item) => ({ accommodation: accommodationFromResponse(item.accommodation), prices: item.prices, availabilities: item.availabilities, minimum_stays: item.minimum_stays }));
};

export default {
  toSearchAccommodationsPricesRequest,
  fromSearchAccommodationsPricesResponse,
};
