import type { components } from "@/libs/api/schema";
import type { BookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import { cleaningGroupFromResponse } from "@/contexts/cleaning-groups/utils/cleaningGroupFromResponse";
import { bookingFromResponse } from "@/contexts/bookings/utils/bookingFromResponse";

export const bookingCleaningFromResponse = (response: components["schemas"]["BookingCleaning"]): BookingCleaning => {
  return {
    id: response.id,
    assoc: response.assoc ?? null,
    type: response.type ?? null,
    datetime: response.datetime ? new Date(response.datetime) : null,
    single_beds: response.single_beds,
    double_beds: response.double_beds,
    suite_beds: response.suite_beds,
    bunk_beds: response.bunk_beds,
    notes: response.notes,
    booking_id: response.booking_id,
    cleaning_group_id: response.cleaning_group_id ?? null,
    booking: response.booking ? bookingFromResponse(response.booking) : undefined,
    cleaning_group: response.cleaning_group ? cleaningGroupFromResponse(response.cleaning_group) : undefined,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
