import { Loader } from "@googlemaps/js-api-loader";

export const GMapId = "f34db40ca36f3d60";
export const GMapZoom = 5;
export const GMapDefaultPosition: GMapsCoordinates = { lat: 40.463667, lng: -3.74922 };

export type GMapsAddress = {
  street?: string;
  number?: string;
  city?: string;
  postal_code?: string;
  country_code?: string;
  lat?: number;
  lng?: number;
};

export type GMapsCoordinates = {
  lat: number;
  lng: number;
};

export default function useMaps() {
  const loader = reactive<Loader>(
    new Loader({
      apiKey: String(import.meta.env.VITE_GMAPS_KEY),
      libraries: ["places"],
    }),
  );

  const loadMap = (element: HTMLElement) => {
    return new google.maps.Map(element, {
      center: GMapDefaultPosition,
      mapId: GMapId,
      zoom: GMapZoom,
      zoomControl: false,
      mapTypeControl: false,
      clickableIcons: false,
      keyboardShortcuts: false,
      streetViewControl: false,
      fullscreenControl: false,
      minZoom: 3,
      maxZoom: 18,
    });
  };

  const searchMapInput = (element: HTMLInputElement) => {
    return new google.maps.places.SearchBox(element);
  };

  const mapLatLngBounds = () => {
    return new google.maps.LatLngBounds();
  };

  const mapPinIcon = (): string | null | google.maps.Icon | google.maps.Symbol => {
    return {
      url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAALNSURBVEiJlZXPa1RXFMc/5/1MrBnUKFQqum2EUkEX4g9CAw3JTAiNUMFNV+2+FIrb9A9wqeLKCK5cCAlhjD/aqS6MIF21Yml3JRZaJyGZaDLvzdx7XLwkvpl5bzKe1bvfc+7ne8+7990nqkrXKBZHEDkPnGo07Om4aXzT1P+t1Veuy/zA40dXuk2XXIMLFwaJouvA11npODas1iI8V5Y83xspPH7wd1adkwkvlb4kil7kwQGCwGVwfz8KR+qb8Z/rw6OXe+tgcvIoxvwOFPLg6VCF6soGalUHPvLP9FcePsvvQEQw5mav8GQKFAZCrCIbkSkj0sJsNRgf/xYY6RW+HWHg0hd6NBp2/+q5kZl8A5HJHEYDmEbkOCLHgektbSf6+jwArGE0rXttoJOZeJHvmZ+/llJ+YmLiNapXtwXfS9babJpD2R2MjR0GDmfg37Jnz40ONdHebg9dV3AcwSrO6tkvTnQaiBzJXD1UuXPHdKiJVm1ZrSOJmed+3mkQBP/kGByjVDrWoSZai25McuTFcX/rNJid/Q/YzDG5xdTUvp1R8nwrXWCtoqqIwN7K/T+29dZNFqmgWswwGCaOX1IqPdgajwIfpwviOHmLrue8Tuvtp2gGyDJgC/hNTo6NehOAwHNvp/XW78DaOWAlD5IXxihxbBBBm7E/nW9QLkeo/vihButvYgD6QnfmwLNyLZ3Lvq5LpXvAWC/wer2ZXNueUz24+Muh9nz2da36HbC2G9xapfYmxhE0CJ3Mvcs2KJeXUP1hN4Paeoy1Shi6M4VfHz3Pqsn/owEUi3cRmcpKbdabrNUiAt/998DTnz/JQ2R38D4uAQ/bxfp7+DI2HOoG6G5QLke47lfAkx14lGyq7zlrfmg/bT81H2YAMDe3gedNoLpYjwyraxG+56z3+8HQQKVS3W169z1Ix8WL/StLy38pOH7B/6ywsNDTB/kOUn4QDrduJz8AAAAASUVORK5CYII=",
      size: new google.maps.Size(71, 71),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 34),
      scaledSize: new google.maps.Size(25, 25),
    };
  };

  const mapMarker = (map: google.maps.Map | undefined, coordinates: GMapsCoordinates, title?: string | null): google.maps.Marker => {
    return new google.maps.Marker({
      map: map,
      position: { lat: coordinates.lat, lng: coordinates.lng },
      title: title,
      icon: mapPinIcon(),
    });
  };

  const getCoordinates = (lat?: number | null, lng?: number | null): GMapsCoordinates | undefined => {
    if (!lat || !lng) {
      return undefined;
    }

    return {
      lat: lat,
      lng: lng,
    };
  };

  return {
    loader,
    loadMap,
    mapMarker,
    mapPinIcon,
    searchMapInput,
    getCoordinates,
    mapLatLngBounds,
  };
}
