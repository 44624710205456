import type { components } from "@/libs/api/schema";
import type { AccommodationDescription } from "@/contexts/accommodations/models/AccommodationDescription";

export const accommodationDescriptionFromResponse = (response: components["schemas"]["AccommodationDescription"]): AccommodationDescription => {
  return {
    id: response.id,
    accommodation_id: response.accommodation_id,
    language: response.language,
    summary: response.summary,
    overview: response.overview,
    services: response.services,
    additional_features: response.additional_features,
    additional_rules: response.additional_rules,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
