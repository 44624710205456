import type { BodySerializer } from "openapi-fetch";
import type { OperationRequestBodyContent } from "openapi-typescript-helpers";

export const formDataBodySerializer = (body: OperationRequestBodyContent<any> | undefined): any => {
  const fd = new FormData();
  for (const name in body) {
    const value = body[name as keyof BodySerializer<typeof body>];
    if (value) {
      fd.append(name, value);
    }
  }
  return fd;
};
