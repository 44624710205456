<template>
  <div class="grid divide-y divide-gray-200 border border-gray-200">
    <template
      v-for="item in los"
      :key="item"
    >
      <div class="p-2">
        <FormGroup
          :id="`los_${item}`"
          :errors="errors?.[item]"
        >
          <FormLabel>{{ $t("accommodations.prices.los", Number(item)) }}</FormLabel>
          <FormAmount
            v-model="modelValue[item]"
            :name="`los_${item}`"
          />
        </FormGroup>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import useAuth from "@/contexts/auth/composables/useAuth";
import FormAmount from "@/contexts/shared/ui/components/form/FormAmount.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";

defineProps<{
  errors?: Record<number, string[]>;
}>();

const { authenticatedAccount } = useAuth();

const modelValue = defineModel<Record<string, number>>({ default: {} });

const los = computed<number[]>(() => authenticatedAccount.value?.los.map((item) => Number(item)) ?? []);
</script>
