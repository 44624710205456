import axios from "@/libs/axios";

export default async function createAccommodationAttachment(accommodationId: string, file: File): Promise<void> {
  const request = new FormData();
  request.append("file", file);
  try {
    await axios.post(`/accommodations/${accommodationId}/attachments`, request, { headers: { "Content-Type": "multipart/form-data" } });
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
