import type GetAccommodationAttachmentsResponse from "@/clients/accommodation-attachments/GetAccommodationAttachments/response/GetAccommodationAttachmentsResponse";
import type { AccommodationAttachment } from "@/contexts/accommodation-attachments/models/AccommodationAttachment";

const fromGetAccommodationAttachmentsResponse = (response: GetAccommodationAttachmentsResponse): AccommodationAttachment[] => {
  return response.data.map((item) => ({
    id: item.id,
    accommodation_id: item.accommodation_id,
    name: item.name,
    extension: item.extension,
    size: item.size,
    created_at: new Date(item.created_at),
    updated_at: new Date(item.updated_at),
  }));
};

export default {
  fromGetAccommodationAttachmentsResponse,
};
