<template>
  <ConfirmDeleteModal
    :is-loading="deleteBookingRequest.isLoading"
    :description="$t('bookings.delete.description')"
    :title="$t('bookings.delete.title')"
    @close="$emit('close')"
    @confirm="deleteBookingRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import useRequest from "@/contexts/shared/composables/useRequest";
import useNotification from "@/contexts/shared/composables/useNotification";
import useBookings from "@/contexts/bookings/composables/useBookings";
import { isArray } from "lodash-es";
import ConfirmDeleteModal from "@/contexts/shared/ui/modals/ConfirmDeleteModal.vue";

const props = defineProps<{
  id: string;
}>();

const emit = defineEmits<{
  close: [];
  deleted: [];
}>();

const { t } = useI18n();
const { deleteBooking } = useBookings();
const { successNotification, errorNotification } = useNotification();

const deleteBookingRequest = useRequest({
  promise: () => deleteBooking(props.id),
  onSuccess: () => {
    successNotification(t("bookings.delete.success", isArray(props.id) ? props.id.length : 1));
    emit("deleted");
    emit("close");
  },
  onFailure: () => {
    errorNotification(t("bookings.delete.error", isArray(props.id) ? props.id.length : 1));
  },
});
</script>
