import type { BookingImportProvider } from "@/contexts/booking-imports/models/BookingImport";
import type { paths } from "@/libs/api/schema";

const toCreateBookingImportRequestFactory = (bookingImport: { provider: BookingImportProvider | undefined; file: File | undefined }): paths["/bookings/imports"]["post"]["requestBody"]["content"]["multipart/form-data"] => {
  return { provider: bookingImport.provider!, file: bookingImport.file! };
};

export default {
  toCreateBookingImportRequestFactory,
};
