import DashboardLayout from "@/contexts/shared/ui/layouts/DashboardLayout.vue";
import AuthLayout from "@/contexts/shared/ui/layouts/AuthLayout.vue";
import type { DefineComponent } from "vue";
import EmptyLayout from "@/contexts/shared/ui/layouts/EmptyLayout.vue";

export const layouts: Record<string, DefineComponent<{}, {}, any>> = {
  dashboard: DashboardLayout,
  auth: AuthLayout,
  empty: EmptyLayout,
};

export type LayoutTypes = keyof typeof layouts;
