<template>
  <FormAsyncSelect
    v-model:search="criteria.name"
    :custom-width="customWidth"
    :model-value="modelValue"
    :pagination="searchAccommodationsRequest.data"
    index="id"
    label="acronym"
    @fetch="searchAccommodationsRequest.fetch()"
    @update:modelValue="$emit('update:modelValue', $event)"
    @fetch:item="showSelectedAccommodations"
  />
</template>

<script lang="ts" setup>
import useRequest from "@/contexts/shared/composables/useRequest";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import { emptyPagination } from "@/contexts/shared/models/Pagination";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { SearchAccommodationsCriteria } from "@/contexts/accommodations/models/SearchAccommodationsCriteria";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import FormAsyncSelect from "@/contexts/shared/ui/components/form/FormAsyncSelect.vue";

defineProps<{
  modelValue: string | null;
  customWidth?: boolean;
}>();

defineEmits<{
  "update:modelValue": [value: string | null];
}>();

const { searchAccommodations, showAccommodation } = useAccommodations();

const criteria = ref<SearchAccommodationsCriteria>({ name: "" });

const searchAccommodationsRequest = useRequest<Pagination<Accommodation>>({
  initialLoading: true,
  value: emptyPagination<Accommodation>(),
  promise: () => searchAccommodations(criteria.value),
});
const showSelectedAccommodations = (id: string, onSelected: (item: Accommodation) => void) => showAccommodation(id).then((response) => onSelected(response));
</script>
