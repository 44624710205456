import type CreateAccommodationRequest from "@/clients/accommodations/CreateAccommodation/request/CreateAccommodationRequest";
import type { UnsavedAccommodation } from "@/contexts/accommodations/models/Accommodation";

const toCreateAccommodationRequest = (accommodation: UnsavedAccommodation): CreateAccommodationRequest => {
  return {
    owner_id: accommodation.owner_id,
    cleaning_group_id: accommodation.cleaning_group_id,
    name: accommodation.name,
    acronym: accommodation.acronym,
    featured: accommodation.featured,
    type: accommodation.type,
    registration_code: accommodation.registration_code,
    land_registration_code: accommodation.land_registration_code,
    indoor_area: accommodation.indoor_area,
    total_area: accommodation.total_area,
    floors: accommodation.floors,
    notes: accommodation.notes,
    street: accommodation.street,
    city: accommodation.city,
    postal_code: accommodation.postal_code,
    country_code: accommodation.country_code,
    latitude: accommodation.latitude,
    longitude: accommodation.longitude,
  };
};

export default {
  toCreateAccommodationRequest,
};
