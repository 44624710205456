import type { components } from "@/libs/api/schema";
import type { BookingPayment } from "@/contexts/bookings/models/BookingPayment";
import { bookingFromResponse } from "@/contexts/bookings/utils/bookingFromResponse";

export const bookingPaymentFromResponse = (response: components["schemas"]["BookingPayment"]): BookingPayment => {
  return {
    id: response.id,
    status: response.status,
    method: response.method,
    amount: response.amount,
    attempted: response.attempted,
    attempt_count: response.attempt_count,
    booking_id: response.booking_id,
    booking: response.booking ? bookingFromResponse(response.booking) : undefined,
    due_date: response.due_date ? new Date(response.due_date) : null,
    paid_at: response.paid_at ? new Date(response.paid_at) : null,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
