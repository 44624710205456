import createAccommodation from "@/clients/accommodations/CreateAccommodation/CreateAccommodation";
import deleteAccommodation from "@/clients/accommodations/DeleteAccommodation/DeleteAccommodation";
import archiveAccommodation from "@/clients/accommodations/ArchiveAccommodation/ArchiveAccommodation";
import unarchiveAccommodation from "@/clients/accommodations/UnrchiveAccommodation/UnarchiveAccommodation";
import facilitiesAccommodation from "@/clients/accommodations/FacilitiesAccommodation/FacilitiesAccommodation";

export default {
  deleteAccommodation,
  createAccommodation,
  archiveAccommodation,
  unarchiveAccommodation,
  facilitiesAccommodation,
};
