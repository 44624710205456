import { createI18n, type LocaleMessageDictionary } from "vue-i18n";
import auth from "@/contexts/auth/i18n";
import shared from "@/contexts/shared/i18n";
import owners from "@/contexts/owners/i18n";
import profile from "@/contexts/profile/i18n";
import accounts from "@/contexts/accounts/i18n";
import bookings from "@/contexts/bookings/i18n";
import settings from "@/contexts/settings/i18n";
import dashboard from "@/contexts/dashboard/i18n";
import analytics from "@/contexts/analytics/i18n";
import bookingGuests from "@/contexts/booking-guests/i18n";
import bookingImports from "@/contexts/booking-imports/i18n";
import accommodations from "@/contexts/accommodations/i18n";
import cleaningGroups from "@/contexts/cleaning-groups/i18n";
import bookingCleanings from "@/contexts/booking-cleanings/i18n";
import ownerBankAccounts from "@/contexts/owner-bank-accounts/i18n";
import accommodationPhotos from "@/contexts/accommodation-photos/i18n";
import accommodationContracts from "@/contexts/accommodation-contracts/i18n";
import accommodationAttachments from "@/contexts/accommodation-attachments/i18n";

const messages: Record<any, LocaleMessageDictionary<any>> = {
  en: {},
  es: {},
};

const registerTranslations = (context: string, translations: Record<any, LocaleMessageDictionary<any>>) => {
  Object.keys(messages).forEach((locale) => (messages[locale][context] = translations[locale]));
};

registerTranslations("auth", auth);
registerTranslations("shared", shared);
registerTranslations("owners", owners);
registerTranslations("profile", profile);
registerTranslations("accounts", accounts);
registerTranslations("bookings", bookings);
registerTranslations("settings", settings);
registerTranslations("dashboard", dashboard);
registerTranslations("analytics", analytics);
registerTranslations("booking_guests", bookingGuests);
registerTranslations("booking_imports", bookingImports);
registerTranslations("accommodations", accommodations);
registerTranslations("cleaning_groups", cleaningGroups);
registerTranslations("booking_cleanings", bookingCleanings);
registerTranslations("owner_bank_accounts", ownerBankAccounts);
registerTranslations("accommodation_photos", accommodationPhotos);
registerTranslations("accommodation_contracts", accommodationContracts);
registerTranslations("accommodation_attachments", accommodationAttachments);

const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  fallbackWarn: false,
  globalInjection: true,
  messages,
});

export default i18n;
