import axios from "@/libs/axios";
import type GetAccountIntegrationsResponse from "@/clients/account-integrations/GetAccountIntegrations/response/GetAccountIntegrationsResponse";

export default async function getAccountIntegrations(): Promise<GetAccountIntegrationsResponse> {
  try {
    const { data } = await axios.get<GetAccountIntegrationsResponse>(`/settings/integrations`);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
