<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("owners.update.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <OwnersDetailsForm
      v-model="form"
      :errors="updateOwnerRequest.errors"
      type="update"
      @submit="submit"
    />
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="updateOwnerRequest.isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import type { Owner, UnsavedOwner } from "@/contexts/owners/models/Owner";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import OwnersDetailsForm from "@/contexts/owners/ui/components/OwnersDetailsForm.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import useNotification from "@/contexts/shared/composables/useNotification";
import useOwners from "@/contexts/owners/composables/useOwners";

const props = defineProps<{
  owner: Owner;
}>();
const emit = defineEmits<{
  close: [];
  updated: [];
}>();
const { updateOwner } = useOwners();
const { successNotification, errorNotification } = useNotification();

const form = ref<UnsavedOwner>({ ...props.owner });

const updateOwnerRequest = useRequest({
  promise: () => updateOwner(props.owner.id, form.value),
  onSuccess: () => {
    successNotification("owners.update.success");
    emit("updated");
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("owners.update.error");
    }
  },
});

const submit = async () => {
  await updateOwnerRequest.fetch();
};
</script>
