<template>
  <DashboardHeader>
    <DashboardHeaderTitle>{{ $t("bookings.title") }}</DashboardHeaderTitle>
    <DashboardHeaderActions>
      <RouterLinkWithIcon
        :to="{ name: 'bookings.imports' }"
        class="btn-white"
        icon="file-arrow-up-solid"
      >
        {{ $t("bookings.imports.title") }}
      </RouterLinkWithIcon>
      <RouterLinkWithIcon
        :to="{ name: 'bookings.create' }"
        class="btn-primary"
        icon="plus-solid"
      >
        {{ $t("bookings.create.title") }}
      </RouterLinkWithIcon>
    </DashboardHeaderActions>
  </DashboardHeader>
  <div class="space-y-3">
    <FilterGroup
      :is-empty="criteriaEmpty"
      @clear="clearCriteria"
    >
      <FilterItem
        v-model="criteria.code"
        label="bookings.filters.code"
        title="bookings.filters_by.code"
      >
        <FilterText type="contains" />
      </FilterItem>
      <FilterItem
        v-model="criteria.status"
        label="bookings.filters.status"
        title="bookings.filters_by.status"
      >
        <FilterOptions :options="bookingStatusOptions" />
      </FilterItem>
      <FilterItem
        v-model="criteria.provider"
        label="bookings.filters.provider"
        title="bookings.filters_by.provider"
      >
        <FilterOptions :options="bookingProviderOptions" />
      </FilterItem>
      <FilterItem
        v-model="criteria.check_in"
        label="bookings.filters.check_in"
        title="bookings.filters_by.check_in"
      >
        <FilterDateRange />
      </FilterItem>
      <FilterItem
        v-model="criteria.check_out"
        label="bookings.filters.check_out"
        title="bookings.filters_by.check_out"
      >
        <FilterDateRange />
      </FilterItem>
      <FilterItem
        v-model="criteria.accommodation_id"
        label="bookings.filters.accommodation"
        title="bookings.filters_by.accommodation"
      >
        <AccommodationsFilter />
      </FilterItem>
    </FilterGroup>
    <template v-if="searchBookingsRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchBookingsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchBookingsRequest.data.data.length === 0">
      <ListEmptyState
        description="bookings.empty.description"
        icon="calendar-days-solid"
        title="bookings.empty.title"
      >
        <div class="space-x-5">
          <RouterLinkWithIcon
            :to="{ name: 'bookings.imports' }"
            class="btn-white"
            icon="file-arrow-up-solid"
          >
            {{ $t("bookings.imports.title") }}
          </RouterLinkWithIcon>
          <RouterLinkWithIcon
            :to="{ name: 'bookings.create' }"
            class="btn-primary"
            icon="plus-solid"
          >
            {{ $t("bookings.create.title") }}
          </RouterLinkWithIcon>
        </div>
      </ListEmptyState>
    </template>
    <template v-else>
      <ListTableGroup>
        <ListTableActions :selected="selected">
          <ButtonWithIcon
            class="btn-primary"
            icon="check-solid"
            :disabled="!canConfirmSelectedBookings"
            @click="bookingConfirmModal?.open()"
          >
            {{ $t("bookings.confirm.selected", selected?.length ?? 0) }}
          </ButtonWithIcon>
          <ButtonWithIcon
            class="btn-primary"
            icon="print-solid"
            @click="bookingCheckInPreviewModal?.open()"
          >
            {{ $t("bookings.check_in.selected", selected?.length ?? 0) }}
          </ButtonWithIcon>
        </ListTableActions>
        <BookingsTable
          v-model:selected="selected"
          :bookings="searchBookingsRequest.data.data"
          @fetch-list="searchBookingsRequest.fetch()"
        />
      </ListTableGroup>
      <CursorPagination
        :meta="searchBookingsRequest.data.meta"
        :total="searchBookingsRequest.data.total"
        @change-page="changePage"
      />
    </template>
  </div>
  <RouterView @fetch-list="changePage" />
  <ModalDialog
    ref="bookingConfirmModal"
    v-slot="{ close }"
  >
    <BookingsConfirmModal
      :id="selected"
      @close="close"
      @confirmed="searchBookingsRequest.fetch()"
    />
  </ModalDialog>
  <ModalDialog
    ref="bookingCheckInPreviewModal"
    size="3xl"
    @close="pdfBookingCheckInRequest.reset()"
    @opened="pdfBookingCheckInRequest.fetch()"
  >
    <PreviewPdfModal
      :file="pdfBookingCheckInRequest.data"
      :has-errors="pdfBookingCheckInRequest.hasErrors"
      :is-loading="pdfBookingCheckInRequest.isLoading"
      :name="`check_ins.pdf`"
      title="bookings.check_in.title"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import { useEventBus } from "@vueuse/core";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import RouterLinkWithIcon from "@/contexts/shared/ui/components/button/RouterLinkWithIcon.vue";
import FilterItem from "@/contexts/shared/ui/components/filter/FilterItem.vue";
import FilterGroup from "@/contexts/shared/ui/components/filter/FilterGroup.vue";
import FilterText from "@/contexts/shared/ui/components/filter/FilterText.vue";
import type { SearchBookingsCriteria } from "@/contexts/bookings/models/SearchBookingsCriteria";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import { emptyPagination } from "@/contexts/shared/models/Pagination";
import useBookings from "@/contexts/bookings/composables/useBookings";
import type { Booking } from "@/contexts/bookings/models/Booking";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import FilterDateRange from "@/contexts/shared/ui/components/filter/FilterDateRange.vue";
import BookingsTable from "@/contexts/bookings/ui/components/BookingsTable.vue";
import AccommodationsFilter from "@/contexts/accommodations/ui/components/AccommodationsFilter.vue";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useSelect from "@/contexts/shared/composables/useSelect";
import ListTableGroup from "@/contexts/shared/ui/components/list/ListTableGroup.vue";
import ListTableActions from "@/contexts/shared/ui/components/list/ListTableActions.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import PreviewPdfModal from "@/contexts/shared/ui/modals/PreviewPdfModal.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import BookingsConfirmModal from "@/contexts/bookings/ui/modals/BookingsConfirmModal.vue";
import FilterOptions from "@/contexts/shared/ui/components/filter/FilterOptions.vue";

const { t } = useI18n();
const accountBus = useEventBus<string>("account");
const { selected } = useSelect();
const { errorNotification } = useNotification();
const { searchBookings, pdfBookingCheckIn, bookingStatusOptions, bookingProviderOptions } = useBookings();

accountBus.on(() => clearCriteria());
useHead({ title: () => `${t("bookings.title")} - Hussbook` });

const { criteria, cursor, changePage, criteriaEmpty, clearCriteria } = useCriteria<SearchBookingsCriteria>({}, () => searchBookingsRequest.fetch());

const bookingConfirmModal = ref<InstanceType<typeof ModalDialog>>();
const bookingCheckInPreviewModal = ref<InstanceType<typeof ModalDialog>>();
const canConfirmSelectedBookings = computed(() => selected.value.every((id) => searchBookingsRequest.data.data.some((booking) => booking.id === id && booking.status === "draft")));

onMounted(() => {
  searchBookingsRequest.fetch();
});

const searchBookingsRequest: UseRequest<Pagination<Booking>> = useRequest<Pagination<Booking>>({
  initialLoading: true,
  value: emptyPagination<Booking>(),
  promise: () => searchBookings(criteria.value, cursor.value),
  onFetch: () => (selected.value = []),
  onSuccess: (response) => {
    if (cursor.value && response.data.length === 0) {
      changePage();
      return;
    }
  },
});
const pdfBookingCheckInRequest = useRequest({
  initialLoading: true,
  promise: () => pdfBookingCheckIn(selected.value),
  onFailure: () => errorNotification("bookings.check_in.error"),
});
</script>
