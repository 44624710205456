<template>
  <component :is="icons[method]" />
</template>

<script lang="ts" setup>
import type { BookingPaymentMethod } from "@/contexts/bookings/models/BookingPayment";
import type { FunctionalComponent } from "vue";

defineProps<{
  method: BookingPaymentMethod;
}>();

const icons: Record<BookingPaymentMethod, FunctionalComponent> = {
  stripe: defineAsyncComponent(() => import("@/assets/svg/providers/stripe.svg?component")),
  adyen: defineAsyncComponent(() => import("@/assets/svg/providers/adyen.svg?component")),
  paypal: defineAsyncComponent(() => import("@/assets/svg/providers/paypal.svg?component")),
  bank_transfer: defineAsyncComponent(() => import("@/assets/svg/font-awesome/building-columns-solid.svg?component")),
  cash: defineAsyncComponent(() => import("@/assets/svg/font-awesome/money-bill-solid.svg?component")),
};

export type ProviderIconTypes = keyof typeof icons;
</script>
