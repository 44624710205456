<template>
  <div ref="dropzone">
    <div
      v-show="filesToUpload.length === 0"
      :class="{ 'bg-gradient': isOverDropZone }"
      class="group relative rounded-md border border-dashed border-gray-900/25"
    >
      <div class="flex w-full justify-center rounded-md text-center">
        <div class="m-auto mx-6 my-10 flex flex-col items-center py-0.5">
          <FontAwesomeIcon
            class="mx-auto h-12 w-12 fill-primary"
            icon="file-circle-plus-solid"
          />
          <div class="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              :for="id"
              class="relative cursor-pointer font-semibold text-primary focus-within:outline-none hover:text-primary/80"
            >
              <span>{{ $t("shared.form.images.upload") }}</span>
              <input
                :id="id"
                ref="input"
                accept="image/*"
                class="sr-only"
                multiple
                name="file-upload"
                tabindex="-1"
                type="file"
                @input="changeFile"
              />
            </label>
            <p class="pl-1">
              {{ $t("shared.form.images.drag_drop") }}
            </p>
          </div>
          <p class="max-w-sm text-xs leading-5 text-gray-600">
            {{ $t("shared.form.images.helper", { min_dimensions: "1000 x 680", max_dimensions: "14000 x 10000", size: "20" }) }}
          </p>
        </div>
      </div>
    </div>
    <template v-if="filesToUpload.length > 0">
      <div class="grid grid-cols-2 gap-3 md:grid-cols-3">
        <template v-for="fileToUpload in filesToUpload">
          <div
            :style="{ backgroundImage: `url(${fileToUpload.preview})` }"
            class="relative h-40 rounded bg-cover bg-center bg-no-repeat"
          >
            <div
              :class="{ 'to-red-100/60': fileToUpload.status === 'error' }"
              class="absolute flex size-full flex-col justify-between rounded bg-gradient-to-b from-gray-900/60 from-5% via-10% px-2.5 py-2 text-sm"
            >
              <div class="flex gap-3">
                <div class="grow truncate">
                  <div class="truncate text-gray-100/90">{{ fileToUpload.name }}</div>
                  <div class="mt-px text-xs text-gray-200/70">{{ bytesToHuman(fileToUpload.size) }}</div>
                </div>
                <div class="flex-none p-0.5">
                  <FontAwesomeIcon
                    v-if="fileToUpload.status === 'waiting' || fileToUpload.status === 'uploading'"
                    class="size-5 animate-spin fill-gray-100"
                    icon="spinner-third-duotone"
                  />
                  <FontAwesomeIcon
                    v-else-if="fileToUpload.status === 'uploaded'"
                    class="size-5 fill-green-500"
                    icon="circle-check-regular"
                  />
                  <template v-else-if="fileToUpload.status === 'error'">
                    <FontAwesomeIcon
                      class="size-5 fill-red-500"
                      icon="circle-xmark-regular"
                    />
                  </template>
                </div>
              </div>
              <template v-if="fileToUpload.status === 'error'">
                <div class="text-xs leading-3 text-red-600">
                  {{ fileToUpload.error }}
                </div>
              </template>
            </div>
          </div>
        </template>
        <div class="col-span-2 flex items-center justify-center md:col-span-3">
          <ButtonWithIcon
            class="btn-primary"
            icon="plus-solid"
            @click="input?.click()"
          >
            {{ $t("shared.form.images.more") }}
          </ButtonWithIcon>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import useFormatter from "@/contexts/shared/composables/useFormatter";
import { useDropZone } from "@vueuse/core";
import { AxiosError } from "axios";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import type { AccommodationPhoto } from "@/contexts/accommodation-photos/models/AccommodationPhoto";

const props = defineProps<{
  id: string;
  promise: (file: File) => Promise<unknown>;
}>();

const emit = defineEmits<{
  uploaded: [value: AccommodationPhoto];
}>();

const { bytesToHuman } = useFormatter();

const uploading = ref<boolean>(false);
const filesToUpload = ref<{ file: File; preview: string; name: string; size: number; status: "waiting" | "uploading" | "uploaded" | "error"; error?: string }[]>([]);
const dropzone = ref<HTMLElement>();
const input = ref<HTMLInputElement>();

const { isOverDropZone } = useDropZone(dropzone, (files: File[] | null) => {
  files?.forEach((item) => filesToUpload.value.push({ file: item, preview: URL.createObjectURL(item), name: item.name, size: item.size, status: "waiting" }) && uploadFiles());
});

const changeFile = (event: Event) => {
  if ((<FileList>(<HTMLInputElement>event.target).files).length) {
    Array.from(<FileList>(<HTMLInputElement>event.target).files)?.forEach((item) => filesToUpload.value.push({ file: item, preview: URL.createObjectURL(item), name: item.name, size: item.size, status: "waiting" }) && uploadFiles());
  }
};

const uploadFiles = async () => {
  const filesUploading = filesToUpload.value.filter((item) => "uploading" === item.status);
  const pendingFilesToUpload = filesToUpload.value.filter((item) => "waiting" === item.status);
  if (pendingFilesToUpload.length > 0 && filesUploading.length < 5) {
    const file = pendingFilesToUpload[0];
    try {
      uploading.value = true;
      file.status = "uploading";
      const response = (await props.promise(file.file)) as AccommodationPhoto;
      uploading.value = false;
      file.status = "uploaded";
      emit("uploaded", response);
    } catch (e) {
      uploading.value = false;
      file.status = "error";
      file.error = e instanceof AxiosError ? e.response?.data?.errors?.file?.[0] ?? e.response?.data?.message ?? e : e;
    } finally {
      uploadFiles();
    }
  }
};
</script>
