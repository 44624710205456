<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t(title) }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent class="flex h-[78vh]">
    <template v-if="isLoading">
      <LoadingSection class="m-auto" />
    </template>
    <template v-else-if="hasErrors">
      <ListErrorState class="m-auto" />
    </template>
    <div
      v-show="!isLoading && !hasErrors"
      class="h-full w-full"
    >
      <div
        v-if="viewerEnabled"
        class="relative h-full w-full border border-gray-200"
      >
        <div class="absolute m-4 space-x-2.5 rounded-lg">
          <ButtonWithIcon
            class="btn-primary"
            icon="arrow-down-to-line-solid"
            @click="download()"
          >
            {{ $t("shared.preview.download") }}
          </ButtonWithIcon>
          <ButtonWithIcon
            class="btn-primary"
            icon="print-solid"
            @click="print()"
          >
            {{ $t("shared.preview.print") }}
          </ButtonWithIcon>
        </div>
        <iframe
          ref="iframe"
          class="h-full w-full"
          type="application/pdf"
        />
      </div>
      <div
        v-else
        class="flex h-full w-full border border-gray-200"
      >
        <div class="m-auto text-center">
          <FileIcon
            class="mx-auto h-14 w-14"
            icon="pdf"
          />
          <div class="my-4 w-full">
            <div class="truncate text-sm font-medium leading-6 text-gray-700">
              {{ name }}
            </div>
          </div>
          <ButtonWithIcon
            class="btn-white"
            icon="arrow-down-to-line-solid"
            @click="download()"
          >
            {{ $t("shared.preview.download") }}
          </ButtonWithIcon>
        </div>
      </div>
    </div>
  </ModalDialogContent>
</template>

<script lang="ts" setup>
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import FileIcon from "@/contexts/shared/ui/components/icon/FileIcon.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import useFormatter from "@/contexts/shared/composables/useFormatter";

const props = defineProps<{
  file?: string;
  title: string;
  name: string;
  isLoading: boolean;
  hasErrors: boolean;
}>();

const { b64toBlob } = useFormatter();

const iframe = ref<HTMLIFrameElement>();

watch(
  () => props.file,
  (value) => (value && iframe.value ? (iframe.value.src = `${URL.createObjectURL(b64toBlob(value, "application/pdf"))}#toolbar=0`) : void 0),
);

const viewerEnabled = computed(() => navigator.pdfViewerEnabled && !(!/Chrome|Android|CriOS|FxiOS|EdgiOS/i.test(navigator.userAgent) && /Mac|iPhone|iPod|iPad/i.test(navigator.userAgent) && /Safari/i.test(navigator.userAgent)));
const download = () => {
  if (props.file) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(b64toBlob(props.file, "application/pdf"));
    link.download = props.name;
    link.target = "_blank";
    link.click();
    link.remove();
  }
};
const print = () => {
  iframe.value?.contentWindow?.print();
};
</script>
