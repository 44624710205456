import type CreateBookingRequest from "@/clients/bookings/CreateBooking/request/CreateBookingRequest";
import type CreateBookingResponse from "@/clients/bookings/CreateBooking/response/CreateBookingResponse";
import axios from "@/libs/axios";

export default async function createBooking(request: CreateBookingRequest): Promise<CreateBookingResponse> {
  try {
    const { data } = await axios.post<CreateBookingResponse>("/bookings", request);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
