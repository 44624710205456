<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("cleaning_groups.update.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <CleaningGroupsForm
      v-model="form"
      :errors="updateCleaningGroupRequest.errors"
      @submit="submit"
    />
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="updateCleaningGroupRequest.isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import CleaningGroupsForm from "@/contexts/cleaning-groups/ui/components/CleaningGroupsForm.vue";
import type { CleaningGroup, UnsavedCleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import useRequest from "@/contexts/shared/composables/useRequest";
import useCleaningGroups from "@/contexts/cleaning-groups/composables/useCleaningGroups";
import useNotification from "@/contexts/shared/composables/useNotification";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";

const props = defineProps<{
  cleaningGroup: CleaningGroup;
}>();

const emit = defineEmits<{
  close: [];
  updated: [];
}>();

const { updateCleaningGroup } = useCleaningGroups();
const { successNotification, errorNotification } = useNotification();

const form = ref<UnsavedCleaningGroup>({ ...props.cleaningGroup });

const updateCleaningGroupRequest = useRequest({
  promise: () => updateCleaningGroup(props.cleaningGroup.id, form.value),
  onSuccess: () => {
    successNotification("cleaning_groups.update.success");
    emit("updated");
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("cleaning_groups.update.error");
    }
  },
});

const submit = async () => {
  await updateCleaningGroupRequest.fetch();
};
</script>
