import type ShowAccommodationAttachmentResponse from "@/clients/accommodation-attachments/ShowAccommodationAttachment/response/ShowAccommodationAttachmentResponse";
import type { AccommodationAttachmentWithUrl } from "@/contexts/accommodation-attachments/models/AccommodationAttachment";

const fromShowAccommodationAttachmentResponse = (response: ShowAccommodationAttachmentResponse): AccommodationAttachmentWithUrl => {
  return {
    id: response.data.id,
    accommodation_id: response.data.accommodation_id,
    name: response.data.name,
    extension: response.data.extension,
    size: response.data.size,
    url: response.data.url,
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
  };
};

export default {
  fromShowAccommodationAttachmentResponse,
};
