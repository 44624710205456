<template>
  <Badge :class="classes">
    {{ $t(`accommodation_contracts.status.${status}`) }}
  </Badge>
</template>

<script lang="ts" setup>
import type { AccommodationContractStatus } from "@/contexts/accommodations/models/AccommodationContract";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";

const props = defineProps<{
  status: AccommodationContractStatus;
}>();

const classes = computed(() => {
  switch (props.status) {
    case "draft":
      return "badge-gray";
    case "approved":
      return "badge-primary";
    case "confirmed":
      return "badge-green";
  }
});
</script>
