import { isArray } from "lodash-es";
import type { paths } from "@/libs/api/schema";

const toPdfBookingCleaningDetailsRequest = (id: string | string[]): paths["/booking-cleanings/details/pdf"]["get"]["parameters"]["query"] => {
  return {
    "id[]": isArray(id) ? id : [id],
  };
};

const fromPdfBookingCleaningDetailsResponse = (response: paths["/booking-cleanings/details/pdf"]["get"]["responses"]["200"]["content"]["application/json"] | undefined): string => {
  if (!response) {
    throw new Error("Invalid response");
  }

  return response.data;
};

export default {
  toPdfBookingCleaningDetailsRequest,
  fromPdfBookingCleaningDetailsResponse,
};
