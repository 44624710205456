import login from "@/clients/auth/Login/Login";
import csrf from "@/clients/auth/Csrf/Csrf";
import user from "@/clients/auth/User/User";
import logout from "@/clients/auth/Logout/Logout";

export default {
  csrf,
  user,
  login,
  logout,
};
