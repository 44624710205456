import axios from "@/libs/axios";

export default async function deleteAccommodationAttachment(accommodationId: string, id: string): Promise<void> {
  try {
    await axios.delete(`/accommodations/${accommodationId}/attachments/${id}`);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
