import type { BookingGuest } from "@/contexts/booking-guests/models/BookingGuest";
import type ShowBookingGuestResponse from "@/clients/booking-guests/ShowBookingGuests/response/ShowBookingGuestResponse";

const fromShowBookingGuestResponse = (response: ShowBookingGuestResponse): BookingGuest => {
  return {
    id: response.data.id,
    name: response.data.name,
    emails: response.data.emails,
    phones: response.data.phones,
    language: response.data.language,
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
  };
};

export default {
  fromShowBookingGuestResponse,
};
