import type { SearchAccommodationsCriteria } from "@/contexts/accommodations/models/SearchAccommodationsCriteria";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { paths } from "@/libs/api/schema";
import { paginationMetaFromResponse } from "@/contexts/shared/utils/paginationMetaFromResponse";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";

const toSearchAccommodationsRequest = (criteria: SearchAccommodationsCriteria, cursor?: string): paths["/accommodations"]["get"]["parameters"]["query"] => {
  return {
    name: criteria.name ?? undefined,
    address: criteria.address ?? undefined,
    archived: criteria.archived ?? undefined,
    featured: criteria.featured ?? undefined,
    cleaning_group_id: criteria.cleaning_group_id ?? undefined,
    order_by: criteria.order_by ?? undefined,
    sort: criteria.sort ?? undefined,
    per_page: criteria.per_page ?? undefined,
    cursor: cursor ?? undefined,
  };
};

const fromSearchAccommodationsResponse = (response: paths["/accommodations"]["get"]["responses"][200]["content"]["application/json"] | undefined, headers: Headers): Pagination<Accommodation> => {
  return {
    data: response?.data?.map((item) => accommodationFromResponse(item)) ?? [],
    meta: paginationMetaFromResponse(response?.meta),
    total: Number(headers.get("x-total-count") ?? 0),
  };
};

export default {
  toSearchAccommodationsRequest,
  fromSearchAccommodationsResponse,
};
