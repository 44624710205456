<template>
  <DropdownMenu>
    <template #button>
      <slot />
    </template>
    <DropdownMenuItemButton @click="bookingCheckInPreviewModal?.open()">
      {{ $t("bookings.actions.check_in") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="!['draft', 'requested'].includes(booking.status)"
      @click="bookingConfirmModal?.open()"
    >
      {{ $t("bookings.actions.confirm") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="!['draft', 'requested'].includes(booking.status)"
      @click="bookingCancelModal?.open()"
    >
      {{ $t("bookings.actions.cancel") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="!['draft'].includes(booking.status)"
      class="text-red-600 hover:text-red-700"
      @click="bookingDeleteModal?.open()"
    >
      {{ $t("bookings.actions.delete") }}
    </DropdownMenuItemButton>
  </DropdownMenu>
  <ModalDialog
    ref="bookingCheckInPreviewModal"
    size="3xl"
    @opened="pdfBookingCheckInRequest.fetch()"
    @close="pdfBookingCheckInRequest.reset()"
  >
    <PreviewPdfModal
      :file="pdfBookingCheckInRequest.data"
      :has-errors="pdfBookingCheckInRequest.hasErrors"
      :is-loading="pdfBookingCheckInRequest.isLoading"
      :name="`check_in_${booking.code}.pdf`"
      title="bookings.check_in.title"
    />
  </ModalDialog>
  <ModalDialog
    ref="bookingConfirmModal"
    v-slot="{ close }"
  >
    <BookingsConfirmModal
      :id="booking.id"
      @close="close"
      @confirmed="$emit('confirmed')"
    />
  </ModalDialog>
  <ModalDialog
    ref="bookingCancelModal"
    v-slot="{ close }"
  >
    <BookingsCancelModal
      :id="booking.id"
      @close="close"
      @cancelled="$emit('cancelled')"
    />
  </ModalDialog>
  <ModalDialog
    ref="bookingDeleteModal"
    v-slot="{ close }"
  >
    <BookingsDeleteModal
      :id="booking.id"
      @close="close"
      @deleted="$emit('deleted')"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import DropdownMenu from "@/contexts/shared/ui/components/dropdown/DropdownMenu.vue";
import DropdownMenuItemButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuItemButton.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import type { Booking } from "@/contexts/bookings/models/Booking";
import PreviewPdfModal from "@/contexts/shared/ui/modals/PreviewPdfModal.vue";
import useBookings from "@/contexts/bookings/composables/useBookings";
import useRequest from "@/contexts/shared/composables/useRequest";
import BookingsConfirmModal from "@/contexts/bookings/ui/modals/BookingsConfirmModal.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import BookingsCancelModal from "@/contexts/bookings/ui/modals/BookingsCancelModal.vue";
import BookingsDeleteModal from "@/contexts/bookings/ui/modals/BookingsDeleteModal.vue";

const props = defineProps<{
  booking: Booking;
}>();

defineEmits<{
  confirmed: [];
  cancelled: [];
  deleted: [];
}>();

const { errorNotification } = useNotification();
const { pdfBookingCheckIn } = useBookings();

const bookingConfirmModal = ref<InstanceType<typeof ModalDialog>>();
const bookingCancelModal = ref<InstanceType<typeof ModalDialog>>();
const bookingDeleteModal = ref<InstanceType<typeof ModalDialog>>();
const bookingCheckInPreviewModal = ref<InstanceType<typeof ModalDialog>>();

const pdfBookingCheckInRequest = useRequest({
  initialLoading: true,
  promise: () => pdfBookingCheckIn(props.booking.id),
  onFailure: () => errorNotification("bookings.check_in.error"),
});
</script>
