<template>
  <Badge :class="classes">
    {{ featured ? $t("shared.featured") : $t("shared.not_featured") }}
  </Badge>
</template>

<script setup lang="ts">
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";

const props = defineProps<{
  featured: boolean | null | undefined;
}>();

const classes = computed(() => {
  switch (props.featured) {
    case true:
      return "badge-green";
    default:
      return "badge-gray";
  }
});
</script>
