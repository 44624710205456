<template>
  <ConfirmDeleteModal
    :is-loading="deleteAccommodationContractRequest.isLoading"
    description="accommodation_contracts.delete.description"
    title="accommodation_contracts.delete.title"
    @close="$emit('close')"
    @confirm="deleteAccommodationContractRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import ConfirmDeleteModal from "@/contexts/shared/ui/modals/ConfirmDeleteModal.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import type { AccommodationContract } from "@/contexts/accommodations/models/AccommodationContract";
import useAccommodationContracts from "@/contexts/accommodation-contracts/composables/useAccommodationContracts";

const props = defineProps<{
  contract: AccommodationContract;
}>();

const emit = defineEmits<{
  close: [];
  deleted: [];
}>();

const { deleteAccommodationContract } = useAccommodationContracts();
const { successNotification, errorNotification } = useNotification();

const deleteAccommodationContractRequest = useRequest({
  promise: () => deleteAccommodationContract(props.contract.accommodation_id, props.contract.id),
  onSuccess: () => {
    successNotification("accommodation_contracts.delete.success");
    emit("deleted");
    emit("close");
  },
  onFailure: () => {
    errorNotification("accommodation_contracts.delete.error");
  },
});
</script>
