import type BookingCleaningsByTypeResponse from "@/clients/analytics/BookingCleaningsByType/response/BookingCleaningsByTypeResponse";
import type { BookingCleaningType } from "@/contexts/booking-cleanings/models/BookingCleaningType";

const fromBookingCleaningsByTypeResponse = (response: BookingCleaningsByTypeResponse): { data: Partial<Record<BookingCleaningType, { total: number; avg: number }>>; total: number } => {
  return { data: response.data, total: response.total };
};

export default {
  fromBookingCleaningsByTypeResponse,
};
