import type { UnsavedAccommodationBathroom } from "@/contexts/accommodations/models/AccommodationRooms";
import type { paths } from "@/libs/api/schema";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";

const toUpdateAccommodationBathroomsRequest = (bathrooms: UnsavedAccommodationBathroom[]): paths["/accommodations/{accommodation}/bathrooms"]["put"]["requestBody"]["content"]["application/json"] => {
  return {
    bathrooms: bathrooms.map((item) => ({
      id: item.id ?? null,
      type: item.type,
      floor: item.floor,
      toilet: item.toilet,
      shower: item.shower,
      bath: item.bath,
    })),
  };
};

const fromUpdateAccommodationBathroomsResponse = (response: paths["/accommodations/{accommodation}/bathrooms"]["put"]["responses"][200]["content"]["application/json"] | undefined): Accommodation => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationFromResponse(response.data);
};

export default {
  toUpdateAccommodationBathroomsRequest,
  fromUpdateAccommodationBathroomsResponse,
};
