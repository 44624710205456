<template>
  <div class="mb-6 w-full rounded bg-white p-8 shadow-xl md:p-10">
    <div class="mb-6 text-xl font-semibold text-gray-900">
      {{ $t("auth.reset.title") }}
    </div>
    <div class="mb-8 text-sm text-gray-700">
      {{ $t("auth.reset.description") }}
    </div>
    <form
      class="space-y-6"
      @submit.prevent="submit"
    >
      <FormGroup :errors="forgotPasswordRequest.errors.email">
        <FormLabel>{{ $t("auth.reset.form.email") }}</FormLabel>
        <FormInput>
          <FormText
            v-model="form.email"
            :padding="false"
            name="email"
          />
        </FormInput>
      </FormGroup>
      <FormSubmit
        id="submit"
        :is-loading="forgotPasswordRequest.isLoading"
      >
        {{ $t("auth.reset.form.submit") }}
      </FormSubmit>
    </form>
    <div class="mt-6 flex w-full justify-center">
      <RouterLink
        :to="{ name: 'login' }"
        class="text-sm text-primary hover:text-gray-800"
      >
        {{ $t("auth.reset.to_login") }}
      </RouterLink>
    </div>
  </div>
  <div class="px-4">
    <p class="text-sm text-gray-700">
      {{ t("auth.register.no_account") }}
      <RouterLink
        :to="{ name: 'register' }"
        class="text-primary hover:text-gray-800"
      >
        {{ $t("auth.register.sign_up") }}
      </RouterLink>
    </p>
  </div>
</template>

<script lang="ts" setup>
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import FormSubmit from "@/contexts/shared/ui/components/form/FormSubmit.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import useAuth from "@/contexts/auth/composables/useAuth";

const { t } = useI18n();
const { forgotPassword } = useAuth();
useHead({ title: () => `${t("auth.reset.title")} - Hussbook` });

const form = ref({ email: "" });

const forgotPasswordRequest = useRequest<void, { email: string }>({
  promise: () => forgotPassword(form.value.email),
  onSuccess: () => {
    // redirect to login
  },
  onFailure: () => {
    // error message
  },
});

const submit = async () => {
  //
};
</script>
