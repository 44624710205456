import type { AccountIntegration, AccountIntegrationProvider, UnsavedAccountIntegration } from "@/contexts/accounts/models/AccountIntegration";
import type UpdateAccountIntegrationRequest from "@/clients/account-integrations/UpdateAccountIntegration/request/UpdateAccountIntegrationRequest";
import type UpdateAccountIntegrationResponse from "@/clients/account-integrations/UpdateAccountIntegration/response/UpdateAccountIntegrationResponse";

const toUpdateAccountIntegrationRequest = (integration: UnsavedAccountIntegration): UpdateAccountIntegrationRequest => {
  return {
    credentials: integration.credentials,
  };
};
const fromUpdateAccountIntegrationResponse = (response: UpdateAccountIntegrationResponse): AccountIntegration => {
  return {
    id: response.data.id,
    provider: response.data.provider as AccountIntegrationProvider,
    credentials: response.data.credentials,
    enabled: response.data.enabled,
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
  };
};

export default {
  toUpdateAccountIntegrationRequest,
  fromUpdateAccountIntegrationResponse,
};
