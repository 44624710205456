import type { components } from "@/libs/api/schema";
import type { BookingGuest } from "@/contexts/booking-guests/models/BookingGuest";

export const bookingGuestFromResponse = (response: components["schemas"]["BookingGuest"]): BookingGuest => {
  return {
    id: response.id,
    name: response.name,
    emails: response.emails,
    phones: response.phones,
    language: response.language,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
