import axios from "@/libs/axios";
import type CreateAccountRequest from "@/clients/accounts/CreateAccount/request/CreateAccountRequest";
import type CreateAccountResponse from "@/clients/accounts/CreateAccount/response/CreateAccountResponse";

export default async function createAccount(request: CreateAccountRequest): Promise<CreateAccountResponse> {
  try {
    const { data } = await axios.post<CreateAccountResponse>("/accounts", request);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
