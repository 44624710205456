import type { AccommodationPhoto } from "@/contexts/accommodation-photos/models/AccommodationPhoto";
import type { components } from "@/libs/api/schema";

export const accommodationPhotoFromResponse = (response: components["schemas"]["AccommodationPhoto"]): AccommodationPhoto => {
  return {
    id: response.id,
    accommodation_id: response.accommodation_id,
    name: response.name,
    url: response.url,
    extension: response.extension,
    size: response.size,
    order: response.order,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
