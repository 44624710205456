import type { SearchBookingGuestsCriteria } from "@/contexts/booking-guests/models/SearchBookingGuestsCriteria";
import type SearchBookingGuestsRequest from "@/clients/booking-guests/SearchBookingGuests/request/SearchBookingGuestsRequest";
import type SearchBookingGuestsResponse from "@/clients/booking-guests/SearchBookingGuests/response/SearchBookingGuestsResponse";
import type { BookingGuest } from "@/contexts/booking-guests/models/BookingGuest";
import type { Pagination } from "@/contexts/shared/models/Pagination";

const toSearchBookingGuestsRequest = (criteria: SearchBookingGuestsCriteria): SearchBookingGuestsRequest => {
  return {
    search: criteria.search ?? undefined,
    name: criteria.name ?? undefined,
    email: criteria.email ?? undefined,
    phone: criteria.phone ?? undefined,
    language: criteria.language ?? undefined,
    order_by: criteria.order_by ?? undefined,
    sort: criteria.sort ?? undefined,
    per_page: criteria.per_page ?? undefined,
  };
};

const fromSearchBookingGuestsResponse = (response: SearchBookingGuestsResponse): Pagination<BookingGuest> => {
  return {
    data: response.data.map((item) => ({
      id: item.id,
      name: item.name,
      emails: item.emails,
      phones: item.phones,
      language: item.language,
      created_at: new Date(item.created_at),
      updated_at: new Date(item.updated_at),
    })),
    meta: {
      next_cursor: response.meta.next_cursor,
      path: response.meta.path,
      per_page: response.meta.per_page,
      prev_cursor: response.meta.prev_cursor,
    },
    total: response.total,
  };
};

export default {
  toSearchBookingGuestsRequest,
  fromSearchBookingGuestsResponse,
};
