<template>
  <DashboardHeader>
    <div class="text-xl font-semibold text-gray-800">
      {{ $t("settings.account.title") }}
    </div>
  </DashboardHeader>
  <BreadcrumbGroup>
    <BreadcrumbItem :to="{ name: 'settings' }">
      {{ $t("settings.title") }}
    </BreadcrumbItem>
    <BreadcrumbItem :to="{ name: 'settings.account' }">
      {{ $t("settings.account.title") }}
    </BreadcrumbItem>
  </BreadcrumbGroup>
  <div class="grid gap-3 md:grid-cols-4">
    <div class="grid gap-3 md:col-span-4 md:grid-cols-2">
      <FormGroup :errors="updateAccountRequest.errors.name">
        <FormLabel>{{ $t("accounts.props.name") }}</FormLabel>
        <FormInput>
          <FormText
            v-model="form.name"
            name="name"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="updateAccountRequest.errors.email">
        <FormLabel>{{ $t("accounts.props.email") }}</FormLabel>
        <FormInput>
          <FormText
            v-model="form.email"
            name="email"
            type="email"
          />
        </FormInput>
      </FormGroup>
    </div>
    <div class="grid gap-3 md:col-span-4 md:grid-cols-2">
      <FormGroup :errors="updateAccountRequest.errors.icon">
        <FormLabel>{{ $t("accounts.props.icon") }}</FormLabel>
        <FormInput>
          <FormImage
            :value="authenticatedAccount?.icon"
            v-model="form.icon"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="updateAccountRequest.errors.logo">
        <FormLabel>{{ $t("accounts.props.logo") }}</FormLabel>
        <FormInput>
          <FormImage
            :value="authenticatedAccount?.logo"
            v-model="form.logo"
          />
        </FormInput>
      </FormGroup>
    </div>
    <div class="grid gap-3 md:col-span-4 md:grid-cols-2">
      <FormGroup :errors="updateAccountRequest.errors.primary_color">
        <FormLabel>{{ $t("accounts.props.primary_color") }}</FormLabel>
        <FormInput>
          <FormText
            v-model="form.primary_color"
            name="primary_color"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="updateAccountRequest.errors.secondary_color">
        <FormLabel>{{ $t("accounts.props.secondary_color") }}</FormLabel>
        <FormInput>
          <FormText
            v-model="form.secondary_color"
            name="secondary_color"
          />
        </FormInput>
      </FormGroup>
    </div>
    <FormGroup :errors="updateAccountRequest.errors.street">
      <FormLabel>{{ $t("accounts.props.street") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="form.street"
          name="street"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="updateAccountRequest.errors.city">
      <FormLabel>{{ $t("accounts.props.city") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="form.city"
          name="city"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="updateAccountRequest.errors.postal_code">
      <FormLabel>{{ $t("accounts.props.postal_code") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="form.postal_code"
          name="postal_code"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="updateAccountRequest.errors.country_code">
      <FormLabel>{{ $t("accounts.props.country_code") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="form.country_code"
          :options="useCountry().countryOptions"
          name="country_code"
        />
      </FormInput>
    </FormGroup>
    <div class="flex items-center justify-end space-y-2.5 md:col-span-4">
      <button
        :disabled="updateAccountRequest.isLoading"
        class="btn-primary"
        type="submit"
        @click.prevent="submit"
      >
        {{ $t("shared.form.save") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useEventBus } from "@vueuse/core";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import BreadcrumbItem from "@/contexts/shared/ui/components/breadcrumbs/BreadcrumbItem.vue";
import BreadcrumbGroup from "@/contexts/shared/ui/components/breadcrumbs/BreadcrumbGroup.vue";
import useAuth from "@/contexts/auth/composables/useAuth";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import type { UnsavedUpdateAccount } from "@/contexts/accounts/models/Account";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import useCountry from "@/contexts/shared/composables/useCountry";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import FormImage from "@/contexts/shared/ui/components/form/FormImage.vue";
import useAccounts from "@/contexts/accounts/composables/useAccounts";
import useRequest from "@/contexts/shared/composables/useRequest";
import useNotification from "@/contexts/shared/composables/useNotification";

const { t } = useI18n();
const router = useRouter();
const { updateAccount } = useAccounts();
const { successNotification, errorNotification } = useNotification();
const { authenticatedAccount, changeAccount } = useAuth();
const accountBus = useEventBus<string>("account");
useHead({ title: () => `${t("settings.account.title")} - Hussbook` });

accountBus.on(() => router.push({ name: "settings" }));

const form = ref<UnsavedUpdateAccount>({
  name: String(authenticatedAccount.value?.name ?? ""),
  email: String(authenticatedAccount.value?.email ?? ""),
  primary_color: String(authenticatedAccount.value?.primary_color ?? ""),
  secondary_color: String(authenticatedAccount.value?.secondary_color ?? ""),
  street: String(authenticatedAccount.value?.street ?? ""),
  city: String(authenticatedAccount.value?.city ?? ""),
  postal_code: String(authenticatedAccount.value?.postal_code ?? ""),
  country_code: String(authenticatedAccount.value?.country_code ?? ""),
});

const updateAccountRequest = useRequest({
  promise: () => updateAccount(String(authenticatedAccount.value?.id), form.value),
  onSuccess: (account) => {
    changeAccount(account);
    successNotification("accounts.update.success");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accounts.update.error");
    }
  },
});

const submit = () => {
  updateAccountRequest.fetch();
};
</script>
