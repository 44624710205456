import type { AccommodationContract, UnsavedAccommodationContractUpdated } from "@/contexts/accommodations/models/AccommodationContract";
import { accommodationContractFromResponse } from "@/contexts/accommodation-contracts/utils/accommodationContractFromResponse";
import type { paths } from "@/libs/api/schema";

const toUpdateAccommodationContractRequest = (contract: UnsavedAccommodationContractUpdated): paths["/accommodations/{accommodation}/contracts/{contract}"]["put"]["requestBody"]["content"]["application/json"] => {
  return {
    reference_price: contract.reference_price,
    weeks: contract.weeks,
    notes: contract.notes,
  };
};

const fromUpdateAccommodationContractResponse = (response: paths["/accommodations/{accommodation}/contracts/{contract}"]["put"]["responses"][200]["content"]["application/json"] | undefined): AccommodationContract => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationContractFromResponse(response.data);
};

export default {
  toUpdateAccommodationContractRequest,
  fromUpdateAccommodationContractResponse,
};
