<template>
  <component :is="icons[icon]" />
</template>

<script lang="ts" setup>
defineProps<{
  icon: FileIconTypes;
}>();

const icons = {
  default: defineAsyncComponent(() => import("@/assets/svg/files/default.svg?component")),
  spreadsheet: defineAsyncComponent(() => import("@/assets/svg/files/spreadsheet.svg?component")),
  image: defineAsyncComponent(() => import("@/assets/svg/files/image.svg?component")),
  pdf: defineAsyncComponent(() => import("@/assets/svg/files/pdf.svg?component")),
};

export type FileIconTypes = keyof typeof icons;
</script>
