import axios from "@/libs/axios";
import type CreateAccountIntegrationRequest from "@/clients/account-integrations/CreateAccountIntegration/request/CreateAccountIntegrationRequest";
import type CreateAccountIntegrationResponse from "@/clients/account-integrations/CreateAccountIntegration/response/CreateAccountIntegrationResponse";
import { pickBy } from "lodash-es";

export default async function createAccountIntegration(request: CreateAccountIntegrationRequest): Promise<CreateAccountIntegrationResponse> {
  try {
    const { data } = await axios.post<CreateAccountIntegrationResponse>(`/settings/integrations`, pickBy(request));
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
