import axios from "@/libs/axios";
import { pickBy } from "lodash-es";
import type SearchOwnersRequest from "@/clients/owners/SearchOwners/request/SearchOwnersRequest";
import type SearchOwnersResponse from "@/clients/owners/SearchOwners/response/SearchOwnersResponse";

export default async function searchOwners(request: SearchOwnersRequest): Promise<SearchOwnersResponse> {
  try {
    const { data, headers } = await axios.get<SearchOwnersResponse>("/owners", { params: pickBy(request) });
    return Promise.resolve({ ...data, total: Number(headers["x-total-count"] ?? 0) });
  } catch (e) {
    return Promise.reject(e);
  }
}
