import type { AccountSeason, UnsavedAccountSeason } from "@/contexts/accounts/models/AccountSeason";
import accountSeasonsClient from "@/clients/account-seasons";
import SearchAccountSessionsFactory from "@/contexts/accounts/composables/factories/SearchAccountSessionsFactory";
import UpdateAccountSeasonFactory from "@/contexts/accounts/composables/factories/UpdateAccountSeasonFactory";

export default function useAccountSeasons() {
  const searchAccountSeasons = async (year: number): Promise<Record<number, AccountSeason | null>> => {
    return SearchAccountSessionsFactory.fromSearchAccountSeasonsResponse(await accountSeasonsClient.searchAccountSeasons(year));
  };
  const updateAccountSeason = async (season: UnsavedAccountSeason): Promise<AccountSeason> => {
    return UpdateAccountSeasonFactory.fromUpdateAccountSeasonsResponse(await accountSeasonsClient.updateAccountSeason(season.year, season.week, UpdateAccountSeasonFactory.toUpdateAccountSeasonRequest(season)));
  };

  return {
    searchAccountSeasons,
    updateAccountSeason,
  };
}
