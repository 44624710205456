import axios from "@/libs/axios";
import type ExportAccommodationPhotosResponse from "@/clients/accommodation-photos/ExportAccommodationPhotos/response/ExportAccommodationPhotosResponse";

export default async function exportAccommodationPhotos(accommodationId: string): Promise<ExportAccommodationPhotosResponse> {
  try {
    const { data } = await axios.get<ExportAccommodationPhotosResponse>(`/accommodations/${accommodationId}/photos/export`);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
