import type SearchAccountServicesResponse from "@/clients/account-services/SearchAccountServices/response/SearchAccountServicesResponse";
import type SearchAccountServicesRequest from "@/clients/account-services/SearchAccountServices/request/SearchAccountServicesRequest";
import axios from "@/libs/axios";
import { pickBy } from "lodash-es";

export default async function searchAccountServices(request: SearchAccountServicesRequest): Promise<SearchAccountServicesResponse> {
  try {
    const { data, headers } = await axios.get<SearchAccountServicesResponse>("/settings/account/services", { params: pickBy(request) });
    return Promise.resolve({ ...data, total: Number(headers["x-total-count"] ?? 0) });
  } catch (e) {
    return Promise.reject(e);
  }
}
