<template>
  <ConfirmModal
    :is-loading="cancelBookingRequest.isLoading"
    :description="$t('bookings.cancel.description', isArray(props.id) ? props.id.length : 1)"
    :title="$t('bookings.cancel.title', isArray(props.id) ? props.id.length : 1)"
    @close="$emit('close')"
    @confirm="cancelBookingRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import useRequest from "@/contexts/shared/composables/useRequest";
import useNotification from "@/contexts/shared/composables/useNotification";
import ConfirmModal from "@/contexts/shared/ui/modals/ConfirmModal.vue";
import useBookings from "@/contexts/bookings/composables/useBookings";
import { isArray } from "lodash-es";

const props = defineProps<{
  id: string | string[];
}>();

const emit = defineEmits<{
  close: [];
  cancelled: [];
}>();

const { t } = useI18n();
const { cancelBooking } = useBookings();
const { successNotification, errorNotification } = useNotification();

const cancelBookingRequest = useRequest({
  promise: () => cancelBooking(props.id),
  onSuccess: () => {
    successNotification(t("bookings.cancel.success", isArray(props.id) ? props.id.length : 1));
    emit("cancelled");
    emit("close");
  },
  onFailure: () => {
    errorNotification(t("bookings.cancel.error", isArray(props.id) ? props.id.length : 1));
  },
});
</script>
