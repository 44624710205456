import type { SearchBookingsCriteria } from "@/contexts/bookings/models/SearchBookingsCriteria";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import type { Booking } from "@/contexts/bookings/models/Booking";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import type { paths } from "@/libs/api/schema";
import { paginationMetaFromResponse } from "@/contexts/shared/utils/paginationMetaFromResponse";
import { bookingFromResponse } from "@/contexts/bookings/utils/bookingFromResponse";

const toSearchBookingsRequest = (criteria: SearchBookingsCriteria, cursor?: string): paths["/bookings"]["get"]["parameters"]["query"] => {
  const dayjs = useDayjs();

  return {
    code: criteria.code ?? undefined,
    "status[]": criteria.status ?? undefined,
    "check_in[from]": criteria.check_in ? dayjs(criteria.check_in.from).startOf("day").format("YYYY-MM-DD") : undefined,
    "check_in[to]": criteria.check_in ? dayjs(criteria.check_in.to).endOf("day").format("YYYY-MM-DD") : undefined,
    "check_out[from]": criteria.check_out ? dayjs(criteria.check_out.from).startOf("day").format("YYYY-MM-DD") : undefined,
    "check_out[to]": criteria.check_out ? dayjs(criteria.check_out.to).endOf("day").format("YYYY-MM-DD") : undefined,
    "provider[]": criteria.provider ?? undefined,
    accommodation_id: criteria.accommodation_id ?? undefined,
    order_by: criteria.order_by ?? undefined,
    sort: criteria.sort ?? undefined,
    per_page: criteria.per_page ?? undefined,
    cursor: cursor ?? undefined,
  };
};

const fromSearchBookingsResponse = (response: paths["/bookings"]["get"]["responses"][200]["content"]["application/json"] | undefined, headers: Headers): Pagination<Booking> => {
  return {
    data: response?.data?.map((item) => bookingFromResponse(item)) ?? [],
    meta: paginationMetaFromResponse(response?.meta),
    total: Number(headers.get("x-total-count") ?? 0),
  };
};

export default {
  toSearchBookingsRequest,
  fromSearchBookingsResponse,
};
