import type { Middleware } from "openapi-fetch";
import createClient from "openapi-fetch";
import type { paths } from "@/libs/api/schema";
import i18n from "@/i18n";
import useAuth from "@/contexts/auth/composables/useAuth";
import router from "@/router";
import { useAuthStore } from "@/contexts/auth/store";
import { isBoolean } from "lodash-es";

const UNPROTECTED_ROUTES: (keyof paths)[] = ["/oauth/{provider}/redirect"];

const instance = createClient<paths>({
  baseUrl: String(import.meta.env.VITE_API_URL),
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  credentials: "include",
  querySerializer(queryParams) {
    const search: string[] = [];
    for (const name in queryParams) {
      const value = queryParams[name];
      if (value === undefined || value === null) {
        continue;
      }
      if (Array.isArray(value)) {
        for (const item of value) {
          search.push(`${name}=${encodeURIComponent(item)}`);
        }
      } else if (isBoolean(value)) {
        search.push(`${name}=${encodeURIComponent(value ? "1" : "0")}`);
      } else {
        search.push(`${name}=${encodeURIComponent(value as string | number | boolean)}`);
      }
    }
    return search.join("&");
  },
});

export class ApiError extends Error {
  public readonly status: number = 500;
  public readonly response: any;

  constructor(message?: string, status?: number, response?: any) {
    super(message);
    this.message = message ?? "An error occurred";
    this.name = "ApiError";
    status && (this.status = status);
    response && (this.response = response);
  }
}

const errorMiddleware: Middleware = {
  async onResponse({ response }) {
    if (!response.ok) {
      if (response.status === 401) {
        router.push({ name: "login", query: { redirect: !["/login", "/", ""].includes(window.location.pathname) ? window.location.pathname : undefined } });
        useAuth().clearAuth();
        return;
      }

      throw new ApiError(response.statusText, response.status, await response.json());
    }
    return response;
  },
};

const authMiddleware: Middleware = {
  async onRequest({ request, schemaPath }) {
    const cookies = document.cookie.split("; ");
    const xsrfToken = cookies.find((row) => row.startsWith("XSRF-TOKEN="))?.split("=")[1];
    if (xsrfToken) {
      request.headers.set("X-XSRF-TOKEN", String(decodeURIComponent(xsrfToken)));
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone) {
      request.headers.set("Timezone", timezone);
    }
    if (i18n.global.locale.value) {
      request.headers.set("Locale", String(i18n.global.locale.value));
    }
    if (!UNPROTECTED_ROUTES.some((unprotected) => unprotected === schemaPath)) {
      const { authenticatedId, authenticatedAccountId } = useAuth();
      if (authenticatedId.value && authenticatedAccountId.value) {
        request.headers.set("Account-Id", String(authenticatedAccountId.value));
      }
    }
    return request;
  },
};

instance.use(errorMiddleware);
instance.use(authMiddleware);

export default instance;
