import type { AccommodationBedroom } from "@/contexts/accommodations/models/AccommodationRooms";
import type { components } from "@/libs/api/schema";

export const accommodationBedroomFromResponse = (response: components["schemas"]["AccommodationBedroom"]): AccommodationBedroom => {
  return {
    id: response.id,
    type: response.type,
    floor: response.floor,
    single_beds: response.single_beds,
    double_beds: response.double_beds,
    suite_beds: response.suite_beds,
    bunk_beds: response.bunk_beds,
  };
};
