import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import isBetween from "dayjs/plugin/isBetween";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import type { App } from "vue";

export {};

declare global {
  interface Window {
    dayjs: typeof dayjs;
  }
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $dayjs: typeof dayjs;
  }
}

export default {
  install: (app: App) => {
    dayjs.extend(isoWeek);
    dayjs.extend(weekOfYear);
    dayjs.extend(isBetween);
    dayjs.extend(relativeTime);
    dayjs.extend(updateLocale);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(quarterOfYear);
    dayjs.extend(advancedFormat);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(localizedFormat);
    dayjs.extend(customParseFormat);
    dayjs.locale(app.config.globalProperties.$i18n.locale, {
      monthsShort: app.config.globalProperties.$t("shared.dayjs.months_short").split("_"),
      weekdays: app.config.globalProperties.$t("shared.dayjs.weekdays").split("_"),
      weekdaysShort: app.config.globalProperties.$t("shared.dayjs.weekdays_short").split("_"),
      weekdaysMin: app.config.globalProperties.$t("shared.dayjs.weekdays_min").split("_"),
      months: app.config.globalProperties.$t("shared.dayjs.months").split("_"),
      weekStart: 1,
      formats: {
        LT: app.config.globalProperties.$t("shared.dayjs.formats.LT"),
        LTS: app.config.globalProperties.$t("shared.dayjs.formats.LTS"),
        L: app.config.globalProperties.$t("shared.dayjs.formats.L"),
        LL: app.config.globalProperties.$t("shared.dayjs.formats.LL"),
        LLL: app.config.globalProperties.$t("shared.dayjs.formats.LLL"),
        LLLL: app.config.globalProperties.$t("shared.dayjs.formats.LLLL"),
      },
      relativeTime: {
        future: app.config.globalProperties.$t("shared.dayjs.relative_time.future"),
        past: app.config.globalProperties.$t("shared.dayjs.relative_time.past"),
        s: app.config.globalProperties.$t("shared.dayjs.relative_time.s"),
        m: app.config.globalProperties.$t("shared.dayjs.relative_time.m"),
        mm: app.config.globalProperties.$t("shared.dayjs.relative_time.mm"),
        h: app.config.globalProperties.$t("shared.dayjs.relative_time.h"),
        hh: app.config.globalProperties.$t("shared.dayjs.relative_time.hh"),
        d: app.config.globalProperties.$t("shared.dayjs.relative_time.d"),
        dd: app.config.globalProperties.$t("shared.dayjs.relative_time.dd"),
        M: app.config.globalProperties.$t("shared.dayjs.relative_time.M"),
        MM: app.config.globalProperties.$t("shared.dayjs.relative_time.MM"),
        y: app.config.globalProperties.$t("shared.dayjs.relative_time.y"),
        yy: app.config.globalProperties.$t("shared.dayjs.relative_time.yy"),
      },
    });

    window.dayjs = dayjs;

    app.config.globalProperties.$dayjs = window.dayjs;
  },
};
