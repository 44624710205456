import accountIntegrationsClient from "@/clients/account-integrations";
import type { AccountIntegration, AccountIntegrations, UnsavedAccountIntegration } from "@/contexts/accounts/models/AccountIntegration";
import GetAccountIntegrationsFactory from "@/contexts/accounts/composables/factories/GetAccountIntegrationsFactory";
import CreateAccountIntegrationsFactory from "@/contexts/accounts/composables/factories/CreateAccountIntegrationsFactory";
import UpdateAccountIntegrationsFactory from "@/contexts/accounts/composables/factories/UpdateAccountIntegrationsFactory";

export default function useAccountIntegrations() {
  const getAccountIntegrations = async (): Promise<{ credentials: AccountIntegration[]; integrations: AccountIntegrations }> => {
    return GetAccountIntegrationsFactory.fromGetAccountIntegrationsResponse(await accountIntegrationsClient.getAccountIntegrations());
  };
  const createAccountIntegration = async (integration: UnsavedAccountIntegration): Promise<AccountIntegration> => {
    return CreateAccountIntegrationsFactory.fromCreateAccountIntegrationResponse(await accountIntegrationsClient.createAccountIntegration(CreateAccountIntegrationsFactory.toCreateAccountIntegrationRequest(integration)));
  };
  const updateAccountIntegration = async (id: string, integration: UnsavedAccountIntegration): Promise<AccountIntegration> => {
    return UpdateAccountIntegrationsFactory.fromUpdateAccountIntegrationResponse(await accountIntegrationsClient.updateAccountIntegration(id, UpdateAccountIntegrationsFactory.toUpdateAccountIntegrationRequest(integration)));
  };
  const disableAccountIntegration = async (id: string): Promise<void> => {
    return await accountIntegrationsClient.disableAccountIntegration(id);
  };
  const enableAccountIntegration = async (id: string): Promise<void> => {
    return await accountIntegrationsClient.enableAccountIntegration(id);
  };

  return {
    getAccountIntegrations,
    createAccountIntegration,
    updateAccountIntegration,
    disableAccountIntegration,
    enableAccountIntegration,
  };
}
