<template>
  <Badge :class="classes">
    {{ $t(`bookings.status.${status}`) }}
  </Badge>
</template>

<script setup lang="ts">
import type { BookingStatus } from "@/contexts/bookings/models/Booking";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";

const props = defineProps<{
  status: BookingStatus | null;
}>();

const classes = computed(() => {
  switch (props.status) {
    case "draft":
    case "requested":
      return "badge-gray";
    case "confirmed":
      return "badge-green";
    case "completed":
      return "badge-primary";
    case "rejected":
    case "cancelled":
    case "expired":
    case "refunded":
      return "badge-orange";
  }
});
</script>
