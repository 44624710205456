<template>
  <FormText
    :max="max"
    :min="min"
    v-bind="$props"
    @keyup="preventText"
    @update:model-value="input = $event as number"
  />
</template>

<script lang="ts" setup>
import type { Props } from "@/contexts/shared/ui/components/form/FormText.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";

const props = withDefaults(
  defineProps<
    Props & {
      modelValue: number | null | undefined;
      min?: number;
      max?: number;
    }
  >(),
  {
    type: "number",
    min: undefined,
    max: undefined,
  },
);

const emit = defineEmits<{
  "update:modelValue": [value: number | null | undefined];
}>();

const input = computed<number>({
  get: () => props.modelValue ?? 0,
  set: (value) => emit("update:modelValue", value),
});

const preventText = (event: KeyboardEvent & { target: HTMLInputElement }) => {
  if (!/\d/.test(event.key) && ((String(event.target?.value).includes(".") && event.key === ".") || event.key !== ".") && ((String(event.target?.value).includes("-") && event.key === "-") || event.key !== "-")) {
    event.preventDefault();
  }
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
