export default function useClipboard() {
  const copyToClipboard = async (value: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(value);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return {
    copyToClipboard,
  };
}
