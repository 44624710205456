import type { BookingGuest, UnsavedBookingGuest } from "@/contexts/booking-guests/models/BookingGuest";
import type CreateBookingGuestRequest from "@/clients/booking-guests/CreateBookingGuest/request/CreateBookingGuestRequest";
import type CreateBookingGuestResponse from "@/clients/booking-guests/CreateBookingGuest/response/CreateBookingGuestResponse";

const toCreateBookingGuestRequest = (guest: UnsavedBookingGuest): CreateBookingGuestRequest => {
  return {
    name: guest.name,
    emails: guest.emails,
    phones: guest.phones,
    language: guest.language,
  };
};

const fromCreateBookingGuestResponse = (response: CreateBookingGuestResponse): BookingGuest => {
  return {
    id: response.data.id,
    name: response.data.name,
    emails: response.data.emails,
    phones: response.data.phones,
    language: response.data.language,
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
  };
};

export default {
  toCreateBookingGuestRequest,
  fromCreateBookingGuestResponse,
};
