<template>
  <template v-if="bedrooms.length === 0">
    <SectionEmptyState>
      {{ $t("accommodations.manage.bedrooms.empty") }}
    </SectionEmptyState>
  </template>
  <template v-else>
    <div class="grid grid-cols-2 place-items-stretch gap-2.5">
      <template v-for="(items, group) in bedroomsGroup">
        <template
          v-for="(bedroom, index) in items"
          :key="index"
        >
          <div class="min-h-24 rounded border border-gray-200 p-2.5">
            <div class="text-sm font-medium text-gray-800">{{ $t(`accommodations.manage.bedrooms.name.${group}`, { number: index + 1 }) }}</div>
            <div class="mt-1.5 text-sm text-gray-500">
              <div v-if="bedroom.single_beds > 0">{{ $t("accommodations.manage.bedrooms.count.single_beds", bedroom.single_beds) }}</div>
              <div v-if="bedroom.double_beds > 0">{{ $t("accommodations.manage.bedrooms.count.double_beds", bedroom.double_beds) }}</div>
              <div v-if="bedroom.suite_beds > 0">{{ $t("accommodations.manage.bedrooms.count.suite_beds", bedroom.suite_beds) }}</div>
              <div v-if="bedroom.bunk_beds > 0">{{ $t("accommodations.manage.bedrooms.count.bunk_beds", bedroom.bunk_beds) }}</div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </template>
</template>

<script lang="ts" setup>
import type { AccommodationBedroom } from "@/contexts/accommodations/models/AccommodationRooms";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";
import { groupBy } from "lodash-es";

const props = defineProps<{
  bedrooms: AccommodationBedroom[];
}>();

const bedroomsGroup = computed<Record<string, AccommodationBedroom[]>>(() => groupBy(props.bedrooms, "type"));
</script>
