import type { Option } from "@/contexts/shared/models/Select";

export default function useArchived() {
  const { t } = useI18n();

  const archivedOptions: Option[] = [
    { value: true, label: t("shared.archived") },
    { value: false, label: t("shared.unarchived") },
  ];

  return {
    archivedOptions,
  };
}
