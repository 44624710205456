import type { paths } from "@/libs/api/schema";
import useDayjs from "@/contexts/shared/composables/useDayjs";

const toSyncAccommodationPricesRequest = (from: Date | null, to: Date | null): paths["/accommodations/{accommodation}/prices/sync"]["post"]["requestBody"]["content"]["application/json"] => {
  const dayjs = useDayjs();

  return {
    from: from ? dayjs(from).startOf("day").format("YYYY-MM-DD") : "",
    to: to ? dayjs(to).endOf("day").format("YYYY-MM-DD") : "",
  };
};

export default {
  toSyncAccommodationPricesRequest,
};
