<template>
  <template v-if="showCleaningGroupRequest.isLoading">
    <LoadingSection />
  </template>
  <template v-else-if="showCleaningGroupRequest.hasErrors">
    <ListErrorState />
  </template>
  <template v-else-if="showCleaningGroupRequest.data">
    <DashboardHeader>
      <DashboardBackTo :to="{ name: 'cleaning_groups' }">
        {{ $t("cleaning_groups.manage.back_to_list") }}
      </DashboardBackTo>
      <DashboardHeaderTitle>{{ $t("cleaning_groups.manage.title") }}</DashboardHeaderTitle>
      <DashboardHeaderActions>
        <ClipboardCopyId :value="showCleaningGroupRequest.data.id" />
        <CleaningGroupsDropdown
          :cleaning-group="showCleaningGroupRequest.data"
          hide-edit
          @archived="showCleaningGroupRequest.fetch()"
          @deleted="router.push({ name: 'cleaning_groups' })"
          @unarchived="showCleaningGroupRequest.fetch()"
        >
          <DropdownMenuButton v-slot="{ toggle }">
            <button
              type="button"
              class="btn-white"
              @click="toggle"
            >
              <FontAwesomeIcon
                class="h-5 w-5 fill-gray-700"
                icon="ellipsis-light"
              />
            </button>
          </DropdownMenuButton>
        </CleaningGroupsDropdown>
      </DashboardHeaderActions>
    </DashboardHeader>
    <SectionGroup>
      <section v-if="showCleaningGroupRequest.data.archived_at">
        <SharedAlert
          description="cleaning_groups.archived.description"
          title="cleaning_groups.archived.title"
          variant="info"
        />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("cleaning_groups.manage.details.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="pen-solid"
              @click="updateCleaningGroupModal?.open()"
            >
              {{ $t("cleaning_groups.manage.details.edit") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <ListDetails>
          <ListDetailsColumn>
            <ListDetailsCell label="cleaning_groups.props.id">
              {{ showCleaningGroupRequest.data.id }}
            </ListDetailsCell>
            <ListDetailsCell label="cleaning_groups.props.name">
              {{ showCleaningGroupRequest.data.name }}
            </ListDetailsCell>
            <ListDetailsCell label="cleaning_groups.props.updated_at">
              {{ showCleaningGroupRequest.data.updated_at.toLocaleString() }}
            </ListDetailsCell>
            <ListDetailsCell label="cleaning_groups.props.created_at">
              {{ showCleaningGroupRequest.data.created_at.toLocaleString() }}
            </ListDetailsCell>
          </ListDetailsColumn>
        </ListDetails>
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("cleaning_groups.manage.booking_cleanings.title") }}</SectionTitle>
        </SectionHeader>
        <CleaningGroupsBookingCleanings :cleaning-group="showCleaningGroupRequest.data" />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("cleaning_groups.manage.accommodations.title") }}</SectionTitle>
        </SectionHeader>
        <CleaningGroupsAccommodations :cleaning-group="showCleaningGroupRequest.data" />
      </section>
    </SectionGroup>
  </template>
  <ModalDialog
    ref="updateCleaningGroupModal"
    v-slot="{ close }"
  >
    <CleaningGroupsUpdateModal
      :cleaning-group="showCleaningGroupRequest.data"
      @close="close"
      @updated="showCleaningGroupRequest.fetch()"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import useCleaningGroups from "@/contexts/cleaning-groups/composables/useCleaningGroups";
import useRequest from "@/contexts/shared/composables/useRequest";
import SectionTitle from "@/contexts/shared/ui/components/section/SectionTitle.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import SectionHeader from "@/contexts/shared/ui/components/section/SectionHeader.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import { useEventBus } from "@vueuse/core";
import ListDetailsCell from "@/contexts/shared/ui/components/list/ListDetailsCell.vue";
import ListDetails from "@/contexts/shared/ui/components/list/ListDetails.vue";
import ListDetailsColumn from "@/contexts/shared/ui/components/list/ListDetailsColumn.vue";
import SectionGroup from "@/contexts/shared/ui/components/section/SectionGroup.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import ClipboardCopyId from "@/contexts/shared/ui/components/clipboard/ClipboardCopyId.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import DashboardBackTo from "@/contexts/shared/ui/components/header/DashboardBackTo.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import CleaningGroupsUpdateModal from "@/contexts/cleaning-groups/ui/modals/CleaningGroupsUpdateModal.vue";
import Errors from "@/contexts/shared/models/Errors";
import CleaningGroupsDropdown from "@/contexts/cleaning-groups/ui/components/CleaningGroupsDropdown.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import SharedAlert from "@/contexts/shared/ui/components/alert/SharedAlert.vue";
import CleaningGroupsAccommodations from "@/contexts/cleaning-groups/ui/components/CleaningGroupsAccommodations.vue";
import CleaningGroupsBookingCleanings from "@/contexts/cleaning-groups/ui/components/CleaningGroupsBookingCleanings.vue";
import DropdownMenuButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuButton.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { showCleaningGroup } = useCleaningGroups();
const accountBus = useEventBus<string>("account");

accountBus.on(() => router.push({ name: "cleaning_groups" }));
useHead({ title: () => `${t("cleaning_groups.manage.title")} - Hussbook` });

const cleaningGroupId = computed<string>(() => String(route.params.id));
const updateCleaningGroupModal = ref<InstanceType<typeof ModalDialog>>();

onMounted(() => {
  showCleaningGroupRequest.fetch();
});

const showCleaningGroupRequest = useRequest<CleaningGroup>({
  initialLoading: true,
  promise: () => showCleaningGroup(cleaningGroupId.value),
  onFailure: (e) => {
    if (e === Errors.NOT_FOUND) {
      router.push({ name: "cleaning_groups" });
      return;
    }
  },
});
</script>
