<template>
  <ConfirmDeleteModal
    :is-loading="deleteOwnerRequest.isLoading"
    description="owners.delete.description"
    title="owners.delete.title"
    @close="$emit('close')"
    @confirm="deleteOwnerRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import ConfirmDeleteModal from "@/contexts/shared/ui/modals/ConfirmDeleteModal.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import useNotification from "@/contexts/shared/composables/useNotification";
import useOwners from "@/contexts/owners/composables/useOwners";
import type { Owner } from "@/contexts/owners/models/Owner";

const props = defineProps<{
  owner: Owner;
}>();

const emit = defineEmits<{
  close: [];
  deleted: [];
}>();

const { deleteOwner } = useOwners();
const { successNotification, errorNotification } = useNotification();

const deleteOwnerRequest = useRequest({
  promise: () => deleteOwner(props.owner.id),
  onSuccess: () => {
    successNotification("owners.delete.success");
    emit("deleted");
    emit("close");
  },
  onFailure: () => {
    errorNotification("owners.delete.error");
  },
});
</script>
