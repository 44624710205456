<template>
  <input
    :id="id"
    v-model="modelValue"
    :name="name"
    :value="value"
    class="h-4 w-4 rounded border-gray-300 text-primary shadow-sm focus:ring-primary"
    type="checkbox"
    @click.stop
  />
</template>

<script lang="ts" setup>
defineProps<{
  name: string;
  value?: string | number | boolean;
}>();

const modelValue = defineModel<any[] | Set<any> | boolean | undefined>({ default: [] });

const id = inject<string>("id", "");
</script>
