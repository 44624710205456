<template>
  <FormText
    step="0.1"
    v-bind="$props"
    :model-value="modelValue ? modelValue / 100 : null"
    type="number"
    @update:model-value="modelValue = $event ? parseInt(String(Number($event) * 100)) : null"
  />
</template>

<script setup lang="ts">
import FormText, { type Props } from "@/contexts/shared/ui/components/form/FormText.vue";

defineProps<Omit<Props, "type" | "modelValue"> & { min?: number; max?: number }>();

const modelValue = defineModel<number | null | undefined>({ default: null });
</script>
