<template>
  <div ref="target">
    <template v-if="searchBookingCleaningsRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchBookingCleaningsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchBookingCleaningsRequest.data.data.length === 0">
      <SectionEmptyState>
        {{ $t("cleaning_groups.manage.booking_cleanings.empty") }}
      </SectionEmptyState>
    </template>
    <template v-else>
      <div class="space-y-3">
        <ListTable>
          <ListTableHeader>
            <ListTableHeaderCell primary>
              {{ $t("booking_cleanings.props.type") }}
            </ListTableHeaderCell>
            <ListTableHeaderCell>
              {{ $t("booking_cleanings.props.datetime") }}
            </ListTableHeaderCell>
            <ListTableHeaderCell>
              {{ $t("booking_cleanings.props.booking_id") }}
            </ListTableHeaderCell>
            <ListTableHeaderCell>
              {{ $t("booking_cleanings.props.accommodation_id") }}
            </ListTableHeaderCell>
            <ListTableHeaderCell optional>
              {{ $t("booking_cleanings.props.single_beds") }}
            </ListTableHeaderCell>
            <ListTableHeaderCell optional>
              {{ $t("booking_cleanings.props.double_beds") }}
            </ListTableHeaderCell>
            <ListTableHeaderCell optional>
              {{ $t("booking_cleanings.props.suite_beds") }}
            </ListTableHeaderCell>
            <ListTableHeaderCell optional>
              {{ $t("booking_cleanings.props.bunk_beds") }}
            </ListTableHeaderCell>
          </ListTableHeader>
          <ListTableBody>
            <template
              v-for="cleaning in searchBookingCleaningsRequest.data.data"
              :key="cleaning.id"
            >
              <ListTableBodyRow>
                <ListTableBodyCell class="w-full">
                  <BookingCleaningsTypeIcon :type="cleaning.type" />
                </ListTableBodyCell>
                <ListTableBodyCell>
                  {{ cleaning.datetime?.toLocaleString() ?? "-" }}
                </ListTableBodyCell>
                <ListTableBodyCell>
                  <BookingsDetailsLink :booking="cleaning?.booking" />
                </ListTableBodyCell>
                <ListTableBodyCell>
                  <AccommodationsDetailsLink :accommodation="cleaning?.booking?.accommodation" />
                </ListTableBodyCell>
                <ListTableBodyCell optional>
                  {{ cleaning.single_beds }}
                </ListTableBodyCell>
                <ListTableBodyCell optional>
                  {{ cleaning.double_beds }}
                </ListTableBodyCell>
                <ListTableBodyCell optional>
                  {{ cleaning.suite_beds }}
                </ListTableBodyCell>
                <ListTableBodyCell optional>
                  {{ cleaning.bunk_beds }}
                </ListTableBodyCell>
              </ListTableBodyRow>
            </template>
          </ListTableBody>
        </ListTable>
        <CursorPagination
          :meta="searchBookingCleaningsRequest.data.meta"
          :total="searchBookingCleaningsRequest.data.total"
          @change-page="changePage"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useVisibility from "@/contexts/shared/composables/useVisibility";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import { emptyPagination, type Pagination } from "@/contexts/shared/models/Pagination";
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import type { SearchBookingCleaningsCriteria } from "@/contexts/booking-cleanings/models/SearchBookingCleaningsCriteria";
import useBookingCleanings from "@/contexts/booking-cleanings/composables/useBookingCleanings";
import type { BookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import BookingCleaningsTypeIcon from "@/contexts/booking-cleanings/ui/components/BookingCleaningsTypeIcon.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import BookingsDetailsLink from "@/contexts/bookings/ui/components/BookingsDetailsLink.vue";
import AccommodationsDetailsLink from "@/contexts/accommodations/ui/components/AccommodationsDetailsLink.vue";

const props = defineProps<{
  cleaningGroup: CleaningGroup;
}>();

const { searchBookingCleanings } = useBookingCleanings();

const target = ref<HTMLElement>();
const { criteria, cursor, changePage } = useCriteria<SearchBookingCleaningsCriteria>({ cleaning_group_id: String(props.cleaningGroup.id), per_page: 10 }, () => searchBookingCleaningsRequest.fetch());
useVisibility(target, () => searchBookingCleaningsRequest.fetch());

const searchBookingCleaningsRequest: UseRequest<Pagination<BookingCleaning>> = useRequest<Pagination<BookingCleaning>>({
  initialLoading: true,
  value: emptyPagination<BookingCleaning>(),
  promise: () => searchBookingCleanings(criteria.value, cursor.value),
  onSuccess: (response) => {
    if (cursor.value && response.data.length === 0) {
      changePage();
      return;
    }
  },
});
</script>
