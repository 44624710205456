<template>
  <SwitchRoot
    :id="id"
    v-model="input"
    :name="name"
    class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
  >
    <div class="pointer-events-none absolute h-full w-full rounded-md bg-white" />
    <div :class="[input ? 'bg-primary' : 'bg-gray-200', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']" />
    <div :class="[input ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-100 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']" />
  </SwitchRoot>
</template>

<script lang="ts" setup>
import { Switch as SwitchRoot } from "@headlessui/vue";

const props = defineProps<{
  name: string;
  modelValue: boolean;
}>();
const emit = defineEmits<{
  "update:modelValue": [value: boolean];
}>();

const input = computed<boolean>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const id = inject<string>("id", "");
</script>
