import type CreateAccountServiceRequest from "@/clients/account-services/CreateAccountService/request/CreateAccountServiceRequest";
import axios from "@/libs/axios";
import { pickBy } from "lodash-es";

export default async function createAccountService(request: CreateAccountServiceRequest): Promise<void> {
  try {
    await axios.post(`/settings/account/services`, pickBy(request));
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
