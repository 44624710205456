<template>
  <component :is="icons[icon]" />
</template>

<script lang="ts" setup>
defineProps<{
  icon: FontAwesomeIconTypes;
}>();

const icons = {
  "angle-left-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/angle-left-solid.svg?component")),
  "angle-right-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/angle-right-solid.svg?component")),
  "arrow-down-to-line-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/arrow-down-to-line-solid.svg?component")),
  "arrow-left-long-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/arrow-left-long-solid.svg?component")),
  "arrows-up-down-left-right-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/arrows-up-down-left-right-solid.svg?component")),
  "bars-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/bars-solid.svg?component")),
  "bath-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/bath-solid.svg?component")),
  "bed-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/bed-solid.svg?component")),
  "bolt-auto-duotone": defineAsyncComponent(() => import("@/assets/svg/font-awesome/bolt-auto-duotone.svg?component")),
  "briefcase-duotone": defineAsyncComponent(() => import("@/assets/svg/font-awesome/briefcase-duotone.svg?component")),
  "broom-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/broom-solid.svg?component")),
  "building-columns-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/building-columns-solid.svg?component")),
  "building-user-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/building-user-solid.svg?component")),
  "calendar-days-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/calendar-days-solid.svg?component")),
  "calendar-lines-pen-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/calendar-lines-pen-solid.svg?component")),
  "calendar-week-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/calendar-week-solid.svg?component")),
  "check-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/check-solid.svg?component")),
  "chevron-down-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/chevron-down-solid.svg?component")),
  "chevron-left-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/chevron-left-solid.svg?component")),
  "chevron-right-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/chevron-right-solid.svg?component")),
  "circle-check-regular": defineAsyncComponent(() => import("@/assets/svg/font-awesome/circle-check-regular.svg?component")),
  "circle-check-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/circle-check-solid.svg?component")),
  "circle-plus-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/circle-plus-solid.svg?component")),
  "circle-xmark-regular": defineAsyncComponent(() => import("@/assets/svg/font-awesome/circle-xmark-regular.svg?component")),
  "circle-xmark-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/circle-xmark-solid.svg?component")),
  "clipboard-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/clipboard-solid.svg?component")),
  "ellipsis-light": defineAsyncComponent(() => import("@/assets/svg/font-awesome/ellipsis-light.svg?component")),
  "eraser-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/eraser-solid.svg?component")),
  "eye-slash-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/eye-slash-solid.svg?component")),
  "eye-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/eye-solid.svg?component")),
  "fan-table-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/fan-table-solid.svg?component")),
  "file-arrow-up-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/file-arrow-up-solid.svg?component")),
  "file-circle-plus-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/file-circle-plus-solid.svg?component")),
  "file-contract-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/file-contract-solid.svg?component")),
  "file-zipper-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/file-zipper-solid.svg?component")),
  "gauge-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/gauge-solid.svg?component")),
  "grid-dividers-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/grid-dividers-solid.svg?component")),
  "gear-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/gear-solid.svg?component")),
  "heat-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/heat-solid.svg?component")),
  "house-building-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/house-building-solid.svg?component")),
  "image-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/image-solid.svg?component")),
  "list-check-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/list-check-solid.svg?component")),
  "location-dot-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/location-dot-solid.svg?component")),
  "lock-keyhole-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/lock-keyhole-solid.svg?component")),
  "magnifying-glass-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/magnifying-glass-solid.svg?component")),
  "minus-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/minus-solid.svg?component")),
  "paperclip-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/paperclip-solid.svg?component")),
  "pen-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/pen-solid.svg?component")),
  "plug-duotone": defineAsyncComponent(() => import("@/assets/svg/font-awesome/plug-duotone.svg?component")),
  "plus-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/plus-solid.svg?component")),
  "print-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/print-solid.svg?component")),
  "repeat-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/repeat-solid.svg?component")),
  "right-from-bracket-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/right-from-bracket-solid.svg?component")),
  "robot-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/robot-solid.svg?component")),
  "spinner-third-duotone": defineAsyncComponent(() => import("@/assets/svg/font-awesome/spinner-third-duotone.svg?component")),
  "square-info-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/square-info-solid.svg?component")),
  "square-parking-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/square-parking-solid.svg?component")),
  "table-list-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/table-list-solid.svg?component")),
  "trash-can-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/trash-can-solid.svg?component")),
  "triangle-exclamation-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/triangle-exclamation-solid.svg?component")),
  "user-large-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/user-large-solid.svg?component")),
  "utensils-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/utensils-solid.svg?component")),
  "vacuum-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/vacuum-solid.svg?component")),
  "wand-magic-sparkles-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/wand-magic-sparkles-solid.svg?component")),
  "wifi-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/wifi-solid.svg?component")),
  "xmark-solid": defineAsyncComponent(() => import("@/assets/svg/font-awesome/xmark-solid.svg?component")),
};

export type FontAwesomeIconTypes = keyof typeof icons;
</script>
