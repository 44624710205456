import type { AccommodationPhoto } from "@/contexts/accommodation-photos/models/AccommodationPhoto";
import type GetAccommodationPhotosResponse from "@/clients/accommodation-photos/GetAccommodationPhotos/response/GetAccommodationPhotosResponse";

const fromGetAccommodationPhotosResponse = (response: GetAccommodationPhotosResponse): AccommodationPhoto[] => {
  return response.data.map((item) => ({
    id: item.id,
    accommodation_id: item.accommodation_id,
    url: item.url,
    name: item.name,
    extension: item.extension,
    size: item.size,
    order: item.order,
    created_at: new Date(item.created_at),
    updated_at: new Date(item.updated_at),
  }));
};

export default {
  fromGetAccommodationPhotosResponse,
};
