import type { UpdateAccommodationsPrices } from "@/contexts/accommodations/models/UpdateAccommodationsPrices";
import type { paths } from "@/libs/api/schema";
import useDayjs from "@/contexts/shared/composables/useDayjs";

const toUpdateAccommodationPricesFactory = (prices: UpdateAccommodationsPrices): paths["/accommodations/prices"]["post"]["requestBody"]["content"]["application/json"] => {
  const dayjs = useDayjs();

  return {
    accommodations: prices.accommodations.filter((accommodation) => !!accommodation).map((accommodation) => accommodation as string),
    from: dayjs(prices.date.from).startOf("day").format("YYYY-MM-DD"),
    to: dayjs(prices.date.to).endOf("day").format("YYYY-MM-DD"),
    minimum_stay: prices.minimum_stay,
    weekday: prices.weekday,
    prices: prices.prices,
  };
};

export default {
  toUpdateAccommodationPricesFactory,
};
