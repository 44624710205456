import type { AccommodationExtraNameType } from "@/contexts/accommodations/models/AccommodationExtra";

class AccommodationExtraNameProvider {
  get(): AccommodationExtraNameType[] {
    return [
      "final_cleaning",
      "extra_cleaning",
      "refundable_security_deposit",
      "tax",
      "tourist_tax",
      "booking_fee",
      "pet",
      "travel_insurance",
      "damage_fund",
      "towel",
      "bedlinen",
      "towel_bedlinen_package",
      "kitchen_towels",
      "baby_cot",
      "baby_highchair",
      "baby_buggy",
      "heating",
      "air_conditioning",
      "firewood",
      "electricity",
      "water",
      "sauna",
      "pool",
      "washing_machine",
      "beach_services",
      "internet",
      "parking",
      "airport_shuttle",
      "breakfast",
      "service_costs",
      "late_check_in",
      "late_check_out",
      "early_check_in",
      "early_check_out",
    ];
  }
}

export default new AccommodationExtraNameProvider();
