import type { MaybeElement } from "@vueuse/core";
import { useElementVisibility } from "@vueuse/core";
import type { Ref } from "vue";

export default function useVisibility(target: Ref<MaybeElement | InstanceType<any>>, onMounted: () => void) {
  const viewed = shallowRef(false);
  const isVisible = useElementVisibility(target);

  watch(
    () => isVisible.value,
    (value) => {
      if (!viewed.value && value) {
        viewed.value = true;
        onMounted();
      }
    },
  );

  const reset = () => {
    viewed.value = isVisible.value ?? false;
    if (isVisible.value) {
      onMounted();
    }
  };

  return reactive({
    reset,
    viewed,
    isVisible,
  });
}
