import type { Chart, TooltipModel } from "chart.js";

export type TooltipData = { values: { value: number; color: string; label: string }[]; title: string } | undefined;

export default function useChart() {
  const tooltipData = ref<TooltipData>();
  const tooltipPosition = ref<{
    getBoundingClientRect: () => { width: number; height: number; x: number; y: number; top: number; left: number; right: number; bottom: number };
  }>();

  const colorVar = (arg: string): string => {
    return `rgb(${getComputedStyle(document.documentElement).getPropertyValue(arg)})`;
  };
  const createTooltip = (args: { chart: Chart; tooltip: TooltipModel<"line">; replay: boolean }) => {
    if (args.tooltip.opacity === 0) {
      tooltipData.value = undefined;
      return;
    }
    tooltipData.value = {
      title: String(args.tooltip.title),
      values: args.tooltip.dataPoints.map((item) => ({
        color: String(item.dataset.backgroundColor),
        label: String(item.dataset.label),
        value: Number(item.raw),
      })),
    };
    const position = args.chart.canvas.getBoundingClientRect();
    tooltipPosition.value = {
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x: 0,
          y: 0,
          top: position.top + args.tooltip.caretY,
          left: position.left + args.tooltip.caretX,
          right: 0,
          bottom: 0,
        };
      },
    };
  };

  return {
    colorVar,
    tooltipData,
    createTooltip,
    tooltipPosition,
  };
}
