<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodations.update.address") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <SectionGroup>
      <form
        class="space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0"
        @submit.prevent="submit"
      >
        <AccommodationsMapForm
          ref="accommodationMapForm"
          v-model="input"
        />
        <section>
          <AccommodationsAddressForm
            v-model="input"
            :errors="updateAccommodationAddressRequest.errors"
          />
        </section>
      </form>
    </SectionGroup>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import type { Accommodation, UnsavedAccommodation } from "@/contexts/accommodations/models/Accommodation";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import AccommodationsMapForm from "@/contexts/accommodations/ui/components/AccommodationsMapForm.vue";
import AccommodationsAddressForm from "@/contexts/accommodations/ui/components/AccommodationsAddressForm.vue";
import SectionGroup from "@/contexts/shared/ui/components/section/SectionGroup.vue";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import { cloneDeep } from "lodash-es";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const emit = defineEmits<{
  close: [];
  updated: [value: Accommodation];
}>();

const { updateAccommodationAddress, empty } = useAccommodations();
const { successNotification, errorNotification } = useNotification();

onMounted(() => {
  input.value = cloneDeep(props.accommodation);
  setTimeout(() => accommodationMapForm.value?.loaded(), 550);
});

const input = ref<UnsavedAccommodation>(empty);
const accommodationMapForm = ref<InstanceType<typeof AccommodationsMapForm>>();

const updateAccommodationAddressRequest = useRequest({
  promise: () => updateAccommodationAddress(props.accommodation.id, input.value),
  onSuccess: (response) => {
    successNotification("accommodations.update.success");
    emit("updated", response);
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accommodations.update.error");
    }
  },
});

const submit = async () => {
  await updateAccommodationAddressRequest.fetch();
};
</script>
