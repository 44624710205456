import type SearchAccountServicesResponse from "@/clients/account-services/SearchAccountServices/response/SearchAccountServicesResponse";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import type { AccountService, AccountServiceType, AccountServiceUnit } from "@/contexts/accounts/models/AccountService";
import type { SearchAccountServicesCriteria } from "@/contexts/accounts/models/SearchAccountServicesCriteria";
import type SearchAccountServicesRequest from "@/clients/account-services/SearchAccountServices/request/SearchAccountServicesRequest";

const toSearchAccountServicesRequest = (criteria: SearchAccountServicesCriteria, cursor?: string): SearchAccountServicesRequest => {
  return {
    cursor: cursor ?? undefined,
    order_by: criteria.order_by ?? undefined,
    sort: criteria.sort ?? undefined,
    per_page: criteria.per_page ?? undefined,
  };
};

const fromSearchAccountServicesResponse = (response: SearchAccountServicesResponse): Pagination<AccountService> => {
  return {
    data: response.data.map((item) => ({
      id: item.id,
      name: item.name,
      price: item.price,
      unit: item.unit as AccountServiceUnit,
      type: item.type as AccountServiceType,
      created_at: new Date(item.created_at),
      updated_at: new Date(item.updated_at),
    })),
    meta: {
      next_cursor: response.meta.next_cursor,
      path: response.meta.path,
      per_page: response.meta.per_page,
      prev_cursor: response.meta.prev_cursor,
    },
    total: response.total,
  };
};

export default {
  toSearchAccountServicesRequest,
  fromSearchAccountServicesResponse,
};
