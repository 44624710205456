import type { Booking } from "@/contexts/bookings/models/Booking";
import { bookingFromResponse } from "@/contexts/bookings/utils/bookingFromResponse";
import type { paths } from "@/libs/api/schema";

const fromShowBookingResponse = (response: paths["/bookings/{booking}"]["get"]["responses"][200]["content"]["application/json"] | undefined): Booking => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return bookingFromResponse(response.data);
};

export default {
  fromShowBookingResponse,
};
