import type { paths } from "@/libs/api/schema";
import useDayjs from "@/contexts/shared/composables/useDayjs";

const toUpdateAccommodationAvailabilityRequest = (from: Date | null, to: Date | null, status: "available" | "locked_by_owner" | "locked_by_manager"): paths["/accommodations/{accommodation}/availability"]["post"]["requestBody"]["content"]["application/json"] => {
  const dayjs = useDayjs();

  return {
    from: from ? dayjs(from).startOf("day").format("YYYY-MM-DD") : "",
    to: to ? dayjs(to).endOf("day").format("YYYY-MM-DD") : "",
    status: status,
  };
};

export default {
  toUpdateAccommodationAvailabilityRequest,
};
