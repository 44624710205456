<template>
  <div
    ref="dropzone"
    :class="classes"
  >
    <div
      v-if="hasSelectedFile"
      class="flex h-48 w-full flex-col items-center justify-center rounded-md text-center group-hover:opacity-10 group-hover:backdrop-sepia-0"
    >
      <FileIcon
        :icon="selectedExtension ? (selectedExtension === 'pdf' ? 'pdf' : ['csv', 'xls', 'xlsx'].includes(selectedExtension) ? 'spreadsheet' : 'default') : 'default'"
        class="mx-auto h-12 w-12"
      />
      <div class="mt-4 w-full truncate px-2 text-sm leading-6 text-gray-800">
        {{ selectedName }}
      </div>
    </div>
    <div
      :class="{ 'hover:bg-gradient absolute top-0 hidden group-hover:flex': hasSelectedFile }"
      class="flex w-full justify-center rounded-md text-center"
    >
      <div class="m-auto mx-6 my-10 flex flex-col items-center py-0.5">
        <FontAwesomeIcon
          class="mx-auto h-12 w-12 fill-primary"
          icon="file-circle-plus-solid"
        />
        <div class="mt-4 flex text-sm leading-6 text-gray-600">
          <label
            :for="id"
            class="relative cursor-pointer font-semibold text-primary focus-within:outline-none hover:text-primary/80"
          >
            <span>{{ $t("shared.form.file.upload") }}</span>
            <input
              :id="id"
              class="sr-only"
              name="file-upload"
              :accept="allowedMimes"
              tabindex="-1"
              type="file"
              @input="changeFile"
            />
          </label>
          <p class="pl-1">
            {{ $t("shared.form.file.drag_drop") }}
          </p>
        </div>
        <p class="text-xs leading-5 text-gray-600">
          <span class="uppercase">{{ extensions }}</span> <span v-if="maxSize">{{ $t("shared.form.file.up_to") }} {{ bytesToHuman(maxSize) }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useFormatter from "@/contexts/shared/composables/useFormatter";
import type { Ref } from "vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import { useDropZone } from "@vueuse/core";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import FileIcon from "@/contexts/shared/ui/components/icon/FileIcon.vue";
import mime from "mime";

const props = defineProps<{
  modelValue: File | undefined;
  allowedExtensions: string[];
  maxSize?: number;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: File];
}>();

const id = inject<string>("id", "");
const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
const hasErrors = computed<boolean>(() => (errors?.value?.length ?? 0) > 0);
const allowedMimes = computed(() => props.allowedExtensions.map((extension) => mime.getType(extension)).join(", "));

const { bytesToHuman } = useFormatter();
const { errorNotification } = useNotification();

const dropzone = ref<HTMLElement>();
const { isOverDropZone } = useDropZone(dropzone, (files: File[] | null) => {
  if (files && files?.length) {
    selectFile([...Array.from(files)][0]);
  }
});
const extensions = computed(() => props.allowedExtensions.join(", "));
const hasSelectedFile = computed(() => props.modelValue !== undefined);
const selectedName = computed(() => props.modelValue?.name);
const selectedExtension = computed(() => {
  if (!props.modelValue) {
    return;
  }
  const filename = props.modelValue.name.split(".");
  return filename[filename.length - 1];
});
const classes = computed(() => ({
  "group relative rounded-md border border-dashed": true,
  "bg-gradient": isOverDropZone.value,
  "border-gray-900/25": !hasErrors.value,
  "border-red-500": hasErrors.value,
}));

const selectFile = (file: File) => {
  const filename = file.name.split(".");
  if (!props.allowedExtensions.includes(filename[filename.length - 1])) {
    errorNotification("shared.form.file.invalid");
    return;
  }
  if (props.maxSize && file.size >= props.maxSize) {
    errorNotification("shared.form.file.max_size");
    return;
  }
  emit("update:modelValue", file);
};

const changeFile = (event: Event) => {
  if ((<FileList>(<HTMLInputElement>event.target).files).length) {
    selectFile([...Array.from(<FileList>(<HTMLInputElement>event.target).files)][0]);
  }
};
</script>
