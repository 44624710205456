<template>
  <div class="relative h-64 w-full rounded border border-gray-200 p-1">
    <div class="absolute right-0 top-0 mr-1.5 mt-1.5">
      <ButtonWithIcon
        icon="eraser-solid"
        class="btn-white"
        @click="clear"
      >
        {{ $t("shared.form.signature.clear") }}
      </ButtonWithIcon>
    </div>
    <canvas
      ref="canvas"
      :style="{ width: '100%', height: '100%' }"
    />
  </div>
</template>

<script lang="ts" setup>
import SignaturePad from "signature_pad";
import { useEventListener } from "@vueuse/core";
import type { Ref } from "vue";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";

const pad = ref<SignaturePad>();
const canvas = ref<HTMLCanvasElement>() as Ref<HTMLCanvasElement>;

onMounted(() => {
  pad.value = new SignaturePad(canvas.value);
  resizeCanvas(canvas.value);
});

useEventListener(document, "resize", () => resizeCanvas(canvas.value));

const resizeCanvas = (e: HTMLCanvasElement) => {
  let ratio = Math.max(window.devicePixelRatio || 1, 1);
  e.width = e.offsetWidth * ratio;
  e.height = e.offsetHeight * ratio;
  e.getContext("2d")?.scale(ratio, ratio);
};

const clear = () => {
  pad.value?.clear();
};
const save = (): string | undefined => {
  if (!pad.value?.isEmpty()) {
    return pad.value?.toDataURL();
  }
};
const isEmpty = (): boolean => {
  return pad.value?.isEmpty() ?? true;
};

defineExpose({ isEmpty, clear, save });
</script>
