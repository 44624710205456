import type GetAccommodationAttachmentsResponse from "@/clients/accommodation-attachments/GetAccommodationAttachments/response/GetAccommodationAttachmentsResponse";
import axios from "@/libs/axios";

export default async function getAccommodationAttachments(accommodationId: string): Promise<GetAccommodationAttachmentsResponse> {
  try {
    const { data } = await axios.get<GetAccommodationAttachmentsResponse>(`/accommodations/${accommodationId}/attachments`);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
