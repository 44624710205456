import type ShowCleaningGroupResponse from "@/clients/cleaning-groups/ShowCleaningGroup/response/ShowCleaningGroupResponse";
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";

const fromShowCleaningGroupResponse = (response: ShowCleaningGroupResponse): CleaningGroup => {
  return {
    id: response.data.id,
    name: response.data.name,
    created_at: new Date(response.data.created_at),
    updated_at: new Date(response.data.updated_at),
    archived_at: response.data.archived_at ? new Date(response.data.archived_at) : null,
  };
};

export default {
  fromShowCleaningGroupResponse,
};
