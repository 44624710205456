import type { components } from "@/libs/api/schema";
import type { PaginationMeta } from "@/contexts/shared/models/Pagination";

export const paginationMetaFromResponse = (pagination: components["schemas"]["Pagination"]["meta"] | undefined): PaginationMeta => {
  return {
    next_cursor: pagination?.next_cursor ?? null,
    path: pagination?.path ?? null,
    per_page: pagination?.per_page ?? 25,
    prev_cursor: pagination?.prev_cursor ?? null,
  };
};
