<template>
  <div class="relative">
    <div class="absolute top-0 h-full w-full">
      <div
        ref="element"
        class="h-full w-full"
      />
    </div>
    <div
      v-if="loading"
      class="absolute top-0 z-10 flex h-full w-full bg-gray-600 bg-opacity-40"
    >
      <div class="m-auto inline-flex items-center rounded-sm bg-white bg-opacity-80 px-3 py-2">
        <FontAwesomeIcon
          class="m-auto h-5 w-5 animate-spin fill-gray-800"
          icon="spinner-third-duotone"
        />
        <div class="ml-3 text-sm text-gray-800">
          {{ $t("shared.map.loading") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { GMapsCoordinates } from "@/contexts/shared/composables/useMaps";
import useMaps from "@/contexts/shared/composables/useMaps";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

const props = defineProps<{
  withCoordinates?: boolean;
}>();

const emit = defineEmits<{
  loaded: [];
}>();

const { loader, loadMap, mapMarker } = useMaps();

const element = ref<HTMLElement>();
const map = ref<google.maps.Map>();
const loading = ref(true);
const markers: google.maps.Marker[] = [];

const load = () => {
  loader.loadCallback(() => {
    map.value = loadMap(element.value as HTMLElement);
    map.value.addListener("tilesloaded", () => {
      if (!props.withCoordinates) {
        loading.value = false;
      }
      emit("loaded");
    });
  });
};

const setZoom = (zoom: number) => {
  loader.loadCallback(() => {
    map.value?.setOptions({ zoom: zoom });
  });
};

const setCenter = (coordinates: GMapsCoordinates) => {
  loader.loadCallback(() => {
    map.value?.setCenter(coordinates);
    loading.value = false;
  });
};

const addMarker = async (coordinates: GMapsCoordinates, title?: string) => {
  loader.loadCallback(() => {
    markers.push(mapMarker(map.value, { lat: coordinates.lat, lng: coordinates.lng }, title));
  });
};

const removeAllMarkers = () => {
  markers.forEach((marker) => {
    marker.setMap(null);
    markers.splice(markers.indexOf(marker), 1);
  });
};

defineExpose({
  map,
  load,
  loader,
  setZoom,
  setCenter,
  addMarker,
  removeAllMarkers,
});
</script>
