import axios from "@/libs/axios";
import type ShowAccommodationAttachmentResponse from "@/clients/accommodation-attachments/ShowAccommodationAttachment/response/ShowAccommodationAttachmentResponse";
import { AxiosError } from "axios";
import Errors from "@/contexts/shared/models/Errors";

export default async function showAccommodationAttachment(accommodationId: string, id: string): Promise<ShowAccommodationAttachmentResponse> {
  try {
    const { data } = await axios.get<ShowAccommodationAttachmentResponse>(`/accommodations/${accommodationId}/attachments/${id}`);
    return Promise.resolve(data);
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 404) {
        return Promise.reject(Errors.NOT_FOUND);
      }
    }
    return Promise.reject(e);
  }
}
