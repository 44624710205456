import type CreateCleaningGroupRequest from "@/clients/cleaning-groups/CreateCleaningGroup/request/CreateCleaningGroupRequest";
import axios from "@/libs/axios";

export default async function updateCleaningGroup(id: string, request: CreateCleaningGroupRequest): Promise<void> {
  try {
    await axios.put(`/cleaning-groups/${id}`, request);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
