import type { paths } from "@/libs/api/schema";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import { accommodationFromResponse } from "@/contexts/accommodations/utils/accommodationFromResponse";
import type { UnsavedAccommodationDescription } from "@/contexts/accommodations/models/AccommodationDescription";

const toUpdateAccommodationDescriptionsRequest = (descriptions: UnsavedAccommodationDescription[]): paths["/accommodations/{accommodation}/descriptions"]["put"]["requestBody"]["content"]["application/json"] => {
  return {
    descriptions: descriptions.map((item) => ({
      id: item.id ?? null,
      language: item.language,
      summary: item.summary,
      overview: item.overview,
      services: item.services,
      additional_features: item.additional_features,
      additional_rules: item.additional_rules,
    })),
  };
};

const fromUpdateAccommodationDescriptionsResponse = (response: paths["/accommodations/{accommodation}/descriptions"]["put"]["responses"][200]["content"]["application/json"] | undefined): Accommodation => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return accommodationFromResponse(response.data);
};

export default {
  toUpdateAccommodationDescriptionsRequest,
  fromUpdateAccommodationDescriptionsResponse,
};
