<template>
  <input
    :id="id"
    :class="classes"
    :data-testid="id"
    :disabled="disabled"
    :name="name"
    :placeholder="placeholder"
    :type="type"
    :value="modelValue"
    class="block w-full text-sm shadow-sm disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400"
    @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
  />
</template>

<script lang="ts" setup>
import type { Ref } from "vue";

export type Props = {
  type?: "text" | "email" | "password" | "number" | "tel" | "url";
  name: string;
  modelValue: string | number | null | undefined;
  placeholder?: string;
  disabled?: boolean;
};

const props = withDefaults(
  defineProps<
    Props & {
      padding?: boolean;
      rounded?: boolean;
    }
  >(),
  {
    type: "text",
    padding: true,
    rounded: true,
    placeholder: undefined,
  },
);

defineEmits<{
  "update:modelValue": [value: string | number | null | undefined];
}>();

const id = inject<string>("id", "");
const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
const hasErrors = computed<boolean>(() => (errors?.value?.length ?? 0) > 0);

const classes = computed(() => ({
  "px-2 py-1": props.padding,
  rounded: props.rounded,
  "border-gray-300 focus:border-primary focus:ring-primary": !hasErrors.value,
  "border-red-500 focus:border-red-500 focus:ring-red-500 text-red-900 placeholder-red-300": hasErrors.value,
}));
</script>
