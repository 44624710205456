<template>
  <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
    <div class="grid gap-4 md:col-span-2 md:grid-cols-2">
      <FormGroup :errors="errors.check_in">
        <FormLabel>{{ $t("bookings.props.check_in") }}</FormLabel>
        <FormInput>
          <FormDatePicker
            v-model="input.check_in"
            :min-date="new Date()"
            @update:model-value="input.check_out = null"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="errors.check_out">
        <FormLabel>{{ $t("bookings.props.check_out") }}</FormLabel>
        <FormInput>
          <FormDatePicker
            v-model="input.check_out"
            :disabled="!input.check_in"
            :min-date="mixCheckOutDate"
          />
        </FormInput>
      </FormGroup>
      <div class="rounded bg-gray-100 p-3 text-sm md:col-span-2">
        <span class="mr-1.5 text-gray-700">{{ $t("bookings.total.title") }}</span>
        <span class="font-semibold text-gray-800">{{ $t("bookings.total.nights", totalNights) }}</span>
      </div>
    </div>
    <FormGroup :errors="errors.adults">
      <FormLabel>{{ $t("bookings.props.adults") }}</FormLabel>
      <FormInput>
        <FormInteger
          v-model="input.adults"
          :min="1"
          name="adults"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.children">
      <FormLabel>{{ $t("bookings.props.children") }}</FormLabel>
      <FormInput>
        <FormInteger
          v-model="input.children"
          :min="0"
          name="children"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.language">
      <FormLabel>{{ $t("bookings.props.language") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="input.language"
          :options="useLanguage().languageOptions"
          name="language"
        />
      </FormInput>
    </FormGroup>
  </div>
</template>

<script lang="ts" setup>
import type { UnsavedBooking } from "@/contexts/bookings/models/Booking";
import FormDatePicker from "@/contexts/shared/ui/components/form/FormDatePicker.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import useLanguage from "@/contexts/shared/composables/useLanguage";

const props = defineProps<{
  modelValue: UnsavedBooking;
  errors: RequestErrors<UnsavedBooking>;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: UnsavedBooking];
}>();

const input = computed<UnsavedBooking>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const dayjs = useDayjs();

const mixCheckOutDate = computed<Date>(() => (input.value.check_in ? dayjs(input.value.check_in).add(1, "day").toDate() : new Date()));
const totalNights = computed<number>(() => (input.value.check_in && input.value.check_out ? dayjs(input.value.check_out).diff(dayjs(input.value.check_in), "days") : 0));
</script>
