import type LoginRequest from "@/clients/auth/Login/request/LoginRequest";

const toLoginRequest = (email: string, password: string): LoginRequest => {
  return {
    email: email,
    password: password,
  };
};

export default {
  toLoginRequest,
};
