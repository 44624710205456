import type { paths } from "@/libs/api/schema";

const fromPdfAccommodationContractFactory = (response: paths["/accommodations/{accommodation}/contracts/{contract}/pdf"]["get"]["responses"][200]["content"]["application/json"] | undefined): string => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return response.data;
};

export default {
  fromPdfAccommodationContractFactory,
};
