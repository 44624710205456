<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodations.publish.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form
      class="space-y-3.5"
      @submit.prevent="submit"
    >
      <FormGroup
        id="accommodations.publish.create.provider"
        :errors="createAccommodationChannelRequest.errors.provider"
      >
        <FormLabel>{{ $t("accommodations.publish.props.channel") }}</FormLabel>
        <FormChoice
          v-model="form.channel"
          :grid="3"
          :options="channelOptions"
        >
          <template #default="{ option }">
            <ProviderIcon
              :icon="option.value as ProviderIconTypes"
              class="mx-auto mb-2 size-10"
            />
            <span class="text-sm font-medium text-gray-700">{{ $t(option.label) }}</span>
          </template>
        </FormChoice>
      </FormGroup>
      <div class="rounded-md border border-gray-200 px-3 py-2">
        <h4 class="mb-2 font-semibold text-gray-800">{{ $t("accommodations.publish.checks.title") }}</h4>
        <template v-if="isEmpty(showAccommodationChannelRequest.data)">
          <div class="py-0.5">
            <div class="flex h-56 w-full items-center">
              <div class="m-auto max-w-md">
                <div class="flex">
                  <div class="mb-4 w-auto rounded bg-gray-100 p-2">
                    <FontAwesomeIcon
                      icon="list-check-solid"
                      class="size-5 fill-primary"
                    />
                  </div>
                </div>
                <div class="mb-1.5 text-lg font-semibold text-gray-700">
                  {{ $t("accommodations.publish.checks.info.title") }}
                </div>
                <div class="text-balance text-xs text-gray-500">
                  {{ $t("accommodations.publish.checks.info.description") }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="showAccommodationChannelRequest.hasErrors">
          <div>// ERRORS</div>
        </template>
        <template v-else>
          <div class="grid grid-cols-1 gap-3 md:grid-cols-2">
            <template
              v-for="(passed, check) in showAccommodationChannelRequest.data"
              :key="check"
            >
              <div>
                <div class="flex items-center space-x-2">
                  <div
                    :data-passed="passed"
                    class="mt-0.5 size-4 flex-none rounded-full data-[passed=false]:bg-red-500 data-[passed=true]:bg-green-500"
                  />
                  <div class="truncate align-middle text-sm font-medium text-gray-800">{{ $t(`accommodations.publish.checks.requirements.${check}.title`) }}</div>
                </div>
                <div class="ml-6 text-xs text-gray-600">{{ $t(`accommodations.publish.checks.requirements.${check}.description`) }}</div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <input
        class="hidden"
        type="submit"
      />
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="!passRequirements || createAccommodationChannelRequest.isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script setup lang="ts">
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import useRequest from "@/contexts/shared/composables/useRequest";
import type { AccommodationChannelType } from "@/contexts/accommodations/models/AccommodationChannel";
import useNotification from "@/contexts/shared/composables/useNotification";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { Choice } from "@/contexts/shared/models/Choice";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import ProviderIcon, { type ProviderIconTypes } from "@/contexts/shared/ui/components/icon/ProviderIcon.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormChoice from "@/contexts/shared/ui/components/form/FormChoice.vue";
import { isEmpty } from "lodash-es";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const emit = defineEmits<{
  close: [];
  published: [];
}>();

const { successNotification, errorNotification } = useNotification();
const { createAccommodationChannel, showAccommodationChannel, accommodationChannelsOptions } = useAccommodations();

const disabledChannels = computed<AccommodationChannelType[]>(() => ["holidu"]);
const channelOptions = computed<Choice<AccommodationChannelType>[]>(() =>
  accommodationChannelsOptions.map((item) => ({
    value: item.value as AccommodationChannelType,
    label: item.label,
    disabled: !!(disabledChannels.value.includes(item.value as AccommodationChannelType) || props.accommodation.channels?.find((channel) => channel.channel === (item.value as AccommodationChannelType))),
  })),
);
const passRequirements = computed<boolean>(() => (showAccommodationChannelRequest?.data && Object.values(showAccommodationChannelRequest?.data ?? {}).every((value) => value)) ?? false);

const form = ref<{ channel: AccommodationChannelType | undefined }>({ channel: undefined });

watch(
  () => form.value.channel,
  () => {
    showAccommodationChannelRequest.fetch();
  },
);

const createAccommodationChannelRequest = useRequest({
  promise: () => createAccommodationChannel(props.accommodation.id, form.value.channel!),
  onSuccess: () => {
    emit("published");
    successNotification("accommodations.channels.create.success");
  },
  onFailure: () => {
    errorNotification("accommodations.channels.create.error");
  },
});
const showAccommodationChannelRequest = useRequest({
  promise: () => showAccommodationChannel(props.accommodation.id, form.value.channel!),
  onFailure: () => {
    errorNotification("shared.fetch.error");
  },
});

const submit = () => {
  if (passRequirements.value) {
    createAccommodationChannelRequest.fetch();
  }
};
</script>
