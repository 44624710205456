<template>
  <div
    :class="{ 'lg:border-l': week > 26 }"
    class="flex items-stretch divide-x divide-gray-200 border-b border-gray-200 hover:bg-gray-50"
  >
    <div class="flex w-8 flex-none px-1 py-1.5 text-center font-medium tabular-nums text-gray-800">
      <div class="m-auto">{{ week }}</div>
    </div>
    <div class="hidden flex-none p-1.5 text-sm tabular-nums text-gray-600 sm:flex">
      <div class="my-auto">{{ `${start} - ${end}` }}</div>
    </div>
    <div class="flex grow justify-end p-1.5">
      <div class="my-auto flex items-center space-x-5">
        <template v-if="season">
          <div class="flex items-center space-x-2.5">
            <Badge :class="accountSeasonTypeBadge">
              {{ $t(`accounts.seasons.type.${season.type}`) }}
            </Badge>
            <div class="flex w-12 items-center">
              <Badge class="badge-gray mx-auto tabular-nums">
                {{ `${Number(season.amount).toFixed(0)}%` }}
              </Badge>
            </div>
          </div>
        </template>
        <Popover alignment="end">
          <template #button="{ toggle }">
            <ButtonWithIcon
              class="btn-white"
              icon="pen-solid"
              @click="toggle"
            />
          </template>
          <template #default="{ close }">
            <div class="flex flex-col space-y-3">
              <div class="tabular-nums text-gray-600">{{ `${start} - ${end}` }}</div>
              <AccountSeasonsForm
                :season="season"
                :week="week"
                :year="year"
                @submit="updateAccountSeasonRequest.fetch($event).then(() => close())"
              />
            </div>
          </template>
        </Popover>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { AccountSeason, UnsavedAccountSeason } from "@/contexts/accounts/models/AccountSeason";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import Popover from "@/contexts/shared/ui/components/floating/Popover.vue";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";
import AccountSeasonsForm from "@/contexts/accounts/ui/components/AccountSeasonsForm.vue";
import useAccountSeasons from "@/contexts/accounts/composables/useAccountSeasons";
import useRequest from "@/contexts/shared/composables/useRequest";
import useNotification from "@/contexts/shared/composables/useNotification";

const props = defineProps<{
  year: number;
  week: number;
  season: AccountSeason | null;
}>();

const emit = defineEmits<{
  updated: [value: AccountSeason];
}>();

const dayjs = useDayjs();
const { updateAccountSeason } = useAccountSeasons();
const { successNotification, errorNotification } = useNotification();

const start = computed(() => dayjs().year(props.year).week(props.week).startOf("week").format("L"));
const end = computed(() => dayjs().year(props.year).week(props.week).endOf("week").format("L"));

const accountSeasonTypeBadge = computed<string | undefined>(() => {
  switch (props.season?.type) {
    case "f":
      return "bg-gray-100 text-gray-700";
    case "e":
      return "bg-blue-100 text-blue-700";
    case "d":
      return "bg-yellow-100 text-yellow-700";
    case "c":
      return "bg-orange-100 text-orange-700";
    case "b":
      return "bg-red-100 text-red-700";
    case "a":
      return "bg-fuchsia-100 text-fuchsia-700";
  }
});

const updateAccountSeasonRequest = useRequest<AccountSeason>({
  promise: (value: UnsavedAccountSeason) => updateAccountSeason(value),
  onSuccess: (response) => {
    emit("updated", response);
    successNotification("accounts.seasons.update.success");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accounts.seasons.update.error");
    }
  },
});
</script>
