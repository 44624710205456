<template>
  <nav
    class="mt-2.5 flex overflow-auto"
    aria-label="Breadcrumb"
  >
    <ol
      role="list"
      class="flex items-center space-x-2.5"
    >
      <slot />
    </ol>
  </nav>
</template>
