<template>
  <button
    :disabled="disabled"
    type="button"
    @click.prevent="$emit('click')"
  >
    <div class="flex items-center space-x-1.5">
      <FontAwesomeIcon
        :icon="icon"
        class="h-3.5 w-3.5 fill-current"
      />
      <div v-if="slots?.default !== undefined">
        <slot />
      </div>
    </div>
  </button>
</template>

<script lang="ts" setup>
import type { FontAwesomeIconTypes } from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

defineProps<{
  icon: FontAwesomeIconTypes;
  disabled?: boolean;
}>();

defineEmits<{
  click: [];
}>();

const slots = defineSlots<{
  default(props: object): void;
}>();
</script>
