<template>
  <component :is="icons[icon]" />
</template>

<script lang="ts" setup>
defineProps<{
  icon: ProviderIconTypes;
}>();

const icons = {
  google: defineAsyncComponent(() => import("@/assets/svg/providers/google.svg?component")),
  holidu: defineAsyncComponent(() => import("@/assets/svg/providers/holidu.svg?component")),
  icnea: defineAsyncComponent(() => import("@/assets/svg/providers/icnea.svg?component")),
  stripe: defineAsyncComponent(() => import("@/assets/svg/providers/stripe.svg?component")),
  storefront: defineAsyncComponent(() => import("@/assets/svg/providers/storefront.svg?component")),
  club_villamar: defineAsyncComponent(() => import("@/assets/svg/providers/club-villamar.svg?component")),
};

export type ProviderIconTypes = keyof typeof icons;
</script>
