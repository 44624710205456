import type FacilitiesAccommodationResponse from "@/clients/accommodations/FacilitiesAccommodation/response/FacilitiesAccommodationResponse";
import type { AccommodationFacilities } from "@/contexts/accommodations/models/AccommodationFacilities";

const fromFacilitiesAccommodationResponse = (response: FacilitiesAccommodationResponse): AccommodationFacilities => {
  return response.data;
};

export default {
  fromFacilitiesAccommodationResponse,
};
