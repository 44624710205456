import type UpdateAccountResponse from "@/clients/accounts/UpdateAccount/response/UpdateAccountResponse";
import axios from "@/libs/axios";
import type UpdateAccountRequest from "@/clients/accounts/UpdateAccount/request/UpdateAccountRequest";

export default async function updateAccount(id: string, request: UpdateAccountRequest): Promise<UpdateAccountResponse> {
  const formData = new FormData();
  formData.append("_method", "PUT");
  formData.append("name", request.name);
  formData.append("email", request.email);
  if (request.icon) {
    formData.append("icon", request.icon);
  }
  if (request.logo) {
    formData.append("logo", request.logo);
  }
  formData.append("primary_color", request.primary_color ?? "");
  formData.append("secondary_color", request.secondary_color ?? "");
  formData.append("street", request.street ?? "");
  formData.append("city", request.city ?? "");
  formData.append("postal_code", request.postal_code ?? "");
  formData.append("country_code", request.country_code ?? "");
  try {
    const { data } = await axios.post<UpdateAccountResponse>(`/accounts/${id}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
