import type { Pagination } from "@/contexts/shared/models/Pagination";
import type { BookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import type { SearchBookingCleaningsCriteria } from "@/contexts/booking-cleanings/models/SearchBookingCleaningsCriteria";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import type { paths } from "@/libs/api/schema";
import { paginationMetaFromResponse } from "@/contexts/shared/utils/paginationMetaFromResponse";
import { bookingCleaningFromResponse } from "@/contexts/booking-cleanings/utils/bookingCleaningFromResponse";

const toSearchBookingCleaningsRequest = (criteria: SearchBookingCleaningsCriteria, cursor?: string): paths["/booking-cleanings"]["get"]["parameters"]["query"] => {
  const dayjs = useDayjs();

  return {
    "datetime[from]": criteria.datetime?.from ? dayjs(criteria.datetime.from).startOf("day").format("YYYY-MM-DD") : undefined,
    "datetime[to]": criteria.datetime?.to ? dayjs(criteria.datetime.to).endOf("day").format("YYYY-MM-DD") : undefined,
    "check_in[from]": criteria.check_in?.from ? dayjs(criteria.check_in.from).startOf("day").format("YYYY-MM-DD") : undefined,
    "check_in[to]": criteria.check_in?.to ? dayjs(criteria.check_in.to).endOf("day").format("YYYY-MM-DD") : undefined,
    "check_out[from]": criteria.check_out?.from ? dayjs(criteria.check_out.from).startOf("day").format("YYYY-MM-DD") : undefined,
    "check_out[to]": criteria.check_out?.to ? dayjs(criteria.check_out.to).endOf("day").format("YYYY-MM-DD") : undefined,
    accommodation_id: criteria.accommodation_id ?? undefined,
    cleaning_group_id: criteria.cleaning_group_id ?? undefined,
    "type[]": criteria["type[]"] ?? undefined,
    order_by: criteria.order_by ?? undefined,
    sort: criteria.sort ?? undefined,
    per_page: criteria.per_page ?? undefined,
    cursor: cursor ?? undefined,
  };
};

const fromSearchBookingCleaningsResponse = (response: paths["/booking-cleanings"]["get"]["responses"]["200"]["content"]["application/json"] | undefined, headers: Headers): Pagination<BookingCleaning> => {
  return {
    data: response?.data?.map((item) => bookingCleaningFromResponse(item)) ?? [],
    meta: paginationMetaFromResponse(response?.meta),
    total: Number(headers.get("x-total-count") ?? 0),
  };
};

export default {
  toSearchBookingCleaningsRequest,
  fromSearchBookingCleaningsResponse,
};
