<template>
  <ConfirmDeleteModal
    :count="photos.length"
    :is-loading="deleteAttachmentRequest.isLoading"
    description="accommodation_photos.delete.description"
    title="accommodation_photos.delete.title"
    @close="$emit('close')"
    @confirm="deleteAttachmentRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import ConfirmDeleteModal from "@/contexts/shared/ui/modals/ConfirmDeleteModal.vue";
import type { AccommodationPhoto } from "@/contexts/accommodation-photos/models/AccommodationPhoto";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import useAccommodationPhotos from "@/contexts/accommodation-photos/composables/useAccommodationPhotos";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";

const props = defineProps<{
  accommodation: Accommodation;
  photos: AccommodationPhoto[];
}>();

const emit = defineEmits<{
  close: [];
  deleted: [value: AccommodationPhoto[]];
}>();

const { getAccommodationPhotos, deleteAccommodationPhoto } = useAccommodationPhotos();
const { successNotification, errorNotification } = useNotification();

const deleteAttachmentRequest = useRequest({
  keepLoading: true,
  promise: () => Promise.allSettled(props.photos.map((item) => deleteAccommodationPhoto(item.accommodation_id, item.id))),
  onSuccess: async () => {
    const photos = await getAccommodationPhotos(props.accommodation.id);
    successNotification("accommodation_photos.delete.success");
    emit("deleted", photos);
    emit("close");
  },
  onFailure: () => {
    errorNotification("accommodation_photos.delete.error");
  },
});
</script>
