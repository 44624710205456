import type CreateBookingGuestRequest from "@/clients/booking-guests/CreateBookingGuest/request/CreateBookingGuestRequest";
import type CreateBookingGuestResponse from "@/clients/booking-guests/CreateBookingGuest/response/CreateBookingGuestResponse";
import axios from "@/libs/axios";

export default async function createBookingGuest(request: CreateBookingGuestRequest): Promise<CreateBookingGuestResponse> {
  try {
    const { data } = await axios.post<CreateBookingGuestResponse>("/booking-guests", request);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
