import axios from "@/libs/axios";
import { pickBy } from "lodash-es";
import type CreateOwnerRequest from "@/clients/owners/CreateOwner/request/CreateOwnerRequest";
import type CreateOwnerResponse from "@/clients/owners/CreateOwner/response/CreateOwnerResponse";

export default async function createOwner(request: CreateOwnerRequest): Promise<CreateOwnerResponse> {
  try {
    const { data } = await axios.post<CreateOwnerResponse>("/owners", pickBy(request));
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
