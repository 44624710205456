import getAccountIntegrations from "@/clients/account-integrations/GetAccountIntegrations/GetAccountIntegrations";
import createAccountIntegration from "@/clients/account-integrations/CreateAccountIntegration/CreateAccountIntegration";
import disableAccountIntegration from "@/clients/account-integrations/DisableAccountIntegration/DisableAccountIntegration";
import enableAccountIntegration from "@/clients/account-integrations/EnableAccountIntegration/EnableAccountIntegration";
import updateAccountIntegration from "@/clients/account-integrations/UpdateAccountIntegration/UpdateAccountIntegration";

export default {
  getAccountIntegrations,
  createAccountIntegration,
  disableAccountIntegration,
  enableAccountIntegration,
  updateAccountIntegration,
};
