import type { App, ComputedRef, ShallowReactive } from "vue";
import useAuth from "@/contexts/auth/composables/useAuth";
import type { User } from "@/contexts/profile/models/User";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $auth: ShallowReactive<{
      user: ComputedRef<User | undefined>;
    }>;
  }
}

export default {
  install: (app: App) => {
    const { authenticatedUser, authenticatedAccount } = useAuth();
    app.config.globalProperties.$auth = shallowReactive({
      user: authenticatedUser,
      isAdmin: computed<boolean>(() => authenticatedAccount.value?.account_user_type === "admin"),
      isManager: computed<boolean>(() => authenticatedAccount.value?.account_user_type === "manager"),
    });
  },
};
