<template>
  <component :is="icons[icon]" />
</template>

<script lang="ts" setup>
defineProps<{
  icon: BrandIconTypes;
}>();

const icons = {
  icon: defineAsyncComponent(() => import("@/assets/svg/brand/icon.svg?component")),
};

export type BrandIconTypes = keyof typeof icons;
</script>
