<template>
  <div class="space-y-4 lg:col-span-2 lg:grid lg:grid-cols-3 lg:gap-4 lg:space-y-0">
    <div class="space-y-4 lg:col-span-3 lg:grid lg:grid-cols-3 lg:gap-4 lg:space-y-0">
      <FormGroup :errors="errors.acronym">
        <FormLabel>{{ $t("accommodations.props.acronym") }}</FormLabel>
        <FormInput>
          <FormText
            v-model="input.acronym"
            name="acronym"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="errors.name">
        <FormLabel>{{ $t("accommodations.props.name") }}</FormLabel>
        <FormInput>
          <FormText
            v-model="input.name"
            name="name"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="errors.type">
        <FormLabel>{{ $t("accommodations.props.type") }}</FormLabel>
        <FormInput>
          <FormSelect
            v-model="input.type"
            name="type"
            :options="accommodationTypeOptions"
          />
        </FormInput>
      </FormGroup>
    </div>
    <div class="space-y-4 lg:col-span-3 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0">
      <FormGroup :errors="errors.cleaning_group_id">
        <FormLabel>{{ $t("accommodations.props.cleaning_group") }}</FormLabel>
        <FormInput>
          <CleaningGroupsSelect v-model="input.cleaning_group_id" />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="errors.owner_id">
        <FormLabel>{{ $t("accommodations.props.owner") }}</FormLabel>
        <FormInput>
          <OwnersSelect v-model="input.owner_id" />
        </FormInput>
      </FormGroup>
    </div>
    <div class="space-y-4 lg:col-span-3 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0">
      <FormGroup :errors="errors.registration_code">
        <FormLabel>{{ $t("accommodations.props.registration_code") }}</FormLabel>
        <FormInput>
          <FormText
            v-model="input.registration_code"
            name="registration_code"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="errors.land_registration_code">
        <FormLabel>{{ $t("accommodations.props.land_registration_code") }}</FormLabel>
        <FormInput>
          <FormText
            v-model="input.land_registration_code"
            name="land_registration_code"
          />
        </FormInput>
      </FormGroup>
    </div>
    <FormGroup :errors="errors.indoor_area">
      <FormLabel>{{ $t("accommodations.props.indoor_area") }}</FormLabel>
      <FormInput>
        <FormInteger
          v-model="input.indoor_area"
          :min="1"
          name="indoor_area"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.total_area">
      <FormLabel>{{ $t("accommodations.props.total_area") }}</FormLabel>
      <FormInput>
        <FormInteger
          v-model="input.total_area"
          :min="1"
          name="total_area"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.floors">
      <FormLabel>{{ $t("accommodations.props.floors") }}</FormLabel>
      <FormInput>
        <FormInteger
          v-model="input.floors"
          :min="1"
          name="floors"
        />
      </FormInput>
    </FormGroup>
    <FormGroup
      :errors="errors.notes"
      class="lg:col-span-3"
    >
      <FormLabel>{{ $t("accommodations.props.notes") }}</FormLabel>
      <FormInput>
        <FormTextarea
          v-model="input.notes"
          name="notes"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.check_in_time_from">
      <FormLabel>{{ $t("accommodations.props.check_in_time_from") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.check_in_time_from"
          name="check_in_time_from"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.check_in_time_to">
      <FormLabel>{{ $t("accommodations.props.check_in_time_to") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.check_in_time_to"
          name="check_in_time_to"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.check_out_time">
      <FormLabel>{{ $t("accommodations.props.check_out_time") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.check_out_time"
          name="check_out_time"
        />
      </FormInput>
    </FormGroup>
    <div class="space-y-4 lg:col-span-3 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0">
      <FormSwitchWithLabel
        id="featured"
        v-model="input.featured"
        :errors="errors.featured"
        name="featured"
        description="accommodations.featured.description"
      >
        {{ $t("accommodations.props.featured") }}
      </FormSwitchWithLabel>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { UnsavedAccommodation } from "@/contexts/accommodations/models/Accommodation";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import OwnersSelect from "@/contexts/owners/ui/components/OwnersSelect.vue";
import FormTextarea from "@/contexts/shared/ui/components/form/FormTextarea.vue";
import CleaningGroupsSelect from "@/contexts/cleaning-groups/ui/components/CleaningGroupsSelect.vue";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import type { Option } from "@/contexts/shared/models/Select";
import FormSwitchWithLabel from "@/contexts/shared/ui/components/form/FormSwitchWithLabel.vue";

const props = defineProps<{
  modelValue: UnsavedAccommodation;
  errors: RequestErrors<UnsavedAccommodation>;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: UnsavedAccommodation];
}>();

const { t } = useI18n();

const accommodationTypeOptions = computed<Option[]>(() => ["house", "villa", "apartment", "other"].map((item) => ({ value: item, label: t(`accommodations.type.${item}`) })));

const input = computed<UnsavedAccommodation>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>
