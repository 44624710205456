<template>
  <DashboardHeader>
    <div class="text-xl font-semibold text-gray-800">
      {{ $t("profile.title") }}
    </div>
  </DashboardHeader>
  <SectionGroup>
    <section>
      <SectionHeader>
        <SectionTitle>{{ $t("profile.manage.details.title") }}</SectionTitle>
      </SectionHeader>
      {{ authenticatedUser?.name }}
      {{ authenticatedUser?.email }}
    </section>
  </SectionGroup>
  <SectionGroup>
    <section>
      <SectionHeader>
        <SectionTitle>{{ $t("profile.manage.locale.title") }}</SectionTitle>
      </SectionHeader>
      {{ availableLocales }}
      {{ authenticatedUser?.language }}
    </section>
  </SectionGroup>
  <SectionGroup>
    <section>
      <SectionHeader>
        <SectionTitle>{{ $t("profile.manage.accounts.title") }}</SectionTitle>
      </SectionHeader>
      {{ authenticatedUser?.accounts }}
    </section>
  </SectionGroup>
</template>

<script lang="ts" setup>
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import useAuth from "@/contexts/auth/composables/useAuth";
import { useI18n } from "vue-i18n";
import SectionGroup from "@/contexts/shared/ui/components/section/SectionGroup.vue";
import SectionHeader from "@/contexts/shared/ui/components/section/SectionHeader.vue";
import SectionTitle from "@/contexts/shared/ui/components/section/SectionTitle.vue";

const { t } = useI18n();
const { availableLocales } = useI18n();
const { authenticatedUser } = useAuth();

useHead({ title: () => `${t("profile.title")} - Hussbook` });
</script>
