import axios from "@/libs/axios";
import type SearchBookingGuestsRequest from "@/clients/booking-guests/SearchBookingGuests/request/SearchBookingGuestsRequest";
import type SearchBookingGuestsResponse from "@/clients/booking-guests/SearchBookingGuests/response/SearchBookingGuestsResponse";
import { pickBy } from "lodash-es";

export default async function searchBookingGuests(request: SearchBookingGuestsRequest): Promise<SearchBookingGuestsResponse> {
  try {
    const { data, headers } = await axios.get<SearchBookingGuestsResponse>("/booking-guests", { params: pickBy(request) });
    return Promise.resolve({ ...data, total: Number(headers["x-total-count"] ?? 0) });
  } catch (e) {
    return Promise.reject(e);
  }
}
