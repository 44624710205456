import bookingGuestsClient from "@/clients/booking-guests";
import SearchBookingGuestsFactory from "@/contexts/booking-guests/composables/factories/SearchBookingGuestsFactory";
import type { SearchBookingGuestsCriteria } from "@/contexts/booking-guests/models/SearchBookingGuestsCriteria";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import type { BookingGuest, UnsavedBookingGuest } from "@/contexts/booking-guests/models/BookingGuest";
import CreateBookingGuestFactory from "@/contexts/booking-guests/composables/factories/CreateBookingGuestFactory";
import ShowBookingGuestsFactory from "@/contexts/booking-guests/composables/factories/ShowBookingGuestsFactory";

export default function useBookingGuests() {
  const searchBookingGuests = async (criteria: SearchBookingGuestsCriteria): Promise<Pagination<BookingGuest>> => {
    return SearchBookingGuestsFactory.fromSearchBookingGuestsResponse(await bookingGuestsClient.searchBookingGuests(SearchBookingGuestsFactory.toSearchBookingGuestsRequest(criteria)));
  };
  const createBookingGuest = async (guest: UnsavedBookingGuest): Promise<BookingGuest> => {
    return CreateBookingGuestFactory.fromCreateBookingGuestResponse(await bookingGuestsClient.createBookingGuest(CreateBookingGuestFactory.toCreateBookingGuestRequest(guest)));
  };
  const showBookingGuest = async (id: string): Promise<BookingGuest> => {
    return ShowBookingGuestsFactory.fromShowBookingGuestResponse(await bookingGuestsClient.showBookingGuest(id));
  };

  return {
    searchBookingGuests,
    createBookingGuest,
    showBookingGuest,
  };
}
