import type { components } from "@/libs/api/schema";
import type { BookingExtra } from "@/contexts/bookings/models/BookingExtra";
import { accommodationExtraFromResponse } from "@/contexts/accommodations/utils/accommodationExtraFromResponse";
import { bookingFromResponse } from "@/contexts/bookings/utils/bookingFromResponse";

export const bookingExtraFromResponse = (response: components["schemas"]["BookingExtra"]): BookingExtra => {
  return {
    id: response.id,
    amount: response.amount,
    units: response.units,
    price: response.price,
    mode: response.mode,
    payment_time: response.payment_time,
    booking_id: response.booking_id,
    accommodation_extra_id: response.accommodation_extra_id,
    booking: response.booking ? bookingFromResponse(response.booking) : undefined,
    accommodation_extra: response.accommodation_extra ? accommodationExtraFromResponse(response.accommodation_extra) : undefined,
    paid_at: response.paid_at ? new Date(response.paid_at) : null,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
