import axios from "@/libs/axios";

export default async function enableAccountIntegration(id: string): Promise<void> {
  try {
    await axios.patch(`/settings/integrations/${id}/enable`);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
