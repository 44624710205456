import axios from "@/libs/axios";
import { pickBy } from "lodash-es";
import type UpdateOwnerRequest from "@/clients/owners/UpdateOwner/request/UpdateOwnerRequest";

export default async function updateOwner(id: string, request: UpdateOwnerRequest): Promise<void> {
  try {
    await axios.put(`/owners/${id}`, pickBy(request));
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
