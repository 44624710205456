import type { paths } from "@/libs/api/schema";
import type { OwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import { ownerBankAccountFromResponse } from "@/contexts/owner-bank-accounts/utils/ownerBankAccountFromResponse";

const fromShowOwnerBankAccountResponse = (response: paths["/owners/{owner}/bank-accounts/{account}"]["get"]["responses"][200]["content"]["application/json"] | undefined): OwnerBankAccount => {
  if (!response?.data) {
    throw new Error("Invalid response");
  }

  return ownerBankAccountFromResponse(response.data);
};

export default {
  fromShowOwnerBankAccountResponse,
};
