import type { components } from "@/libs/api/schema";
import type { AccommodationKitchen } from "@/contexts/accommodations/models/AccommodationRooms";

export const accommodationKitchenFromResponse = (response: components["schemas"]["AccommodationKitchen"]): AccommodationKitchen => {
  return {
    id: response.id,
    type: response.type,
    floor: response.floor,
  };
};
