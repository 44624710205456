import type { paths } from "@/libs/api/schema";
import { type Pagination } from "@/contexts/shared/models/Pagination";
import { type BookingImport } from "@/contexts/booking-imports/models/BookingImport";
import { bookingImportFromResponse } from "@/contexts/booking-imports/utils/bookingImportFromResponse";
import { paginationMetaFromResponse } from "@/contexts/shared/utils/paginationMetaFromResponse";
import type { SearchBookingImportCriteria } from "@/contexts/booking-imports/models/SearchBookingImportCriteria";

const toSearchBookingImportsRequest = (criteria: SearchBookingImportCriteria, cursor?: string): paths["/bookings/imports"]["get"]["parameters"]["query"] => {
  return {
    cursor: cursor,
    order_by: criteria.order_by,
    sort: criteria.sort,
    per_page: criteria.per_page,
    type: criteria.type,
    "provider[]": criteria.provider,
    "status[]": criteria.status,
  };
};

const fromSearchBookingImportsResponse = (response: paths["/bookings/imports"]["get"]["responses"][200]["content"]["application/json"] | undefined, headers: Headers): Pagination<BookingImport> => {
  return {
    data: response?.data?.map((item) => bookingImportFromResponse(item)) ?? [],
    meta: paginationMetaFromResponse(response?.meta),
    total: Number(headers.get("x-total-count") ?? 0),
  };
};

export default {
  toSearchBookingImportsRequest,
  fromSearchBookingImportsResponse,
};
