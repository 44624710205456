import axios from "@/libs/axios";
import type SearchAccountSeasonsResponse from "@/clients/account-seasons/SearchAccountSeasons/response/SearchAccountSeasonsResponse";

export default async function searchAccountSeasons(year: number): Promise<SearchAccountSeasonsResponse> {
  try {
    const { data } = await axios.get<SearchAccountSeasonsResponse>(`/settings/account/seasons/${year}`);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
