<template>
  <div ref="target">
    <template v-if="searchAccommodationContractsRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchAccommodationContractsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchAccommodationContractsRequest.data.data.length === 0">
      <SectionEmptyState>
        {{ $t("accommodations.manage.contracts.empty") }}
      </SectionEmptyState>
    </template>
    <template v-else>
      <div class="space-y-3">
        <ListTable>
          <ListTableHeader>
            <ListTableHeaderCell primary>{{ $t("accommodation_contracts.props.year") }}</ListTableHeaderCell>
            <ListTableHeaderCell>{{ $t("accommodation_contracts.props.status") }}</ListTableHeaderCell>
            <ListTableHeaderCell>{{ $t("accommodation_contracts.props.reference_price") }}</ListTableHeaderCell>
            <ListTableHeaderCell optional>{{ $t("accommodation_contracts.props.created_at") }}</ListTableHeaderCell>
            <ListTableHeaderCell optional>{{ $t("accommodation_contracts.props.updated_at") }}</ListTableHeaderCell>
            <ListTableHeaderCell optional>{{ $t("accommodation_contracts.props.confirmed_at") }}</ListTableHeaderCell>
            <ListTableHeaderCell small />
          </ListTableHeader>
          <ListTableBody>
            <template
              v-for="contract in searchAccommodationContractsRequest.data.data"
              :key="contract.id"
            >
              <ListTableBodyRow>
                <ListTableBodyCell>{{ contract.year }}</ListTableBodyCell>
                <ListTableBodyCell>
                  <AccommodationContractsStatusBadge :status="contract.status" />
                </ListTableBodyCell>
                <ListTableBodyCell>{{ Number(contract.reference_price).toFixed(2) }}</ListTableBodyCell>
                <ListTableBodyCell optional>{{ contract.created_at.toLocaleDateString() }}</ListTableBodyCell>
                <ListTableBodyCell optional>{{ contract.updated_at.toLocaleDateString() }}</ListTableBodyCell>
                <ListTableBodyCell optional>{{ contract.confirmed_at?.toLocaleDateString() || "-" }}</ListTableBodyCell>
                <ListTableBodyCell
                  :padding="false"
                  :truncate="false"
                  class="pl-2.5"
                >
                  <AccommodationContractsDropdown
                    :contract="contract"
                    :accommodation="accommodation"
                    @approved="contract.status = 'approved'"
                    @confirmed="confirmed()"
                    @deleted="searchAccommodationContractsRequest.fetch()"
                  />
                </ListTableBodyCell>
              </ListTableBodyRow>
            </template>
          </ListTableBody>
        </ListTable>
        <CursorPagination
          :meta="searchAccommodationContractsRequest.data.meta"
          :total="searchAccommodationContractsRequest.data.total"
          @change-page="changePage"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useVisibility from "@/contexts/shared/composables/useVisibility";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import { emptyPagination, type Pagination } from "@/contexts/shared/models/Pagination";
import useAccommodationContracts from "@/contexts/accommodation-contracts/composables/useAccommodationContracts";
import type { SearchAccommodationContractsCriteria } from "@/contexts/accommodations/models/SearchAccommodationContractsCriteria";
import type { AccommodationContract } from "@/contexts/accommodations/models/AccommodationContract";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import AccommodationContractsDropdown from "@/contexts/accommodation-contracts/ui/components/AccommodationContractsDropdown.vue";
import AccommodationContractsStatusBadge from "@/contexts/accommodation-contracts/ui/components/AccommodationContractsStatusBadge.vue";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const emit = defineEmits<{
  confirmed: [];
}>();

const { searchAccommodationContracts } = useAccommodationContracts();

const target = ref<HTMLElement>();
const { criteria, cursor, changePage } = useCriteria<SearchAccommodationContractsCriteria>({ per_page: 10 }, () => searchAccommodationContractsRequest.fetch());
useVisibility(target, () => searchAccommodationContractsRequest.fetch());

const searchAccommodationContractsRequest: UseRequest<Pagination<AccommodationContract>> = useRequest<Pagination<AccommodationContract>>({
  initialLoading: true,
  value: emptyPagination<AccommodationContract>(),
  promise: () => searchAccommodationContracts(props.accommodation.id, criteria.value, cursor.value),
  onSuccess: (response) => {
    if (cursor.value && response.data.length === 0) {
      changePage();
      return;
    }
  },
});

const confirmed = () => {
  searchAccommodationContractsRequest.fetch();
  emit("confirmed");
};

defineExpose({ fetch: () => changePage() });
</script>
