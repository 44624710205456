<template>
  <PopoverRoot
    as="div"
    class="relative"
  >
    <slot />
  </PopoverRoot>
</template>

<script generic="T" lang="ts" setup>
import type { ComputedRef, WritableComputedRef } from "vue";
import { Popover as PopoverRoot } from "@headlessui/vue";

const props = withDefaults(
  defineProps<{
    title: string;
    label: string;
    modelValue: T;
    required?: boolean;
  }>(),
  {
    required: false,
  },
);

const emit = defineEmits<{
  "update:modelValue": [value: T];
}>();

const input = computed<T>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
const empty = computed<boolean>(() => input.value === undefined);
const required = computed<boolean>(() => props.required || false);

provide<string>("title", props.title);
provide<string>("label", props.label);
provide<ComputedRef<boolean>>("empty", empty);
provide<ComputedRef<boolean>>("required", required);
provide<WritableComputedRef<T>>("input", input);
</script>
