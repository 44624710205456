<template>
  <label
    :for="id"
    class="block whitespace-nowrap text-sm font-medium text-gray-700"
  >
    <slot />
  </label>
</template>

<script lang="ts" setup>
const props = defineProps<{
  for?: string;
}>();

const id = inject<string>("id", props.for ?? "");
</script>
