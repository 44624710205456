import type { paths } from "@/libs/api/schema";
import type { OwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import { ownerBankAccountFromResponse } from "@/contexts/owner-bank-accounts/utils/ownerBankAccountFromResponse";

const fromSearchOwnerBankAccountsResponse = (response: paths["/owners/{owner}/bank-accounts"]["get"]["responses"][200]["content"]["application/json"] | undefined): OwnerBankAccount[] => {
  return response?.data?.map((item) => ownerBankAccountFromResponse(item)) ?? [];
};

export default {
  fromSearchOwnerBankAccountsResponse,
};
