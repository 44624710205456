<template>
  <Widget>
    <WidgetTitle>
      {{ $t("analytics.booking_cleanings_by_type.title") }}
    </WidgetTitle>
    <WidgetContent>
      <div
        ref="target"
        class="h-full min-h-[22rem]"
      >
        <template v-if="bookingCleaningsByTypeRequest.isLoading">
          <LoadingSection
            class="m-auto h-full"
            custom-height
          />
        </template>
        <template v-else-if="bookingCleaningsByTypeRequest.hasErrors">
          <ListErrorState
            class="m-auto h-full"
            custom-height
          />
        </template>
        <template v-else-if="(bookingCleaningsByTypeRequest.data.total ?? 0) === 0">
          <ListEmptyState
            class="m-auto h-full"
            custom-height
            description="analytics.booking_cleanings_by_type.empty.description"
            icon="vacuum-solid"
            title="booking_cleanings.empty.title"
          />
        </template>
        <template v-else>
          <div class="h-full p-4">
            <div class="py-4">
              <div class="flex h-6 w-full rounded-md bg-gray-200">
                <div
                  :style="{ width: `${bookingCleaningsByTypeRequest.data.data.deep?.avg}%` }"
                  class="h-full bg-cyan-500 first:rounded-l-md last:rounded-r-md hover:bg-cyan-500/90"
                />
                <div
                  :style="{ width: `${bookingCleaningsByTypeRequest.data.data.in?.avg}%` }"
                  class="h-full bg-indigo-500 first:rounded-l-md last:rounded-r-md hover:bg-indigo-500/90"
                />
                <div
                  :style="{ width: `${bookingCleaningsByTypeRequest.data.data.out?.avg}%` }"
                  class="h-full bg-emerald-500 first:rounded-l-md last:rounded-r-md hover:bg-emerald-500/90"
                />
                <div
                  :style="{ width: `${bookingCleaningsByTypeRequest.data.data.swap?.avg}%` }"
                  class="h-full bg-blue-500 first:rounded-l-md last:rounded-r-md hover:bg-blue-500/90"
                />
                <div
                  :style="{ width: `${bookingCleaningsByTypeRequest.data.data.final?.avg}%` }"
                  class="h-full bg-teal-500 first:rounded-l-md last:rounded-r-md hover:bg-teal-500/90"
                />
              </div>
            </div>
            <div class="divide-y divide-gray-200">
              <div class="flex items-center space-x-3 py-3 text-sm text-gray-800">
                <BookingCleaningsTypeIcon type="deep" />
                <div class="flex-grow text-right font-medium text-gray-900">{{ bookingCleaningsByTypeRequest.data.data.deep?.total ?? 0 }}</div>
              </div>
              <div class="flex items-center space-x-3 py-3 text-sm text-gray-800">
                <BookingCleaningsTypeIcon type="in" />
                <div class="flex-grow text-right font-medium text-gray-900">{{ bookingCleaningsByTypeRequest.data.data.in?.total ?? 0 }}</div>
              </div>
              <div class="flex items-center space-x-3 py-3 text-sm text-gray-800">
                <BookingCleaningsTypeIcon type="out" />
                <div class="flex-grow text-right font-medium text-gray-900">{{ bookingCleaningsByTypeRequest.data.data.out?.total ?? 0 }}</div>
              </div>
              <div class="flex items-center space-x-3 py-3 text-sm text-gray-800">
                <BookingCleaningsTypeIcon type="swap" />
                <div class="flex-grow text-right font-medium text-gray-900">{{ bookingCleaningsByTypeRequest.data.data.swap?.total ?? 0 }}</div>
              </div>
              <div class="flex items-center space-x-3 py-3 text-sm text-gray-800">
                <BookingCleaningsTypeIcon type="final" />
                <div class="flex-grow text-right font-medium text-gray-900">{{ bookingCleaningsByTypeRequest.data.data.final?.total ?? 0 }}</div>
              </div>
              <div class="flex items-center space-x-3 py-3 text-sm text-gray-800">
                <div>{{ $t("analytics.booking_cleanings_by_type.total") }}</div>
                <div class="flex-grow text-right font-medium text-gray-900">{{ bookingCleaningsByTypeRequest.data.total ?? 0 }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </WidgetContent>
  </Widget>
</template>

<script lang="ts" setup>
import { useEventBus } from "@vueuse/core";
import useAnalytics from "@/contexts/analytics/composables/useAnalytics";
import useVisibility from "@/contexts/shared/composables/useVisibility";
import useRequest from "@/contexts/shared/composables/useRequest";
import WidgetTitle from "@/contexts/shared/ui/components/widget/WidgetTitle.vue";
import WidgetContent from "@/contexts/shared/ui/components/widget/WidgetContent.vue";
import Widget from "@/contexts/shared/ui/components/widget/Widget.vue";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import BookingCleaningsTypeIcon from "@/contexts/booking-cleanings/ui/components/BookingCleaningsTypeIcon.vue";

const { bookingCleaningsByType } = useAnalytics();
const accountBus = useEventBus<string>("account");

const target = ref<HTMLElement>();

const visibility = useVisibility(target, () => bookingCleaningsByTypeRequest.fetch());

accountBus.on(() => visibility.reset());

const bookingCleaningsByTypeRequest = useRequest({
  value: { data: {}, total: 0 },
  initialLoading: true,
  promise: () => bookingCleaningsByType(),
});
</script>
