import type ShowCleaningGroupResponse from "@/clients/cleaning-groups/ShowCleaningGroup/response/ShowCleaningGroupResponse";
import axios from "@/libs/axios";
import { AxiosError } from "axios";
import Errors from "@/contexts/shared/models/Errors";

export default async function showCleaningGroup(id: string): Promise<ShowCleaningGroupResponse> {
  try {
    const { data } = await axios.get<ShowCleaningGroupResponse>(`/cleaning-groups/${id}`);
    return Promise.resolve(data);
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 404) {
        return Promise.reject(Errors.NOT_FOUND);
      }
    }
    return Promise.reject(e);
  }
}
