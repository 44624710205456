<template>
  <ModalDialog
    ref="modal"
    :parent-route="{ name: 'bookings.imports' }"
    route
    size="large"
  >
    <ModalDialogHeader>
      <ModalDialogTitle>{{ $t("booking_imports.create.title") }}</ModalDialogTitle>
    </ModalDialogHeader>
    <ModalDialogContent>
      <BookingImportsForm
        v-model="form"
        :errors="createBookingImportRequest.errors"
        @submit="submit"
      />
    </ModalDialogContent>
    <ModalDialogFooter>
      <button
        :disabled="createBookingImportRequest.isLoading"
        class="btn-primary"
        type="submit"
        @click.prevent="submit"
      >
        {{ $t("shared.form.import") }}
      </button>
      <button
        class="btn-white"
        type="button"
        @click.prevent="modal?.close()"
      >
        {{ $t("shared.form.cancel") }}
      </button>
    </ModalDialogFooter>
  </ModalDialog>
</template>

<script setup lang="ts">
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import useBookingImports from "@/contexts/booking-imports/composables/useBookingImports";
import useRequest from "@/contexts/shared/composables/useRequest";
import type { BookingImportProvider } from "@/contexts/booking-imports/models/BookingImport";
import useNotification from "@/contexts/shared/composables/useNotification";
import BookingImportsForm from "@/contexts/booking-imports/ui/components/BookingImportsForm.vue";

const emit = defineEmits<{
  fetchList: [];
}>();

const { createBookingImport } = useBookingImports();
const { successNotification, errorNotification } = useNotification();

const modal = ref<InstanceType<typeof ModalDialog>>();
const form = ref<{ provider: BookingImportProvider | undefined; file: File | undefined }>({ provider: undefined, file: undefined });

const createBookingImportRequest = useRequest({
  promise: () => createBookingImport(form.value),
  onSuccess: () => {
    successNotification("booking_imports.create.success");
    emit("fetchList");
    modal.value?.close();
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("booking_imports.create.error");
    }
  },
});

const submit = () => {
  createBookingImportRequest.fetch();
};
</script>
