import type { AccommodationChannel } from "@/contexts/accommodations/models/AccommodationChannel";
import type { components } from "@/libs/api/schema";

export const accommodationChannelFromResponse = (response: components["schemas"]["AccommodationChannel"]): AccommodationChannel => {
  return {
    id: response.id,
    channel: response.channel,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
