import type { components } from "@/libs/api/schema";
import type { Account, AccountUserType } from "@/contexts/accounts/models/Account";

export const accountFromResponse = (response: components["schemas"]["Account"] & { account_user_type?: AccountUserType }): Account => {
  return {
    id: response.id,
    name: response.name,
    email: response.email,
    icon: response.icon,
    logo: response.logo,
    primary_color: response.primary_color,
    secondary_color: response.secondary_color,
    los: response.los,
    street: response.street,
    city: response.city,
    postal_code: response.postal_code,
    country_code: response.country_code,
    account_user_type: response.account_user_type,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
