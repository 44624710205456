<template>
  <div class="w-full gap-2.5 md:flex">
    <div class="flex items-center">
      <div class="mr-2 h-4 w-4 rounded bg-blue-500" />
      <div class="text-sm text-gray-800">
        {{ $t("bookings.availability.booked") }}
      </div>
    </div>
    <div class="flex items-center">
      <div class="mr-2 h-4 w-4 rounded bg-green-500" />
      <div class="text-sm text-gray-800">
        {{ $t("bookings.availability.available") }}
      </div>
    </div>
    <div class="flex items-center">
      <div class="mr-2 h-4 w-4 rounded bg-orange-500" />
      <div class="text-sm text-gray-800">
        {{ $t("bookings.availability.locked_by_owner") }}
      </div>
    </div>
    <div class="flex items-center">
      <div class="mr-2 h-4 w-4 rounded bg-red-500" />
      <div class="text-sm text-gray-800">
        {{ $t("bookings.availability.locked_by_manager") }}
      </div>
    </div>
  </div>
</template>
