import type { UnsavedOwner } from "@/contexts/owners/models/Owner";
import type UpdateOwnerRequest from "@/clients/owners/UpdateOwner/request/UpdateOwnerRequest";

const toUpdateOwnerRequest = (owner: UnsavedOwner): UpdateOwnerRequest => {
  return {
    name: owner.name,
    email: owner.email,
    language: owner.language,
    phones: owner.phones.length > 0 ? owner.phones : null,
    notes: owner.notes,
  };
};

export default {
  toUpdateOwnerRequest,
};
