<template>
  <p
    v-if="errors && (errors.length ?? 0) > 0"
    class="mt-1 flex flex-col text-sm text-red-600"
  >
    <template
      v-for="(error, index) in errors"
      :key="index"
    >
      <span>{{ error }}</span>
    </template>
  </p>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";

const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
</script>
