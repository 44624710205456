import type UpdateAccountServiceRequest from "@/clients/account-services/UpdateAccountService/request/UpdateAccountServiceRequest";
import axios from "@/libs/axios";
import { pickBy } from "lodash-es";

export default async function updateAccountService(id: string, request: UpdateAccountServiceRequest): Promise<void> {
  try {
    await axios.put(`/settings/account/services/${id}`, pickBy(request));
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}
