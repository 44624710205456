<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodation_attachments.create.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <FormGroup
      id="accommodation_attachments.create.file"
      :errors="createAttachmentRequest.errors.file"
    >
      <FormLabel>{{ $t("accommodation_attachments.props.file") }}</FormLabel>
      <FormFile
        v-model="form.file"
        :allowed-extensions="allowedExtensions"
        :max-size="2000000"
      />
    </FormGroup>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="createAttachmentRequest.isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormFile from "@/contexts/shared/ui/components/form/FormFile.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useAccommodationAttachments from "@/contexts/accommodation-attachments/composables/useAccommodationAttachments";
import useRequest from "@/contexts/shared/composables/useRequest";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";

const props = defineProps<{
  accommodationId: string;
}>();
const emit = defineEmits<{
  close: [];
  created: [];
}>();
const { createAttachment } = useAccommodationAttachments();
const { successNotification, errorNotification } = useNotification();

const form = ref<{ file: File | undefined }>({ file: undefined });
const allowedExtensions = computed(() => ["pdf"]);

const createAttachmentRequest = useRequest<void, { file: File }>({
  promise: () => createAttachment(props.accommodationId, form.value.file as File),
  onSuccess: () => {
    successNotification("accommodation_attachments.create.success");
    emit("close");
    emit("created");
  },
  onFailure: () => {
    errorNotification("accommodation_attachments.create.error");
  },
});

const submit = async () => {
  await createAttachmentRequest.fetch();
};
</script>
