<template>
  <div class="space-y-4">
    <FormGroup :errors="errors.name">
      <FormLabel>{{ $t("booking_guests.props.name") }}</FormLabel>
      <FormText
        v-model="input.name"
        name="name"
      />
    </FormGroup>
    <FormGroup :errors="errors.emails">
      <FormLabel>{{ $t("booking_guests.props.email") }}</FormLabel>
      <FormText
        v-model="input.emails[0]"
        name="email"
        type="email"
      />
    </FormGroup>
    <FormGroup :errors="errors.phones">
      <FormLabel>{{ $t("booking_guests.props.phone") }}</FormLabel>
      <FormText
        v-model="input.phones[0]"
        name="phone"
      />
    </FormGroup>
    <FormGroup :errors="errors.language">
      <FormLabel>{{ $t("booking_guests.props.language") }}</FormLabel>
      <FormSelect
        v-model="input.language"
        :options="useLanguage().languageOptions"
        name="language"
      />
    </FormGroup>
  </div>
</template>

<script lang="ts" setup>
import type { UnsavedBookingGuest } from "@/contexts/booking-guests/models/BookingGuest";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";
import useLanguage from "@/contexts/shared/composables/useLanguage";

const props = defineProps<{
  modelValue: UnsavedBookingGuest;
  errors: RequestErrors<UnsavedBookingGuest>;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: UnsavedBookingGuest];
}>();

const { t } = useI18n();

const input = computed<UnsavedBookingGuest>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>
