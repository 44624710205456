<template>
  <div
    :aria-expanded="!!booking"
    :aria-selected="!isEmpty"
    class="group relative"
    @mouseleave="displayTooltip = false"
    @mouseover="displayTooltip = true"
  >
    <button
      ref="target"
      :class="dayClass"
      class="flex h-10 w-full cursor-default focus:outline-none group-aria-expanded:cursor-pointer group-aria-expanded:hover:bg-opacity-80 group-aria-expanded:focus:bg-opacity-80"
      type="button"
      @blur="displayTooltip = false"
      @click="displayTooltip = true"
      @focus="displayTooltip = true"
    >
      <div class="absolute right-0 top-0 z-10 p-0.5 text-xs text-gray-700 group-aria-selected:text-white">{{ date.getDate() }}</div>
      <template v-if="price">
        <div class="mt-auto w-full bg-white/60 text-center text-sm leading-5 text-gray-700">{{ Number(price / 100).toFixed(2) }}</div>
      </template>
    </button>
    <template v-if="displayTooltip && booking">
      <div
        ref="tooltip"
        :style="floatingStyles"
        class="z-20 w-64 pb-2"
      >
        <div class="rounded border border-gray-200 bg-white px-3 py-2 drop-shadow-xl">
          <h4 class="text-base font-semibold text-gray-800">
            {{ $t("accommodations.calendar.booking.title") }}
          </h4>
          <div class="mt-2 flex flex-col space-y-1.5">
            <div class="flex items-center space-x-2.5 text-sm">
              <div class="text-gray-500">{{ $t("bookings.props.code") }}</div>
              <BookingsDetailsLink
                :booking="booking"
                class="truncate text-sm"
              />
            </div>
            <div class="flex items-center space-x-2.5 text-sm">
              <div class="text-gray-500">{{ $t("bookings.props.check_in") }}</div>
              <div class="text-sm text-gray-800">{{ booking?.check_in.toLocaleDateString() }}</div>
            </div>
            <div class="flex items-center space-x-2.5 text-sm">
              <div class="text-gray-500">{{ $t("bookings.props.check_out") }}</div>
              <div class="text-sm text-gray-800">{{ booking?.check_out.toLocaleDateString() }}</div>
            </div>
            <div class="flex items-center space-x-2.5 text-sm">
              <div class="text-gray-500">{{ $t("bookings.props.adults") }}</div>
              <div class="text-sm text-gray-800">{{ booking?.adults }}</div>
            </div>
            <div class="flex items-center space-x-2.5 text-sm">
              <div class="text-gray-500">{{ $t("bookings.props.children") }}</div>
              <div class="text-sm text-gray-800">{{ booking?.children }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { AccommodationCalendarDayStatus } from "@/contexts/accommodations/models/AccommodationCalendar";
import type { Booking } from "@/contexts/bookings/models/Booking";
import { autoPlacement, flip, shift, useFloating } from "@floating-ui/vue";
import { onClickOutside } from "@vueuse/core";
import BookingsDetailsLink from "@/contexts/bookings/ui/components/BookingsDetailsLink.vue";

const props = defineProps<{
  isEmpty: boolean;
  date: Date;
  booking: Booking | undefined;
  status: AccommodationCalendarDayStatus | null;
  price: number | null;
}>();

const displayTooltip = ref<boolean>(false);
const target = ref<HTMLElement>();
const tooltip = ref<HTMLElement>();

onClickOutside(target, () => (displayTooltip.value = false), { ignore: [tooltip] });
const { floatingStyles } = useFloating(target, tooltip, { middleware: [flip(), shift(), autoPlacement({ allowedPlacements: ["top"] })] });

const dayClass = computed<string>(() => {
  switch (props.status) {
    case "booked":
      return "bg-blue-500";
    case "available":
      return "bg-green-500";
    case "locked_by_owner":
      return "bg-orange-500";
    case "locked_by_manager":
      return "bg-red-500";
    default:
      return "bg-white";
  }
});
</script>
