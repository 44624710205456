import type { CalendarAccommodationsCriteria } from "@/contexts/accommodations/models/CalendarAccommodationsCriteria";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import type { AccommodationCalendar } from "@/contexts/accommodations/models/AccommodationCalendar";
import type { paths } from "@/libs/api/schema";
import { bookingFromResponse } from "@/contexts/bookings/utils/bookingFromResponse";

const toCalendarAccommodationRequest = (criteria: CalendarAccommodationsCriteria): paths["/accommodations/{accommodation}/calendar"]["get"]["parameters"]["query"] => {
  const dayjs = useDayjs();

  return {
    from: dayjs(criteria.from).startOf("day").format("YYYY-MM-DD"),
    to: dayjs(criteria.to).endOf("day").format("YYYY-MM-DD"),
  };
};

const fromCalendarAccommodationResponse = (response: paths["/accommodations/{accommodation}/calendar"]["get"]["responses"][200]["content"]["application/json"] | undefined): AccommodationCalendar => {
  return {
    days: response?.days ?? {},
    bookings: response?.bookings?.map((booking) => bookingFromResponse(booking)) ?? [],
  };
};

export default {
  toCalendarAccommodationRequest,
  fromCalendarAccommodationResponse,
};
