import type { Router, RouteRecordRaw } from "vue-router";
import Middleware from "@/contexts/shared/models/Middleware";
import ProfilePage from "@/contexts/profile/ui/pages/ProfilePage.vue";

const routes: RouteRecordRaw[] = [
  {
    name: "profile",
    path: "/profile",
    component: ProfilePage,
    meta: {
      layout: "dashboard",
      group: "profile",
      middleware: [Middleware.auth],
    },
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
