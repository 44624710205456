import type UserResponse from "@/clients/auth/User/response/UserResponse";
import type { User } from "@/contexts/profile/models/User";
import { accountFromResponse } from "@/contexts/accounts/utils/accountFromResponse";

const fromUserResponse = (response: UserResponse): User => {
  return {
    id: response.data.id,
    name: response.data.name,
    email: response.data.email,
    language: response.data.language,
    accounts_count: response.data.accounts_count,
    notifications_count: response.data.notifications_count,
    read_notifications_count: response.data.read_notifications_count,
    tokens_count: response.data.tokens_count,
    unread_notifications_count: response.data.unread_notifications_count,
    accounts: response.data.accounts?.map((item) => accountFromResponse(item)),
  };
};

export default {
  fromUserResponse,
};
