<template>
  <nav
    aria-label="Pagination"
    class="flex items-center justify-between"
  >
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        {{ $t("shared.pagination.total") }} <span class="font-medium">{{ total }}</span> {{ $t("shared.pagination.results", total) }}
      </p>
    </div>
    <div class="flex flex-1 justify-between space-x-3 sm:justify-end">
      <button
        :disabled="!meta.prev_cursor"
        class="btn-white"
        type="button"
        @click="prev"
      >
        {{ $t("shared.pagination.previous") }}
      </button>
      <button
        :disabled="!meta.next_cursor"
        class="btn-white"
        type="button"
        @click="next"
      >
        {{ $t("shared.pagination.next") }}
      </button>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import type { PaginationMeta } from "@/contexts/shared/models/Pagination";

const props = defineProps<{
  meta: PaginationMeta;
  total: number;
}>();

const emit = defineEmits<{
  changePage: [value: string];
}>();

const prev = () => {
  if (props.meta.prev_cursor) {
    emit("changePage", props.meta.prev_cursor);
  }
};
const next = () => {
  if (props.meta.next_cursor) {
    emit("changePage", props.meta.next_cursor);
  }
};
</script>
