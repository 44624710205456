import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import type { components } from "@/libs/api/schema";

export const cleaningGroupFromResponse = (response: components["schemas"]["CleaningGroup"]): CleaningGroup => {
  return {
    id: response.id,
    name: response.name,
    archived_at: response.archived_at ? new Date(response.archived_at) : null,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
