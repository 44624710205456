<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("owner_bank_accounts.update.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <OwnerBankAccountsDetailsForm
      v-model="ownerBankAccount"
      :errors="updateOwnerBankAccountRequest.errors"
      type="update"
      @submit="submit"
    />
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="updateOwnerBankAccountRequest.isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script setup lang="ts">
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import OwnerBankAccountsDetailsForm from "@/contexts/owner-bank-accounts/ui/components/OwnerBankAccountsDetailsForm.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import type { OwnerBankAccount, UnsavedOwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import useOwnerBankAccounts from "@/contexts/owner-bank-accounts/composables/useOwnerBankAccounts";

const props = defineProps<{
  bankAccount: OwnerBankAccount;
}>();

const emit = defineEmits<{
  close: [];
  updated: [value: OwnerBankAccount];
}>();

const { updateOwnerBankAccount } = useOwnerBankAccounts();
const { successNotification, errorNotification } = useNotification();

const ownerBankAccount = ref<UnsavedOwnerBankAccount>({
  address: props.bankAccount.address,
  country_code: props.bankAccount.country_code,
  id_number: props.bankAccount.id_number,
  bank_name: props.bankAccount.bank_name,
  bank_account: props.bankAccount.bank_account,
  bank_swift_bic: props.bankAccount.bank_swift_bic,
});

const updateOwnerBankAccountRequest = useRequest({
  promise: () => updateOwnerBankAccount(props.bankAccount.owner_id, props.bankAccount.id, ownerBankAccount.value),
  onSuccess: (response) => {
    successNotification("owner_bank_accounts.update.success");
    emit("close");
    emit("updated", response);
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("owner_bank_accounts.update.error");
    }
  },
});

const submit = async () => {
  await updateOwnerBankAccountRequest.fetch();
};
</script>
