<template>
  <DropdownMenu>
    <DropdownMenuItemButton @click="attachmentPreviewModal?.open()">
      {{ $t("accommodation_attachments.preview.title") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="accommodation?.default_attachment_id === attachment.id"
      @click="attachmentDefaultModal?.open()"
    >
      {{ $t("accommodation_attachments.default.title") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="accommodation?.default_attachment_id === attachment.id"
      class="text-red-600 hover:text-red-700"
      @click="attachmentDeleteModal?.open()"
    >
      {{ $t("accommodation_attachments.delete.title") }}
    </DropdownMenuItemButton>
  </DropdownMenu>
  <ModalDialog
    ref="attachmentPreviewModal"
    v-slot="{ close }"
    size="3xl"
  >
    <AccommodationAttachmentsPreviewModal
      :id="attachment.id"
      :accommodation-id="attachment.accommodation_id"
      @close="close"
    />
  </ModalDialog>
  <ModalDialog
    ref="attachmentDefaultModal"
    v-slot="{ close }"
  >
    <AccommodationAttachmentsDefaultModal
      :attachment="attachment"
      @close="close"
      @selected-default="$emit('selectedDefault')"
    />
  </ModalDialog>
  <ModalDialog
    ref="attachmentDeleteModal"
    v-slot="{ close }"
  >
    <AccommodationAttachmentsDeleteModal
      :attachment="attachment"
      @close="close"
      @deleted="$emit('deleted')"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import DropdownMenuItemButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuItemButton.vue";
import DropdownMenu from "@/contexts/shared/ui/components/dropdown/DropdownMenu.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { AccommodationAttachment } from "@/contexts/accommodation-attachments/models/AccommodationAttachment";
import AccommodationAttachmentsPreviewModal from "@/contexts/accommodation-attachments/ui/modals/AccommodationAttachmentsPreviewModal.vue";
import AccommodationAttachmentsDefaultModal from "@/contexts/accommodation-attachments/ui/modals/AccommodationAttachmentsDefaultModal.vue";
import AccommodationAttachmentsDeleteModal from "@/contexts/accommodation-attachments/ui/modals/AccommodationAttachmentsDeleteModal.vue";

defineProps<{
  attachment: AccommodationAttachment;
  accommodation: Accommodation | undefined;
}>();

defineEmits<{
  selectedDefault: [];
  deleted: [];
}>();

const attachmentPreviewModal = ref<InstanceType<typeof ModalDialog>>();
const attachmentDefaultModal = ref<InstanceType<typeof ModalDialog>>();
const attachmentDeleteModal = ref<InstanceType<typeof ModalDialog>>();
</script>
