export default function useFormatter() {
  const bytesToHuman = (bytes: number): string => {
    if (bytes == 0) return "0 Bytes";
    const type = Math.floor(Math.log(bytes) / Math.log(1000));
    return `${parseFloat((bytes / Math.pow(1000, type)).toFixed(0))} ${["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][type]}`;
  };

  const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512): Blob => {
    const characters = atob(b64Data);
    const arrays = [];
    for (let offset = 0; offset < characters.length; offset += sliceSize) {
      const slice = characters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      arrays.push(byteArray);
    }

    return new Blob(arrays, { type: contentType });
  };

  return {
    bytesToHuman,
    b64toBlob,
  };
}
