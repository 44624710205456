<template>
  <div class="space-y-4 md:grid md:gap-4 md:space-y-0 lg:col-span-2 lg:grid-cols-3">
    <div class="lg:col-span-2">
      <FormGroup
        :errors="errors.notes"
        class="lg:col-span-3"
      >
        <FormLabel>{{ $t("bookings.props.notes") }}</FormLabel>
        <FormInput>
          <FormTextarea
            v-model="input.notes"
            name="notes"
          />
        </FormInput>
      </FormGroup>
    </div>
    <div>
      <FormCheckboxWithLabel
        id="is_youngsters"
        v-model="input.is_youngsters"
        :errors="errors.is_youngsters"
        name="is_youngsters"
      >
        {{ $t("bookings.props.is_youngsters") }}
      </FormCheckboxWithLabel>
      <FormCheckboxWithLabel
        id="has_pets"
        v-model="input.has_pets"
        name="has_pets"
      >
        {{ $t("bookings.props.has_pets") }}
      </FormCheckboxWithLabel>
    </div>
    <FormGroup :errors="errors.security_deposit">
      <FormLabel>{{ $t("bookings.props.security_deposit") }}</FormLabel>
      <FormInput>
        <FormFloat
          v-model.number="input.security_deposit"
          name="security_deposit"
        />
      </FormInput>
    </FormGroup>
  </div>
</template>

<script lang="ts" setup>
import type { UnsavedBooking } from "@/contexts/bookings/models/Booking";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormTextarea from "@/contexts/shared/ui/components/form/FormTextarea.vue";
import FormCheckboxWithLabel from "@/contexts/shared/ui/components/form/FormCheckboxWithLabel.vue";
import FormFloat from "@/contexts/shared/ui/components/form/FormFloat.vue";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";

const props = defineProps<{
  modelValue: UnsavedBooking;
  errors: RequestErrors<UnsavedBooking>;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: UnsavedBooking];
}>();

const input = computed<UnsavedBooking>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>
