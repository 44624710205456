import type { Router, RouteRecordRaw } from "vue-router";
import NotFoundPage from "@/contexts/shared/ui/pages/NotFoundPage.vue";

const routes: RouteRecordRaw[] = [
  {
    name: "404",
    path: "/:catchAll(.*)*",
    component: NotFoundPage,
    meta: {
      layout: "empty",
    },
  },
];

const configureRouter = (router: Router): void => {
  routes.map((route) => router.addRoute(route));
};

export { configureRouter };
