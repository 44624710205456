import type { components } from "@/libs/api/schema";
import type { AccommodationAttachment } from "@/contexts/accommodation-attachments/models/AccommodationAttachment";

export const accommodationAttachmentFromResponse = (response: components["schemas"]["AccommodationAttachment"]): AccommodationAttachment => {
  return {
    id: response.id,
    accommodation_id: response.accommodation_id,
    name: response.name,
    extension: response.extension,
    size: response.size,
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
};
